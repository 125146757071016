// primary para color borde = '#e3bc08';

import { T } from "@angular/cdk/keycodes";
import { FormTagEnum } from "@appNeo/neoShared/helpers/enums/FormTag.enum";
import { TipoDatePickerEnum } from "@appNeo/neoShared/helpers/enums/TipoDatePicker.enum";
import { ClasesCampoLayoutCamposPorcentajeFormularioEnum, IFormInput } from "@appNeo/neoShared/helpers/interfaces/IForm-input";

// secondary para color  fondo = '#FDF1BA';
export const COLORES: any = {
    red: {
      primary: '#ad2121',
      secondary: '#FAE3E3',
    },
    blue: {
      primary: '#1e90ff',
      secondary: '#D1E8FF',
    },
    yellow: {
      primary: '#e3bc08',
      secondary: '#FDF1BA',
    },
  };


  export const INPUTS_FORMULARIO_EVENTO_ESPECIFICO : IFormInput[] = [
    {
      tag: FormTagEnum.input,
      label: 'Título',
      type: '',
      placeholder: 'Añade un título y una hora',
      formControlName: 'title',
      maxLength:250,
      obligatorio: true,
      validadores: [],
      clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x],
    },
    {
        tag: FormTagEnum.date,
        tipoDatePicker: TipoDatePickerEnum.Fecha,
        label: 'Inicio evento',
        type: '',
        // placeholder: 'Seleccione fecha evento',
        formControlName: 'start',
        obligatorio: true,
        validadores: [],
        clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x],
    },
    {
      tag: FormTagEnum.date,
      tipoDatePicker: TipoDatePickerEnum.Fecha,
      label: 'Fin evento',
      type: '',
      // placeholder: 'Seleccione fecha evento',
      formControlName: 'end',
      obligatorio: false,
      validadores: [],
      clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x],
    },
    {
      tag: FormTagEnum.checkbox,
      label: 'Todo el día',
      type: '',
      placeholder: '',
      formControlName: 'allDay',
      obligatorio: false,
      validadores: [],
      clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x],
  },
    {
      tag: FormTagEnum.date,
      tipoDatePicker: TipoDatePickerEnum.Hora,
      label: 'Hora inicio evento',
      type: '',
      // placeholder: 'Seleccione hora inicio evento',
      formControlName: 'horaInicio',
      obligatorio: false,
      validadores: [],
      clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x],
    },
    {
      tag: FormTagEnum.date,
      tipoDatePicker: TipoDatePickerEnum.Hora,
      label: 'Hora fin evento',
      type: '',
      // placeholder: 'Seleccione hora inicio evento',
      formControlName: 'horaFin',
      obligatorio: false,
      validadores: [],
      clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x],
    },
    
    {
      tag: FormTagEnum.cantidad,
      label: 'Número de niños',
      type: '',
      placeholder: '',
      formControlName: 'numeroNinhos',
      obligatorio: true,
      validadores: [],
      clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent2x],
  }
  ];

  export const INPUTSFILTRADO: IFormInput[] = [{
    tag: FormTagEnum.select,
    label: 'Tipo evento',
    type: '',
    placeholder: 'Seleccion evento',
    formControlName: 'idEvento',
    obligatorio: false,
    validadores: [],
    datos: [ {id: '1', nombre: 'Cumpleaños'}, {id: '2', nombre:'Otro'}]
  }];
  // TODO BORRAR EN CUANTO APLICADO COMMIT EN MASTER EVITA DEFINIR VALIDADORES PARA CONTRUIR FORMULARIO TABLA
  export const VALIDATORSFILTROS = {
    idEvento:  ['']
  }

// EJMPLO Interface especiales para las que se tenga que mpear en caso de API externa
// adaptacion entidad
export interface IFilm {
    id: number;
    title: string;
    release_date: string;
}

export enum CodigoColorCalendarioHexEnum {
  CODIGOA = '#FFD436',
  CODIGOB = '#00D385',
  CODIGOC = '#6A8DFF',
  CODIGOD = '#DA1884',
  CURRENT = '#F44336',
  SELECCIONDIAS = 'deeppink',
  DISABLED = '#eee',
  BORDER = '#e1e1e1',
  AMARILLO = '#FFD436',
  VERDE = '#00D385',
  LILA = '#6A8DFF',
  ROSA = '#DA1884',
  ROJO = '#F44336',
  FUCSIA = 'deeppink',
  GRIS = '#eee'
}



export enum CodigoColorCalendarioEnum {
  CODIGO_A = 'codigoA',
  CODIGO_B = 'codigoB',
  CODIGO_C = 'codigoC',
  CODIGO_D = 'codigoD'
}
// interface por defecto para API
export interface IEvento {
    id?: string;
    titulo: string;
    fechaInicio: string;
    fechaFin?: string;
    horaInicio?: string;
    horaFin?: string;
    descripcion?: string;
    allDay?:boolean;
  codigoColor?: CodigoColorCalendarioEnum
}

export interface IEventoEntidad extends IEvento {
    numeroNinhos?: number;
    menusPadres?: number;
    email?: string;
  color?: string;
  colorPaquete?: string;
}
export const EVENTOS_DEMO_FILTRADO: IEventoEntidad[] = [
  {
    id: '1',
    titulo: 'Evento MOCK resultado filtrado',
    fechaInicio: "2022-04-02",
    horaInicio: "17:00",
    horaFin: "20:00",
    numeroNinhos: 25,
    menusPadres: 10,
    email: 'lucia.rodriguez@duacode.com',
    color: '#FFD436',
    colorPaquete: '#00D385',
  }
];

export const EVENTOS_DEMO: IEventoEntidad[] = [
    {
        id: '1',
        titulo: 'Cumpleaños María',
        fechaInicio: "2022-04-02",
        horaInicio: "17:00",
        horaFin: "20:00",
        numeroNinhos: 25,
        menusPadres: 10,
    email: 'lucia.rodriguez@duacode.com',
    color: '#6A8DFF',
    colorPaquete: '#00D385',
    },
    {
      id: '2',
      titulo: 'Commemoración dia de la Mujer',
      fechaInicio: "2022-04-08",
      email: 'lucia.rodriguez@duacode.com',
      color: '#FFD436',
      colorPaquete: '#00D385',
    },
    {
      id: '3',
      titulo: 'Cumpleaños Teresa',
      fechaInicio: "2022-04-26",
      horaInicio: "17:00",
      horaFin: "20:00",
      numeroNinhos: 25,
      menusPadres: 10,
      email: 'lucia.rodriguez@duacode.com',
      color: '#6A8DFF',
      colorPaquete: '#00D385',
    },
    {
      id: '4',
      titulo: 'Cumpleaños Gemma',
      fechaInicio: "2022-04-26",
      horaInicio: "17:00",
      horaFin: "20:00",
      numeroNinhos: 25,
      menusPadres: 10,
      email: 'lucia.rodriguez@duacode.com',
      color: '#6A8DFF',
      colorPaquete: '#00D385',
    },
    {
      id: '5',
      titulo: 'Cumpleaños Mery',
      fechaInicio: "2022-04-26",
      horaInicio: "17:00",
      horaFin: "20:00",
      numeroNinhos: 25,
      menusPadres: 10,
      email: 'lucia.rodriguez@duacode.com',
      color: '#FFD436',
      colorPaquete: '#00D385',
    },
    {
        id: '6',
        titulo: 'Cumpleaños Bernardo',
        fechaInicio: "2022-04-26",
        horaInicio: "17:00",
        horaFin: "20:00",
        numeroNinhos: 25,
        menusPadres: 10,
      email: 'lucia.rodriguez@duacode.com',
      color: '#FFD436',
      colorPaquete: '#00D385',
    },
    {
        id: '7',
        titulo: 'Cumpleaños Mía',
        fechaInicio: "2022-04-20",
        horaInicio: "17:00",
        horaFin: "20:00",
        numeroNinhos: 25,
        menusPadres: 10,
      email: 'lucia.rodriguez@duacode.com',
      color: '#6A8DFF',
      colorPaquete: '#00D385',
    },
    {
      id: '8',
      titulo: 'Cumpleaños India',
      fechaInicio: "2022-04-02",
      horaInicio: "11:15",
      horaFin: "12:15",
      numeroNinhos: 25,
      menusPadres: 10,
      email: 'lucia.rodriguez@duacode.com',
      color: '#FFD436',
      colorPaquete: '#00D385',
    },
    {
      id: '9',
      titulo: 'Cumpleaños Matty',
      fechaInicio: "2022-04-10",
      horaInicio: "17:00",
      horaFin: "20:00",
      numeroNinhos: 25,
      menusPadres: 10,
      email: 'lucia.rodriguez@duacode.com',
      color: '#6A8DFF',
      colorPaquete: '#00D385',
  },
  {
    id: '10',
    titulo: 'Cumpleaños Alberto',
    fechaInicio: "2022-04-26",
    allDay: true,
    horaInicio: "10:00",
    numeroNinhos: 25,
    menusPadres: 10,
    email: 'lucia.rodriguez@duacode.com',
    color: '#6A8DFF',
    colorPaquete: '#00D385',
  },
  {
    id: '11',
    titulo: 'Cumpleaños Gala',
    fechaInicio: "2022-04-26",
    horaInicio: "17:00",
    horaFin: "20:00",
    numeroNinhos: 25,
    menusPadres: 10,
    email: 'lucia.rodriguez@duacode.com',
    color: '#6A8DFF',
    colorPaquete: '#00D385',
  },

];



export const DEMO_FILMS: any[] = [
    {
    "adult": false,
    "backdrop_path": null,
    "genre_ids": [
    35
    ],
    "id": 765119,
    "original_language": "en",
    "original_title": "The Bubble",
    "overview": "Follows a group of actors and actresses stuck inside a pandemic bubble at a hotel attempting to complete a film.",
    "popularity": 9.916,
    "poster_path": "/3aS8YU8Yukl11PIwFP0NMsbrmTt.jpg",
    "release_date": "2022-04-01 17:00",
    "title": "The Bubble",
    "video": false,
    "vote_average": 0,
    "vote_count": 0
    },
    {
    "adult": false,
    "backdrop_path": "/xaqAINN4n0LtvqQ2MSKrVkFpYgV.jpg",
    "genre_ids": [
    10752,
    36,
    18
    ],
    "id": 661231,
    "original_language": "en",
    "original_title": "Operation Mincemeat",
    "overview": "In 1943, two British intelligence officers concoct Operation Mincemeat, wherein their plan to drop a corpse with false papers off the coast of Spain would fool Nazi spies into believing the Allied forces were planning to attack by way of Greece rather than Sicily.",
    "popularity": 5.651,
    "poster_path": "/hHdtWTSFFYSdoInT3xqxCviIARx.jpg",
    "release_date": "2022-04-01 19:00",
    "release_date_end": "2022-04-01 19:30",
    "title": "Operation Mincemeat",
    "video": false,
    "vote_average": 0,
    "vote_count": 0
    },
    {
    "adult": false,
    "backdrop_path": "/kMzR2mo1QByfeT80Z4iQJYFW9FG.jpg",
    "genre_ids": [
    10751,
    35
    ],
    "id": 826953,
    "original_language": "en",
    "original_title": "Better Nate Than Ever",
    "overview": "13-year-old Nate Foster has big Broadway dreams but there’s only one problem — he can’t even land a part in the school play. When his parents leave town, Nate and his best friend Libby sneak off to the Big Apple for a once-in-a-lifetime opportunity to prove everyone wrong. A chance encounter with Nate’s long-lost Aunt Heidi turns his journey upside-down, and together they must learn that life’s greatest adventures are only as big as your dreams.",
    "popularity": 7.531,
    "poster_path": "/e41beL31i0davT9IXXztWc95Ssg.jpg",
    "release_date": "2022-04-02",
    "title": "Better Nate Than Ever",
    "video": false,
    "vote_average": 0,
    "vote_count": 0
    },
    {
    "adult": false,
    "backdrop_path": null,
    "genre_ids": [
    53,
    27
    ],
    "id": 927341,
    "original_language": "en",
    "original_title": "Hunting Ava Bravo",
    "overview": "Billionaire sportsman Buddy King unwinds by hunting human captives on his remote mountain estate. But his latest victim, Ava Bravo, is no easy target.",
    "popularity": 3.994,
    "poster_path": "/1xCOE3VYzv4tK0pmkKy196LSqjD.jpg",
    "release_date": "2022-04-03",
    "title": "Hunting Ava Bravo",
    "video": false,
    "vote_average": 0,
    "vote_count": 0
    },
    {
    "adult": false,
    "backdrop_path": null,
    "genre_ids": [
    35
    ],
    "id": 864754,
    "original_language": "ta",
    "original_title": "மன்மத லீலை",
    "overview": "",
    "popularity": 2.126,
    "poster_path": "/mUGVcS0TJkXMzkvsMXxBM94Ecru.jpg",
    "release_date": "2022-04-03",
    "title": "Manmatha Leelai",
    "video": false,
    "vote_average": 0,
    "vote_count": 0
    },
    {
    "adult": false,
    "backdrop_path": null,
    "genre_ids": [
    99
    ],
    "id": 896639,
    "original_language": "en",
    "original_title": "Elizabeth: A Portrait in Parts",
    "overview": "Elizabeth is an archive-based documentary film about the Queen. A celebration. A truly cinematic mystery-tour up and down the decades: poetic, funny, disobedient, ungovernable, affectionate, inappropriate, mischievous, in awe. Funny. Moving. Different. The Queen as never before.",
    "popularity": 3.873,
    "poster_path": "/bm9LaPJEeiBbFWbQYwbfpk0T9Kh.jpg",
    "release_date": "2022-04-03",
    "title": "Elizabeth: A Portrait in Parts",
    "video": false,
    "vote_average": 0,
    "vote_count": 0
    },
    {
    "adult": false,
    "backdrop_path": null,
    "genre_ids": [
    18,
    10749
    ],
    "id": 716814,
    "original_language": "fi",
    "original_title": "Odotus",
    "overview": "The idyllic and secluded Finnish archipelago. The pandemic soars across the world. Elli’s world trembles as her husband’s old friend arrives at the island.",
    "popularity": 2.838,
    "poster_path": "/4MxxI4a17DdXNhTvWj2XEM3vpjC.jpg",
    "release_date": "2022-04-03",
    "title": "The Wait",
    "video": false,
    "vote_average": 0,
    "vote_count": 0
    },
    {
    "adult": false,
    "backdrop_path": "/hV0ORYPc3ImcfgK0ay3BuPgjg1G.jpg",
    "genre_ids": [
    16,
    35,
    9648
    ],
    "id": 919609,
    "original_language": "ja",
    "original_title": "オッドタクシー イン・ザ・ウッズ",
    "overview": "Reconstruction of the television anime that will also depict what happens after its finale with a new story written by Kazuya Konomoto.",
    "popularity": 2.812,
    "poster_path": "/1WV5PlS46v5FU8D6AgIUeA0fj7o.jpg",
    "release_date": "2022-04-03",
    "title": "ODDTAXI: In the Woods",
    "video": false,
    "vote_average": 0,
    "vote_count": 0
    },
    {
    "adult": false,
    "backdrop_path": "/98slhaVHRA227UnwgGww0aCjiHZ.jpg",
    "genre_ids": [
    18
    ],
    "id": 682533,
    "original_language": "en",
    "original_title": "True Things",
    "overview": "A young woman living on the fringes of society becomes intoxicated by a stranger who overwhelms her quiet life.",
    "popularity": 2.047,
    "poster_path": "/l1DdNiqZIbg9UujHxyIG1YTLN6I.jpg",
    "release_date": "2022-04-03",
    "title": "True Things",
    "video": false,
    "vote_average": 0,
    "vote_count": 0
    },
    {
    "adult": false,
    "backdrop_path": null,
    "genre_ids": [
    27
    ],
    "id": 903500,
    "original_language": "en",
    "original_title": "Return of the Salem Witch",
    "overview": "A group of students stuck inside for the holidays, unable to drive home due to bad weather conditions, soon find themselves conducting a modern day witch trial.",
    "popularity": 3.005,
    "poster_path": null,
    "release_date": "2022-04-03",
    "title": "Return of the Salem Witch",
    "video": false,
    "vote_average": 0,
    "vote_count": 0
    },
    {
    "adult": false,
    "backdrop_path": "/hE90htkoiNMVliR8icdlgHAQbof.jpg",
    "genre_ids": [
    99
    ],
    "id": 811992,
    "original_language": "en",
    "original_title": "Mike Mignola: Drawing Monsters",
    "overview": "As one of the most successful independent comic book creators, Mignola has inspired generations of writers and artists. Mike Mignola: Drawing Monsters provides an in-depth look at his legacy, from the beginning of his career working as an inker for Marvel Comics to his success with Hellboy. The film features never-before-told revelations from Neil Gaiman about the Mignolaverse and the production of Guillermo Del Toro’s Hellboy II, and an interview with Steven Universe creator Rebecca Sugar discussing Mignola’s influence on her creation.",
    "popularity": 2.515,
    "poster_path": "/dsdfXwmpjdfMoOfj84RVfbotchQ.jpg",
    "release_date": "2022-04-12",
    "title": "Mike Mignola: Drawing Monsters",
    "video": false,
    "vote_average": 0,
    "vote_count": 0
    },
    {
    "adult": false,
    "backdrop_path": null,
    "genre_ids": [
    27
    ],
    "id": 783117,
    "original_language": "en",
    "original_title": "bodybag",
    "overview": "An impaired driver faces his own mortality on a lonely stretch of highway.",
    "popularity": 2.737,
    "poster_path": null,
    "release_date": "2022-04-15",
    "title": "bodybag",
    "video": false,
    "vote_average": 0,
    "vote_count": 0
    },
    {
    "adult": false,
    "backdrop_path": null,
    "genre_ids": [
    80,
    18
    ],
    "id": 736145,
    "original_language": "zh",
    "original_title": "一刀天堂",
    "overview": "The movie tells the story of a restaurant owner (played by Duan Yihong) who's chased after the murderer of his son for seven years. On the way, he met a young man (played by Shi Pengyuan) with a complicated background. Amidst suspicion and temptation, the relationship between the two began moving in an unexpected direction.",
    "popularity": 1.726,
    "poster_path": "/4uqAeSyZYWNy63tCYDKyeO102M5.jpg",
    "release_date": "2022-04-18",
    "title": "Heaven and Hell",
    "video": false,
    "vote_average": 0,
    "vote_count": 0
    },
    {
    "adult": false,
    "backdrop_path": null,
    "genre_ids": [
    28,
    878
    ],
    "id": 799155,
    "original_language": "hi",
    "original_title": "Attack",
    "overview": "Witness the rise of a nation as future wars will be fought on the basis of technology and India builds its own artificial intelligent super soldier in order to combat the rampant terror Attacks plaguing it from within. They narrow down on one soldier who has lost everything he holds dear to him as he puts his life on the line for the greater good and to serve his nation.",
    "popularity": 1.704,
    "poster_path": "/aVAEx6klolQZayk2bZcxYq5SYKg.jpg",
    "release_date": "2022-04-20",
    "title": "Attack",
    "video": false,
    "vote_average": 0,
    "vote_count": 0
    },
    {
    "adult": false,
    "backdrop_path": null,
    "genre_ids": [
    80,
    53
    ],
    "id": 543504,
    "original_language": "cn",
    "original_title": "神探大戰",
    "overview": "After executing the perpetrator of a murder case unsolved for thirty years, the assassin nicknamed \"Super Sleuth\" announces he'll continue to bring his personal brand of justice to other elusive killers. The police, believing this a challenge to their crime-solving abilities, assign their own super sleuth, Wang Zhenfeng, along with retired \"super sleuth\" Uncle Xiangyun, to analyze long-dormant cold cases and find the killers before they're killed. A succession of successfully executed Super Sleuth-style murders leads Wang to doubt Uncle Xiangyun's true identity. Meanwhile, the three super sleuths engage in a battle of wits before the next execution takes place.",
    "popularity": 1.889,
    "poster_path": "/kvJmuz3EXs9mFJSvlm7kmrIEzll.jpg",
    "release_date": "2022-04-20",
    "title": "Detective vs. Sleuths",
    "video": false,
    "vote_average": 0,
    "vote_count": 0
    },
    {
    "adult": false,
    "backdrop_path": null,
    "genre_ids": [
    16
    ],
    "id": 918428,
    "original_language": "ja",
    "original_title": "銀河英雄伝説 Die Neue These 激突 第二章",
    "overview": "",
    "popularity": 2.337,
    "poster_path": null,
    "release_date": "2022-04-21",
    "title": "The Legend of the Galactic Heroes: Die Neue These Gekitotsu 2",
    "video": false,
    "vote_average": 0,
    "vote_count": 0
    },
    {
    "adult": false,
    "backdrop_path": "/1b0K2Mzfzn8woUwszI1ue8Etp7Z.jpg",
    "genre_ids": [
    35,
    18
    ],
    "id": 939892,
    "original_language": "es",
    "original_title": "Canallas",
    "overview": "",
    "popularity": 2.142,
    "poster_path": "/dGI03IHODnhodXtoL3dXU6ebesQ.jpg",
    "release_date": "2022-04-21",
    "title": "Canallas",
    "video": false,
    "vote_average": 0,
    "vote_count": 0
    },
    {
    "adult": false,
    "backdrop_path": "/vIRGmQFoeIxqOU7OiZX4iC9t8jX.jpg",
    "genre_ids": [
    16,
    10751,
    10402,
    14,
    878
    ],
    "id": 832088,
    "original_language": "xx",
    "original_title": "Coppelia",
    "overview": "When everyone in town falls under the spell of charismatic cosmetic surgeon Doctor Coppelius, feisty Swan must act to save her sweetheart Franz, before his heart is used to spark life into Coppelia – the ‘perfect’ robot-woman the Doctor has created.",
    "popularity": 1.974,
    "poster_path": "/w8Anvy7U1CkKcOhINAxLW9G1c67.jpg",
    "release_date": "2022-04-22",
    "title": "Coppelia",
    "video": false,
    "vote_average": 0,
    "vote_count": 0
    },
    {
    "adult": false,
    "backdrop_path": null,
    "genre_ids": [
    10770,
    35,
    10749
    ],
    "id": 944941,
    "original_language": "en",
    "original_title": "Just One Kiss",
    "overview": "Through a series of chance encounters, sparks fly between college literature professor Mia and Tony, a headliner at a Manhattan supper club. Unbeknownst to them, Mia’s mother, Marlene, and Tony’s mother, Sofia, are working some maternal matchmaking magic.",
    "popularity": 1.598,
    "poster_path": null,
    "release_date": "2022-04-22",
    "title": "Just One Kiss",
    "video": false,
    "vote_average": 0,
    "vote_count": 0
    },
    {
    "adult": false,
    "backdrop_path": null,
    "genre_ids": [],
    "id": 944886,
    "original_language": "pt",
    "original_title": "Amelinda",
    "overview": "A couple arrives at a remote house to spend a few days on vacation in the middle of the countryside, the first night is very busy. What are those noises, what ghost moves the chairs, what kind of people will there be? And what secrets from the past explain everything? Directed and written by Miguel Gomes, his first feature film creates environments that only a cinephile can recreate.",
    "popularity": 0.87,
    "poster_path": "/fsvGBzlQIztW8lGYAuHUEU0XTNL.jpg",
    "release_date": "2022-04-25",
    "title": "Amelinda",
    "video": false,
    "vote_average": 0,
    "vote_count": 0
    },
    {
    "adult": false,
    "backdrop_path": null,
    "genre_ids": [],
    "id": 944886,
    "original_language": "pt",
    "original_title": "Prueba",
    "overview": "A couple arrives at a remote house to spend a few days on vacation in the middle of the countryside, the first night is very busy. What are those noises, what ghost moves the chairs, what kind of people will there be? And what secrets from the past explain everything? Directed and written by Miguel Gomes, his first feature film creates environments that only a cinephile can recreate.",
    "popularity": 0.87,
    "poster_path": "/fsvGBzlQIztW8lGYAuHUEU0XTNL.jpg",
    "release_date": "2022-08-25",
    "title": "Prueba",
    "video": false,
    "vote_average": 0,
    "vote_count": 0
    },
    {
    "adult": false,
    "backdrop_path": null,
    "genre_ids": [],
    "id": 944886,
    "original_language": "pt",
    "original_title": "Prueba 2",
    "overview": "A couple arrives at a remote house to spend a few days on vacation in the middle of the countryside, the first night is very busy. What are those noises, what ghost moves the chairs, what kind of people will there be? And what secrets from the past explain everything? Directed and written by Miguel Gomes, his first feature film creates environments that only a cinephile can recreate.",
    "popularity": 0.87,
    "poster_path": "/fsvGBzlQIztW8lGYAuHUEU0XTNL.jpg",
    "release_date": "2022-09-07",
    "title": "Prueba 2",
    "video": false,
    "vote_average": 0,
    "vote_count": 0
    },
    {
      "adult": false,
      "backdrop_path": null,
      "genre_ids": [],
      "id": 944886,
      "original_language": "pt",
      "original_title": "Prueba 2",
      "overview": "A couple arrives at a remote house to spend a few days on vacation in the middle of the countryside, the first night is very busy. What are those noises, what ghost moves the chairs, what kind of people will there be? And what secrets from the past explain everything? Directed and written by Miguel Gomes, his first feature film creates environments that only a cinephile can recreate.",
      "popularity": 0.87,
      "poster_path": "/fsvGBzlQIztW8lGYAuHUEU0XTNL.jpg",
      "release_date": "2022-09-07 17:10",
      "release_date_end": "2022-09-07 17:45",
      "title": "Prueba 2",
      "video": false,
      "vote_average": 0,
      "vote_count": 0
      }];