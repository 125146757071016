<div *ngIf="load && delayFinish" data-testid="spinners-container">
  <div *ngIf="block  && !rutaImagen" class="block" data-testid="mat-spinner-container">
    <div class="center">
      <mat-spinner [diameter]="diameter" [value]="valorSpinnerDeterminate" [color]="color" [strokeWidth]="strokeWidth" [mode]="mode" ></mat-spinner>
    </div>
  </div>


  <div *ngIf="rutaImagen" class="block" data-testid="img-spinner-container">
    <div class="center">
      <img class="img-spinner" data-testid="img-spinner" src="{{rutaImagen}}" [ngStyle]="getImgSpinnerStyles()"/>
    </div>
  </div>
  <mat-progress-spinner *ngIf="!block && !rutaImagen"
                        [diameter]="diameter"
                        [mode]="mode"
                        [color]="color"
                        [strokeWidth]="strokeWidth"
                        [value]="valorSpinnerDeterminate"
                        data-testid="matProgressSpinner"
  >
  </mat-progress-spinner>
</div>
