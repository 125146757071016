<form class="form-filtros" #formElement [formGroup]="formValidators" novalidate>
  <div class="listado-filtros">
    <ng-container *ngFor="let campo of formInput">
      <div class="filtro">
        <container-element [ngSwitch]="true">

          <some-element *ngSwitchCase=" campo.tag === 'select' || campo.tag === 'estado' ">
            <neo-campo-select
              [label]="campo.label"
              [controlCampo]="formValidators.controls[campo.formControlName]"
              [opcionGeneral]="campo.opcionGeneral"
              [opcionGeneralNombre]="campo.opcionGeneralNombre"
              [textoAyuda]="campo.textoAyuda"
              [multiple]="campo.multiple"
              [datos]="campo.datos"
              [icon]="campo.icon"></neo-campo-select>
          </some-element>

          <some-element *ngSwitchCase="campo.tag === 'date'">
            <neo-campo-datatimepicker
                [label]="campo.label"
                [tipo]="campo.type"
                [controlCampo]="formValidators.controls[campo.formControlName]"
                [textoAyuda]="campo.textoAyuda"
                [placeholder]="campo.placeholder"
                [min]="campo.min"
                [max]="campo.max"
                [horaMin]="campo.horaMin"
                [horaMax]="campo.horaMax"
                [minutoMin]="campo.minutoMin"
                [minutoMax]="campo.minutoMax"
            >
            </neo-campo-datatimepicker>
          </some-element>

          <some-element *ngSwitchDefault>
            <div class="card border border-primary" >
              <div class="card-body collapse show">
                 <h4 class="card-title">Tipo de campo {{ campo.tag }} aún sin implementar</h4>
                 <p class="card-text">Campo {{ campo.label }} [{{ campo.formControlName }}]</p>
              </div>
            </div>
          </some-element>

        </container-element>
      </div>
    </ng-container>

    <ng-content></ng-content>

  </div>
</form>
