import {Component, Input, OnInit} from '@angular/core';
import { FormControl} from '@angular/forms';
import { environment } from '@environments/environment';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import {CaptchaService} from '@appNeo/neoShared/services/captcha/captcha.service';


@Component({
  selector: 'neo-captcha [controlCampo]',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss'],
  providers: [CaptchaService]
})
export class CaptchaComponent implements OnInit {
  @Input() controlCampo: FormControl;
  @Input() label: string = 'Código de verificación';
  captcha: string;

  constructor(private captchaService: CaptchaService) {
    this.subcripcionCaptcha();
  }


  ngOnInit(): void {
    this.generateCaptcha();
    this.subcripcionInputCaptcha();
  }

  subcripcionCaptcha() {
    this.captchaService.captcha$.subscribe(captcha => {
      this.captcha = captcha;
      this.captchaValidator();
    });
  }
  subcripcionInputCaptcha() {
    this.controlCampo?.valueChanges
    .pipe(
      debounceTime(environment.formulario_captcha_debounce),
      distinctUntilChanged((formEntityOld, formEntityNew) => JSON.stringify(formEntityOld) === JSON.stringify(formEntityNew))
    )
    .subscribe( data =>  this.captchaValidator() );
  }

  captchaValidator(): boolean {
    if (this.captcha) {
      const validate = ( this.controlCampo?.value !== this.captcha) ? false : true;
      if (!validate) {
        this.controlCampo?.setErrors({ captcha: true });
      }
      return validate;
    }
  }
  generateCaptcha() {
    this.captchaService.generate();
  }
}
