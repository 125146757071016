import { Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';

export enum InputTypeEnum {
  color = 'color',
  email = 'email',
  hidden = 'hidden',
  range = 'range',
  text = 'text',
  url = 'url',
  number = 'number'
}

@Component({
  selector: 'neo-input [controlCampo]',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})

export class InputComponent {

  // Únicamente visual, es necesario igualmente agregar el validador al FormControl
  @Input() caracteresMaximo = 0;

  @Input() controlCampo: FormControl;

  @Input() tipo = InputTypeEnum.text;

  @Input() label = '';
  @Input() textoAyuda = '';
  @Input() placeholder = '';

  contadorCaracteres = 0;

  constructor() { }

  actualizarContadorCaracteres(event){
    this.contadorCaracteres = event.target.value.length;
  }

}
