import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'resaltadoTermino'
})
export class ResaltadoTerminoPipe implements PipeTransform {

   transform(fuente: any, termino: any, clase: any): any {
      if (!termino) {return fuente;}
      var re = new RegExp(termino, 'gi'); //'gi' for case insensitive and can use 'g' if you want the search to be case sensitive.
      return fuente.replace(re, function (x) {
        return (clase) ? `<span class="${clase}">${x}</span>` : "<mark>" + x + "</mark>";
      });
    }
    
}