<form [formGroup]="form"  name="form">
  <mat-form-field class="form-search" appearance="outline" data-testid="container-input-busqueda">
    <i class="icon-background icon-background-left-center icon-20 icon-ibm--search mr-10"></i>
    <input #inputBusqueda matInput type="text" name="texto-busqueda" aria-labelledby="texto-busqueda" class="browser-default search-field"
          (blur)="eventoPierdeFoco()"
          (keydown.enter)="onPerderFoco(); false; "
          [placeholder]="placeholder"
          formControlName="textoBusqueda">
          <button *ngIf="termino && botonLimpiarBuscador" matSuffix mat-icon-button aria-label="Clear" (click)="filtroLimpiar()" data-testid="btn-eliminar">
            <i class="icon-ibm--close icon-16"></i>
          </button>
  </mat-form-field>
</form>

