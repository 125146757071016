import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import {Component, EventEmitter, Input, OnInit, Output, QueryList, SimpleChanges, ViewChildren} from '@angular/core';
import { Router } from '@angular/router';
import { PerfilPropiedadTagEnum } from '@appNeo/neoShared/helpers/enums/perfil-propiedad-tag.enum';
import { IPerfilPropiedad } from '@appNeo/neoShared/helpers/interfaces/IPerfilPropiedad';
import {IActualizacionCeldaEditable, TablaService} from '@appNeo/neoShared/services/tabla/tabla.service';
import { environment } from '@environments/environment';



@Component({
  selector: 'neo-perfil-vertical',
  templateUrl: './perfil-vertical.component.html',
  styleUrls: ['./perfil-vertical.component.scss']
})
export class PerfilVerticalComponent  {

  @Input() propiedades: IPerfilPropiedad[];
  @Output() cambioCeldaEditable = new EventEmitter<IActualizacionCeldaEditable>();
  @ViewChildren('textAreaColumnaEditable') textAreaColumnaEditable: QueryList<CdkTextareaAutosize>;
  logintudTruncado = environment.longuitud_truncar_textos_defecto;

  displayedColumns: string[] = ['label', 'valor'];
  data: any[] = [];
  constructor(
    private router: Router,
    public tablaService: TablaService
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.propiedades.firstChange) {
      this.data = this.propiedades;
      console.log(this.data);
    }
  }

  clickLink(pagina: string, identificador: string){
    this.router.navigate([`/${pagina}`,identificador]);
  }

  get _PerfilPropiedadTagEnum() {
    return PerfilPropiedadTagEnum;
  }

  esTexto( propiedad: IPerfilPropiedad): boolean {
    return PerfilPropiedadTagEnum.Texto === propiedad.tag;
  }

  focusTextAreaEdicion(posicionRow: number) {
    this.textAreaColumnaEditable['_results'][posicionRow].maxRows = this.numeroRowsCeldaEditableFocus;
  }

  focusOutTextAreaEdicion(posicionRow: number) {
    this.textAreaColumnaEditable['_results'][posicionRow].maxRows = this.numeroRowsCeldaEditableInicial;
  }

  get numeroRowsCeldaEditableFocus(): number {
    return environment.numero_rows_celda_editable_focus;
  }


  get numeroRowsCeldaEditableInicial(): number {
    return environment.numero_rows_celda_editable_inicial;
  }

  actualizarDataSource(event: IActualizacionCeldaEditable) {
    this.cambioCeldaEditable.emit(event);
  }

}
