import {Component, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith, tap} from 'rxjs/operators';
import {AuxiliarService} from '@appNeo/neoShared/services/auxiliar/auxiliar.service';
import {MatAutocomplete} from '@angular/material/autocomplete';
import {EventEmitter} from '@node_modules/@angular/core';

export enum InputTypeEnum {
  color = 'color',
  email = 'email',
  hidden = 'hidden',
  range = 'range',
  text = 'text',
  url = 'url',
  number = 'number'
}

@Component({
  selector: 'neo-input-filter [controlCampo]',
  templateUrl: './input-filter.component.html',
  styleUrls: ['./input-filter.component.scss']
})

export class InputFilterComponent implements OnInit {
  // Todo: No aplica por el momento debido a la complejidad UX de controlar los respectivos comportamientos del usuario en la pérdida del foco del input
  //  únicamente aplica la opción de añadir items mediante el click en btn-nuevo-item
  // @Input() insertarItemPerdidaFoco = false;


  @Input() controlCampo: FormControl;
  @Input() label = '';
  @Input() textoAyuda = '';
  @Input() placeholder = '';
  @Input() propiedadObjetoVisualizar = 'value';
  @Input() añadirDuplicados = false;
  @Input() opciones = [];

  @ViewChild('matAutocomplete') matAutocomplete: MatAutocomplete;

  @Output('error') error =  new EventEmitter<string>();
  @Output('reiniciarValidador') reiniciarValidador =  new EventEmitter<string>();

  @Output('nuevasOpciones') nuevasOpciones = new EventEmitter<object []>();


  tipo = InputTypeEnum.text;
  filteredOptions: Observable<object []>;
  coincidenciaInputListadoUpper = false;
  coincidenciaInputListado;
  itemNoExiste = 'itemNoExiste';
  mensajeErrorSinSeleccion = 'Debes añadir la opción como nueva';
  valorInicialInput = '';
  disabledBtnNuevoItem = false;

  constructor(
    private auxiliarService: AuxiliarService
  ) { }

  ngOnInit(): void {
    this.valorInicialInput = '';
    this.filteredOptions = this.controlCampo.valueChanges
      .pipe(
        tap(x => {
          this.inicializarCoincidencias();
        }),
        tap(valorBusqueda => {
          this.buscarCoincidencias(valorBusqueda);
          if (!this.añadirDuplicados) {
            this.coincidenciaInputListado ? this.disabledBtnNuevoItem = true : this.disabledBtnNuevoItem = false;
          }
        }),
        startWith(''),
        map(val => this.auxiliarService.buscarObjetosArrayByValorAtributo(this.opciones, this.propiedadObjetoVisualizar, val)),
      );
  }

  inicializarCoincidencias() {
    this.coincidenciaInputListadoUpper = false;
    this.coincidenciaInputListado = '';
  }

  buscarCoincidencias(valor: string) {
    this.opciones.forEach(option => {
      if (valor) {
        if (option[this.propiedadObjetoVisualizar].toUpperCase() === valor.toUpperCase()) {
          this.coincidenciaInputListadoUpper = true;
        }
        if (option[this.propiedadObjetoVisualizar] === valor) {
          this.coincidenciaInputListado = option[this.propiedadObjetoVisualizar];
        }
     }
    });
    return this.coincidenciaInputListadoUpper;
  }

  blurInput() {
    // if (this.insertarItemPerdidaFoco) this.nuevoItem();
    // if (!this.coincidenciaInputListado && !this.insertarItemPerdidaFoco) {
      // this.error.emit(this.itemNoExiste);
    // }
    if (!this.coincidenciaInputListado) {
      this.error.emit(this.itemNoExiste);
    }
  }

  nuevoItem() {
    if (this.controlCampo.value) {
      const valorInput = this.controlCampo.value;
        this.opciones.push({
          id: (this.opciones.length + 1).toString(),
          value: valorInput
        });
        this.nuevasOpciones.emit(this.opciones);
    }
  }


}

