
<button (click)="eventoClick($event)" class="{{clase}}" [ngClass]="{'icono-mat-suffix': matsuffix && (icon || iconClass) && text, 'icono-mat-prefix': matprefix  && (icon || iconClass) && text}" type="{{type}}" *ngIf="basic" mat-button color="{{color}}" [disabled]="disabled" [routerLink]="link">
  <ng-template [ngTemplateOutlet]="templateProyeccion"></ng-template>
  <ng-container *ngIf="matprefix"><ng-template [ngTemplateOutlet]="templateIcono"></ng-template></ng-container>
  {{text}}
  <ng-container *ngIf="matsuffix"><ng-template [ngTemplateOutlet]="templateIcono"></ng-template></ng-container>
</button>

<button (click)="eventoClick($event)" class="{{clase}}" [ngClass]="{'icono-mat-suffix': matsuffix  && (icon || iconClass) && text, 'icono-mat-prefix': matprefix  && (icon || iconClass) && text}"  type="{{type}}" *ngIf="raised" mat-raised-button color="{{color}}" [disabled]="disabled" [routerLink]="link">
  <ng-template [ngTemplateOutlet]="templateProyeccion"></ng-template>
  <ng-container *ngIf="matprefix"><ng-template [ngTemplateOutlet]="templateIcono"></ng-template></ng-container>
  {{text}}
  <ng-container *ngIf="matsuffix"><ng-template [ngTemplateOutlet]="templateIcono"></ng-template></ng-container>
</button>
<button  (click)="eventoClick($event)" class="{{clase}}"  [ngClass]="{'icono-mat-suffix': matsuffix  && (icon || iconClass) && text, 'icono-mat-prefix': matprefix  && (icon || iconClass) && text} "  type="{{type}}" *ngIf="stroked" mat-stroked-button color="{{color}}" [disabled]="disabled" [routerLink]="link">
  <ng-template [ngTemplateOutlet]="templateProyeccion"></ng-template>
  <ng-container *ngIf="matprefix"><ng-template [ngTemplateOutlet]="templateIcono"></ng-template></ng-container>
  {{text}}
  <ng-container *ngIf="matsuffix"><ng-template [ngTemplateOutlet]="templateIcono"></ng-template></ng-container>
</button>
<button  (click)="eventoClick($event)" class="{{clase}}"  [ngClass]="{'icono-mat-suffix': matsuffix  && (icon || iconClass) && text, 'icono-mat-prefix': matprefix  && (icon || iconClass) && text}"  type="{{type}}" *ngIf="flat" mat-flat-button color="{{color}}" [disabled]="disabled" [routerLink]="link">
  <ng-template [ngTemplateOutlet]="templateProyeccion"></ng-template>
  <ng-container *ngIf="matprefix"><ng-template [ngTemplateOutlet]="templateIcono"></ng-template></ng-container>
  {{text}}
  <ng-container *ngIf="matsuffix"><ng-template [ngTemplateOutlet]="templateIcono"></ng-template></ng-container>
</button>
<button  (click)="eventoClick($event)" class="{{clase}}" type="{{type}}" *ngIf="fab" mat-fab color="{{color}}" [disabled]="disabled" [routerLink]="link">{{text}}
  <ng-template [ngTemplateOutlet]="templateProyeccion"></ng-template>
  <ng-template [ngTemplateOutlet]="templateIcono"></ng-template>
</button>
<button  (click)="eventoClick($event)" class="{{clase}}" type="{{type}}" *ngIf="miniFab" mat-mini-fab  color="{{color}}" [disabled]="disabled" [routerLink]="link">{{text}}
  <ng-template [ngTemplateOutlet]="templateProyeccion"></ng-template>
  <ng-template [ngTemplateOutlet]="templateIcono"></ng-template>
</button>
<button  (click)="eventoClick($event)" class="{{clase}}" type="{{type}}" *ngIf="icono" mat-icon-button color="{{color}}" [disabled]="disabled" [routerLink]="link">
  <ng-template [ngTemplateOutlet]="templateProyeccion"></ng-template>
  <ng-template [ngTemplateOutlet]="templateIcono"></ng-template>
</button>

<ng-template #templateProyeccion>
  <ng-content></ng-content>
</ng-template>

<ng-template #templateIcono>
  <neo-icono [icono]="icon" [iconoClase]="iconClass"></neo-icono>
  <!-- <i *ngIf="iconClass; else matIcon" class="{{iconClass}}"></i>
  <ng-template #matIcon>
    <mat-icon *ngIf="icon">{{icon}}</mat-icon>
  </ng-template> -->
</ng-template>

