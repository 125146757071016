import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BarraIzquierdaComponent } from './components/barra-izquierda/barra-izquierda.component';
import { MatIconModule} from '@angular/material/icon';
import { RouterModule} from '@angular/router';
import { MatButtonModule} from '@angular/material/button';
import { BarraDerechaComponent} from './components/barra-derecha/barra-derecha.component';
import { NeoSharedModule } from '@appNeo/neoShared/neoShared.module';
import { HeaderComponent } from './components/header/header.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { MigasDePanComponent } from './components/migas-de-pan/migas-de-pan.component';



@NgModule({
  declarations: [
    BarraDerechaComponent,
    BarraIzquierdaComponent,
    HeaderComponent,
    MenuItemComponent,
    MigasDePanComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule,
    MatButtonModule,
    NeoSharedModule,
    MatSidenavModule
  ],
  exports: [
    BarraDerechaComponent,
    BarraIzquierdaComponent,
    HeaderComponent,
    MigasDePanComponent
  ],
})
export class LayoutsModule { }
