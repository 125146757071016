<mat-form-field class="example-full-width icono-mat-suffix campo-importe" appearance="outline">
  <mat-label>{{label}}</mat-label>
  <input  matInput #inputImporte class="text-right" (change)="formatearImporte()"  [type]="'number'"  [disabled]="disabled" [(ngModel)]="importe"  placeholder="{{placeholder}}">
  <mat-hint *ngIf="textoAyuda">{{textoAyuda}}</mat-hint>
  <mat-icon matSuffix>
    <neo-icono-form
      [matsuffix]=true
      [iconoClase]="'mat-icon icon-20 icon-ibm--currency--euro'"
    ></neo-icono-form>
  </mat-icon>
</mat-form-field>
