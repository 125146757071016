import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'neo-input-importe-model [importe]',
  templateUrl: './input-importe-model.component.html',
  styleUrls: ['../input-importe.component.scss']
})
export class InputImporteModelComponent implements OnInit {

  @Input() importe: string;
  @Input() label = '';
  @Input() textoAyuda = '';
  @Input() placeholder = '000.00';
  @Input() disabled = false;

  @Output() nuevoImporte = new EventEmitter<string>();


  constructor() {}


  ngOnInit(): void {
    setTimeout( () => {
      this.formatearImporte();
    });
  }

  public formatearImporte() {
      if (!this.importe) this.importe = '0';
      this.importe = parseFloat(this.importe).toFixed(2);
      this.nuevoImporte.emit(this.importe);
  }
}
