import {Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'neo-mat-error-form [controlCampo]',
  templateUrl: './mat-error-form.component.html',
  styleUrls: ['./mat-error-form.component.scss']
})

export class MatErrorFormComponent  {
  @Input() controlCampo: FormControl;
  @Input() customError = false;

  @Input() overrideRequiredError = false;
  @Input() overrideMaxlengthError = false;
  @Input() overrideMinlengthError = false;
  @Input() overrideEmailError = false;
  @Input() overridePatternError = false;
  @Input() overrideErrorApiError = false;
  @Input() overrideMaxError = false;
  @Input() overrideMinError = false;
  @Input() overrideMatStartDateInvalid = false;
  @Input() overrideMatEndDateInvalid = false;
  

  @Input() nombreCampo = 'Campo';

  get anyOverrideError () {
    if (this.overrideRequiredError  ||  this.overrideMaxlengthError || this.overrideMinlengthError
      || this.overrideEmailError || this.overridePatternError || this.overrideErrorApiError
      || this.overrideMaxError || this.overrideMinError)
    {
      return true;
    }
  }

  constructor() { }
}
