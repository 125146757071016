import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { CriterioValidezEnum } from '@appNeo/neoShared/helpers/enums/CriterioValidez.enum';

export interface CriterioValidezContrasena {
  id: string;
  texto: string;
  completado?: boolean;
}

@Component({
  selector: 'neo-criterio-validez-contrasena[controlCampo]',
  templateUrl: './criterio-validez-contrasena.component.html',
  styleUrls: ['./criterio-validez-contrasena.component.scss']
})
export class CiterioValidezContrasenaComponent implements OnInit {

  @Input() controlCampo: FormControl;
  @Input() minCaracteres: number = 6;
  @Input() maxCaracteres: number = 12;

  criteriosValidezMap = new Map<CriterioValidezEnum, RegExp>();
  criteriosValidez: CriterioValidezContrasena[] = [
    {
      id: 'contenerRangoCaracteres',
      texto: `Entre ${this.minCaracteres} y ${this.maxCaracteres} carácteres`
    },
    {
      id: 'contenerAlMenosUnaLetraMinuscula',
      texto: 'Una letra minúscula'
    },
    {
      id: 'contenerAlMenosUnaLetraMayuscula',
      texto: 'Una letra mayúscula'
    },
    {
      id: 'contenerAlMenosUnDigito',
      texto: 'Un número'
    },
    {
      id: 'contenerAlMenosUnCaracterEspecial',
      texto: 'Un símbolo'
    }
  ];
  private criteriosValidos = false;

  constructor() {
    this.criteriosValidezMap.set(CriterioValidezEnum.at_least_eight_chars, RegExp(/^.{8,63}$/));
    this.criteriosValidezMap.set(CriterioValidezEnum.at_least_one_lower_case_char, RegExp(/^(?=.*?[a-z])/));
    this.criteriosValidezMap.set(CriterioValidezEnum.at_least_one_upper_case_char, RegExp(/^(?=.*?[A-Z])/));
    this.criteriosValidezMap.set(CriterioValidezEnum.at_least_one_digit_char, RegExp(/^(?=.*?[0-9])/));
    this.criteriosValidezMap.set(CriterioValidezEnum.at_least_one_special_char, RegExp(/^(?=.*?[" !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"])/));
  }

  ngOnInit(): void {
    this.comprobarCriterios();
    this.controlCampo.valueChanges.subscribe( valor => {
      this.controlCampo.value && this.controlCampo.value?.length && this.controlCampo.value?.length > 0 ?
      this.comprobarCriterios() : this.reset();
    })
  }

  comprobarCriterios() {
    this.criteriosValidez.map((criterio) => criterio.completado = this[criterio.id].apply(this) );
    let criteriosCompletados = this.criteriosValidez.filter((criterio) => criterio.completado);
    let completadoCriterios: boolean = criteriosCompletados.length === this.criteriosValidez.length;
    this.criteriosValidos = completadoCriterios;
    if (this.criteriosValidos) {
      this.anularEstadoErrorCriterio();
    } else {
      if ( this.controlCampo.value?.length) {
        this.establecerEstadoErrorCriterio();
      }
    }
  }

  reset() {
    this.criteriosValidez.map((criterio) => criterio.completado = false );
  }

  private contenerRangoCaracteres(): boolean {
    let contenerRangoCaracteres = this.controlCampo.value?.length > (this.minCaracteres -1) && this.controlCampo.value?.length < (this.maxCaracteres + 1);
    return contenerRangoCaracteres;
  }


  private contenerAlMenosUnaLetraMinuscula(): boolean {
    let contenerAlMenosUnaLetraMinuscula =
      this.criteriosValidezMap
        .get(CriterioValidezEnum.at_least_one_lower_case_char)
        .test(this.controlCampo.value);
    return contenerAlMenosUnaLetraMinuscula;
  }


  private contenerAlMenosUnaLetraMayuscula(): boolean {
    let contenerAlMenosUnaLetraMayuscula =
      this.criteriosValidezMap
        .get(CriterioValidezEnum.at_least_one_upper_case_char)
        .test(this.controlCampo.value);
    return contenerAlMenosUnaLetraMayuscula;
  }


  private contenerAlMenosUnDigito(): boolean {
    let contenerAlMenosUnDigito =
      this.criteriosValidezMap
        .get(CriterioValidezEnum.at_least_one_digit_char)
        .test(this.controlCampo.value);
    return contenerAlMenosUnDigito;
  }


  private contenerAlMenosUnCaracterEspecial(): boolean {
    let contenerAlMenosUnCaracterEspecial =
      this.criteriosValidezMap
        .get(CriterioValidezEnum.at_least_one_special_char)
        .test(this.controlCampo.value);
    return contenerAlMenosUnCaracterEspecial;
  }

  private establecerEstadoErrorCriterio() {
    this.controlCampo.setErrors({errorCriterios: ''});
  }
  private anularEstadoErrorCriterio() {
    if (this.controlCampo?.errors?.hasOwnProperty('errorCriterios')){
      this.controlCampo.setErrors(null);
    }
  }
}
