<mat-form-field class="example-full-width icono-mat-suffix" appearance="outline">
  <mat-label *ngIf="label">{{label}}</mat-label>
  <input *ngIf="controlCampo; else ngModelInput"  matInput #inputImporte class="text-right" (change)="formatearImporte(inputImporte.value)"  type="{{tipo}}" name="{{controlCampo}}" [formControl]="controlCampo"  placeholder="{{placeholder}}" (change)="inputChange?.emit($event)">
  <ng-template #ngModelInput>
    <input  matInput #inputImporte class="text-right" [formGroup]="controlCampo" (change)="formatearImporte(inputImporte.value)"  type="{{tipo}}" name="{{controlCampo}}"   placeholder="{{placeholder}}">
  </ng-template>
  <mat-hint *ngIf="textoAyuda">{{textoAyuda}}</mat-hint>
  <mat-icon matSuffix>
    <neo-icono-form
      [matsuffix]=true
      [iconoClase]="'mat-icon icon-20 icon-ibm--currency--euro'"
    ></neo-icono-form>
  </mat-icon>
  <mat-error class="invalid-feedback" *ngIf="controlCampo?.errors && mostrarMatError">
    <neo-mat-error-form
      [nombreCampo]="label"
      [controlCampo]=controlCampo>
    </neo-mat-error-form>
  </mat-error>
</mat-form-field>
