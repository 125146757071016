<div class="form-field" *ngIf="tipo === 'fechaHora'">
  <mat-form-field class="icono-mat-suffix campo-datapicker" appearance="outline" >
    <mat-label>{{label}}</mat-label>
    <input (keydown)="false"
      matInput
      readonly
      placeholder="{{placeholder}}"
      [ngxMatDatetimePicker]="picker"
      (click)="picker.open()"
      [min]="fechaMinFormat"
      [max]="fechaMaxFormat"
      [formControl]="controlCampo">
    <neo-button matSuffix *ngIf="controlCampo.value && !controlCampo.disabled" class="btn-limpiar-filtro"
        [icono]="true"
        [iconClass]="'icon-ibm--close icon-18'"
        [disabled]="controlCampo.disabled"
        (accion)="controlCampo.reset()"
    ></neo-button>

    <mat-datepicker-toggle matSuffix [for]="picker">
      <mat-icon matDatepickerToggleIcon matSuffix>
          <neo-icono-form
          [matsuffix]=true
          [iconoClase]="'icon-20 icon-ibm--calendar'"
          ></neo-icono-form>
      </mat-icon>
    </mat-datepicker-toggle>
    <ngx-mat-datetime-picker
      [color]="'primary'"
      #picker>
      <!-- Custom icon or text of Apply icon -->
      <ng-template>
          <!-- <mat-icon>star</mat-icon> -->
          <span>Aceptar</span>
      </ng-template>
    </ngx-mat-datetime-picker>
    <mat-hint *ngIf="textoAyuda">{{textoAyuda}}</mat-hint>
    <mat-error class="invalid-feedback" *ngIf="controlCampo.errors">
      <neo-mat-error-form
          [nombreCampo]="label"
          [controlCampo]=controlCampo>
      </neo-mat-error-form>
    </mat-error>
  </mat-form-field>
</div>

<div class="form-field" *ngIf="tipo === 'fecha'">
  <mat-form-field appearance="outline" class="icono-mat-suffix campo-datapicker">
    <mat-label>{{label}}</mat-label>
    <input
      matInput
      readonly
      placeholder="{{placeholder}}"
      (keydown)="false"
      (click)="dpHasta.open()"
      [matDatepicker]="dpHasta"
      [min]="min"
      [max]="max"
      [formControl]="controlCampo">
    <neo-button matSuffix *ngIf="controlCampo.value && !controlCampo.disabled" class="btn-limpiar-filtro"
      [icono]="true"
      [iconClass]="'icon-ibm--close icon-18'"
      [disabled]="controlCampo.disabled"
      (accion)="controlCampo.reset()"
    ></neo-button>
    <mat-datepicker-toggle matSuffix [for]="dpHasta">
      <mat-icon matDatepickerToggleIcon matSuffix>
        <neo-icono-form
          [matsuffix]=true
          [iconoClase]="'icon-20 icon-ibm--calendar'"
        ></neo-icono-form>
      </mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #dpHasta [color]="'primary'"
      [calendarHeaderComponent]="campoMatdatapickerCustomizadoComponent"
    ></mat-datepicker>
    <mat-hint *ngIf="textoAyuda">{{textoAyuda}}</mat-hint>
    <mat-error class="invalid-feedback" *ngIf="controlCampo.errors">
      <neo-mat-error-form
          [nombreCampo]="label"
          [controlCampo]=controlCampo>
      </neo-mat-error-form>
    </mat-error>
  </mat-form-field>
</div>

<div class="form-field" class="field-hora" *ngIf="tipo === 'hora'">
  <mat-form-field class="icono-mat-suffix campo-datapicker" appearance="outline" >
    <mat-label>{{label}}</mat-label>
<!--    {{minutoMin}}-->
<!--    {{fechaMinFormat}}-->
<!--    {{fechaMaxFormat}}-->
    <input #inputHora
      matInput
      readonly
      placeholder="{{placeholder}}"
      [formControl]="controlCampo"
      (click)="timePicker.open()"
      (keydown)="false"
      [owlDateTimeTrigger]="timePicker"
      [owlDateTime]="timePicker"
      [min]="fechaMinFormat"
      [max]="fechaMaxFormat"
    >
    <neo-button matSuffix *ngIf="controlCampo.value && !controlCampo.disabled" class="btn-limpiar-filtro"
      [icono]="true"
      [iconClass]="'icon-ibm--close icon-18'"
      [disabled]="controlCampo.disabled"
      (accion)="controlCampo.reset()"
    ></neo-button>
    <owl-date-time [pickerType]="'timer'" [pickerMode]="'popup'"  [panelClass]="'field-hora-panel'" #timePicker></owl-date-time>
      <neo-button matSuffix
        [icono]="true"
        [iconClass]="'mat-icon icon-20 icon-ibm--time'"
        (click)="timePicker.open()"
      ></neo-button>
    <!-- <neo-icono-form
          [matsuffix]=true
          [iconoClase]="'mat-icon icon-20 icon-ibm--time'"
          (click)="timePicker.open()"
    ></neo-icono-form> -->
    <mat-hint *ngIf="textoAyuda">{{textoAyuda}}</mat-hint>
    <mat-error class="invalid-feedback" *ngIf="controlCampo.errors">
      <neo-mat-error-form
        [nombreCampo]="label"
        [controlCampo]=controlCampo>
      </neo-mat-error-form>
    </mat-error>
  </mat-form-field>
</div>


<div class="form-field" *ngIf="tipo === 'fechaRango'">
  <mat-form-field appearance="outline" class="icono-mat-suffix campo-datapicker">
    <mat-label>{{label}}</mat-label>
    <mat-date-range-input [rangePicker]="pickerRango"  
      [min]="min"
      [max]="max"
      (click)="pickerRango.open()"  [formGroup] = "controlCampo">
      <input matStartDate  matInput readonly placeholder="Desde"  formControlName = "start">
      <input matEndDate  matInput readonly placeholder="Hasta"  formControlName = "end">
    </mat-date-range-input>
    <neo-button matSuffix *ngIf="controlCampo.get('start').value || controlCampo.get('end').value" class="btn-limpiar-filtro"
      [icono]="true"
      [iconClass]="'icon-ibm--close icon-18'"
      [disabled]="controlCampo.disabled"
      (accion)="controlCampo.reset()"
    ></neo-button>
    <mat-datepicker-toggle matSuffix [for]="pickerRango">
      <mat-icon matDatepickerToggleIcon matSuffix>
        <neo-icono-form
          [matsuffix]=true
          [iconoClase]="'icon-20 icon-ibm--calendar'"
        ></neo-icono-form>
      </mat-icon>
    </mat-datepicker-toggle>
    <mat-date-range-picker #pickerRango
    [calendarHeaderComponent]="campoMatdatapickerCustomizadoComponent"
    [dateClass]="dateClass"
    ></mat-date-range-picker>

    <mat-hint *ngIf="textoAyuda">{{textoAyuda}}</mat-hint>
    <mat-error class="invalid-feedback" *ngIf="controlCampo.errors">
      <neo-mat-error-form
          [nombreCampo]="label"
          [controlCampo]=controlCampo>
      </neo-mat-error-form>
    </mat-error>
  </mat-form-field>
</div>
