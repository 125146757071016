<div class="form-field field-palanca" *ngIf="controlCampo">    
    <mat-label *ngIf="label"> {{label}}</mat-label>
    <mat-slide-toggle
        [ngClass]="iconoClase ? iconoClase : 'toggle-on-off toggle-xs'"
        [color]="(color) ? color : 'primary'"
        [formControl]="controlCampo">
        <span class="texto-ayuda" *ngIf="texto && !(controlCampo.invalid && controlCampo.dirty && controlCampo.errors?.required && mostrarError)" data-testid="texto">
            {{texto}}
        </span>
    </mat-slide-toggle>
    <div class="error-campo" *ngIf="controlCampo.invalid && controlCampo.dirty && controlCampo.errors?.required && mostrarError">
        <ng-container *ngIf="true">  <div class="pl-10">{{label}} obligatorio</div>  </ng-container>
    </div>    
</div>
