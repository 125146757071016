<mat-form-field class="example-full-width" appearance="outline">
    <mat-label *ngIf="label">{{ label }} </mat-label>
    <input matInput [type]="tipo"
        [formControl]="controlCampo"
        [placeholder]="placeholder"
        (keyup)="actualizarContadorCaracteres($event)"
    >
    <mat-icon color="warn" matSuffix *ngIf="!controlCampo.valid && controlCampo.touched && !controlCampo.disabled">
      <neo-icono-form
        [matsuffix]=true
        [iconoClase]="'mat-icon icon-20 icon-ibm--warning--filled'"
      ></neo-icono-form>
    </mat-icon>
    <mat-hint *ngIf="textoAyuda || caracteresMaximo">
        <div *ngIf="textoAyuda">
            {{textoAyuda}}
        </div>
        <div *ngIf="contadorCaracteres && caracteresMaximo" data-testid="template-contado-mat-hint">
            <ng-template [ngTemplateOutlet]="templateContado"></ng-template>
        </div>
    </mat-hint>
    <mat-error class="invalid-feedback" *ngIf="controlCampo?.errors">
      <neo-mat-error-form
        [nombreCampo]="label"
        [controlCampo]="controlCampo"
        [customError] = "true">
        <div *ngIf="contadorCaracteres && (contadorCaracteres >= caracteresMaximo) && !controlCampo?.errors?.pattern"
          data-testid="template-contado-mat-error">
          <ng-template [ngTemplateOutlet]="templateContado"></ng-template>
        </div>
      </neo-mat-error-form>
    </mat-error>
</mat-form-field>


<ng-template #templateContado>
  {{contadorCaracteres}}<span>/</span>{{caracteresMaximo}}
</ng-template>
