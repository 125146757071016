<mat-form-field class="icono-mat-suffix" appearance="outline">
    <mat-label *ngIf="label">{{ label }} </mat-label>
    <input matInput  #userinput
        [type]="tipo"
        [formControl]="controlCampo"
        [placeholder]="placeholder"
    >

    <mat-icon color="warn" matSuffix *ngIf="!controlCampo.valid && controlCampo.touched  && !controlCampo.disabled">
        <neo-icono-form *ngIf="!controlCampo.valid && controlCampo.touched  && !controlCampo.disabled"
        [matsuffix]=true
        [iconoClase]="'mat-icon icon-20 icon-ibm--warning--filled'"
        ></neo-icono-form>
    </mat-icon>

    <mat-icon matSuffix>
        <neo-icono-form data-testid="neo-icon-copiarPortapapeles"
          [matsuffix]=true
          [iconoClase]="'mat-icon icon-20 icon-ibm--copy'"
          [cdkCopyToClipboard]="userinput.value"
          (click)="copiado()"
        ></neo-icono-form>
    </mat-icon>

    <mat-hint *ngIf="textoAyuda">
        <div >
            {{textoAyuda}}
        </div>
    </mat-hint>
    <mat-error  class="invalid-feedback" *ngIf="controlCampo.errors">
      <neo-mat-error-form
        [nombreCampo]="label"
        [controlCampo]="controlCampo">
      </neo-mat-error-form>
    </mat-error>
</mat-form-field>
<neo-notificacion #notificacionInputClipboard></neo-notificacion>