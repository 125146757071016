import { Component, Input, OnInit } from '@angular/core';
import { IKanbanItem } from '@appNeo/neoShared/services/kanban/kanban.service';


@Component({
  selector: 'neo-kanban-card',
  templateUrl: './kanban-card.component.html',
  styleUrls: ['./kanban-card.component.scss']
})
export class KanbanCardComponent implements OnInit {

  @Input('item') item: IKanbanItem;
  constructor() { }

  ngOnInit(): void {}

}
