import { IArbolData } from "./demo-componentes/demo-componentes.component";

// CHIPS
export const CHIPS_ITEMS = [
                            {id: 'laraPerez@dua.es', nombre: `Lara Pérez Guiso`, descripcion: 'laraPerez@dua.es', avatar: 'https://picsum.photos/40/40?random=1', disabled: false},
                            {id: 'laraGarcia@dua.es', nombre: `Lara Garcia Guiso`, descripcion: 'laraGarcia@dua.es', avatar: 'https://picsum.photos/40/40?random=2', disabled: false},
                            {id: 'claraPerez@dua.es', nombre: `Clara Pérez Guiso`, descripcion: 'claraPerez@dua.es', avatar: 'https://picsum.photos/40/40?random=3', disabled: false},
                            {id: 'maraPerez@dua.es', nombre: `Mara Pérez Guiso`, descripcion: 'maraPerez@dua.es', avatar: 'https://picsum.photos/40/40?random=4', disabled: false}
                        ];
export const CHIPS_ITEMS_PRESTABLECIDOS = [
                            {id: 'maraPerez@dua.es', nombre: `Mara Pérez Guiso`, disabled: true}
                        ];
export const CHIPS_ITEMS_PRESTABLECIDOS_MUCHOS = [
                          {id: 'maraPerez@dua.es', nombre: `Mara Pérez Guiso`, disabled: true},
                          {id: 'contacto1@dua.es', nombre: `Contacto 1`, disabled: true},
                          {id: 'contacto2@dua.es', nombre: `Contacto 2`, disabled: true},
                          {id: 'contacto3@dua.es', nombre: `Contacto 3`, disabled: true},
                          {id: 'contacto4@dua.es', nombre: `Contacto 4`, disabled: true},
                          {id: 'contacto5@dua.es', nombre: `Contacto 5`, disabled: true},
                          {id: 'contacto6@dua.es', nombre: `Contacto 6`, disabled: true},
                          {id: 'contacto7@dua.es', nombre: `Contacto 7`, disabled: true},
                          {id: 'contacto8@dua.es', nombre: `Contacto 8`, disabled: true},
                          {id: 'contacto9@dua.es', nombre: `Contacto 9`, disabled: true},
                          {id: 'contacto10@dua.es', nombre: `Contacto 10`, disabled: true},
                          {id: 'contacto12@dua.es', nombre: `Contacto 12`, disabled: true},
                          {id: 'contacto13@dua.es', nombre: `Contacto 13`, disabled: true},
                          {id: 'contacto14@dua.es', nombre: `Contacto 14`, disabled: true},
                          {id: 'contacto15@dua.es', nombre: `Contacto 15`, disabled: true},
                          {id: 'contacto16@dua.es', nombre: `Contacto 16`, disabled: true},
                          {id: 'contacto17@dua.es', nombre: `Contacto 17`, disabled: true},
                          {id: 'contacto18@dua.es', nombre: `Contacto 18`, disabled: true},
                          {id: 'contacto19@dua.es', nombre: `Contacto 19`, disabled: true},
                          {id: 'contacto20@dua.es', nombre: `Contacto 20`, disabled: true},
                          {id: 'contacto21@dua.es', nombre: `Contacto 21`, disabled: true},
                      ];

// ARBOL
export const TREE_DATA_BASICA = {
    'Acceso al módulo de actualizaciones': {
      'Crear y editar actualizaciones': null,
      'Generar excel de actualizacion': null,
      'Ver listado e información de actualizaciones': null,
      'Borrar elementos contactos': {
        'Borrado lógico': null,
        'Borrado temporal': null
      }
    },
    'Admin back contacto: Acceso al modulo de contacto': {
      'Editar contacto': null,
      'Ver contacto': null,
    }
};
  
export const TREE_DATA: IArbolData[] = [
    {
      item: 'Acceso al módulo de actualizaciones',
      children: [
        {
          item: 'Crear y editar actualizaciones',
          id: '2',
          seleccionado: true
        },
        {
          item: 'Generar excel de actualizacion',
          id: '3',
          seleccionado: true
        },
        {
          item: 'Borrar elementos contactos',
          seleccionado: true,
          children: [
            {
              item: 'Borrado lógico',
              id: '5',
              seleccionado: true
            },
            {
              item: 'Borrado temporal',
              id: '6',
              seleccionado: true
            }

          ]
        }

      ]
    },
    {
      item: 'Admin back contacto: Acceso al modulo de contacto',
      seleccionado: true,
      children: [
        {
          item: 'Editar contacto',
          id: '8',
          seleccionado: true
        },
        {
          item: 'Ver contacto',
          id: '9',
          seleccionado: true
        }

      ]
    }
  ];
