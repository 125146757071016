import { Component, OnInit, Input} from '@angular/core';
import {FormControl} from '@node_modules/@angular/forms';
import {InputTypeEnum} from '@appNeo/neoShared/components/input/input.component';

@Component({
  selector: 'neo-input-cantidad',
  templateUrl: './input-cantidad.component.html',
  styleUrls: ['./input-cantidad.component.scss']
})
export class InputCantidadComponent implements OnInit {
  @Input() controlCampo: FormControl;
  @Input() label = '';
  @Input() textoAyuda = '';
  @Input() mostrarMatError = true;
  @Input() tipo = InputTypeEnum.text;
  @Input() placeholder = '0';
  @Input() model;
  @Input() min: any;
  @Input() max: any;


  constructor() { }

  ngOnInit(): void {
  }

}
