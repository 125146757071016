import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IconoDespliegueService {
  private expandido$ = new BehaviorSubject<boolean>(null);

  constructor() { }

  // TODO probar funcionamiento
  async alternar(){
    let expandido = await this.expandido$;
    this.expandido$.next(!expandido);
  }

  get expandidoAsObservable(){
    return this.expandido$.asObservable();
  }

  set expandido(expandido: boolean) {
    this.expandido$.next(expandido);
  }
}
