import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appProgressBarColor]'
})
export class BarraProgresoColorDirective implements OnChanges{
  static counter = 0;

  @Input() appProgressBarColor;
  @Input() gradiente: boolean = false;
  styleEl:HTMLStyleElement = document.createElement('style');

  //generate unique attribule which we will use to minimise the scope of our dynamic style 
  uniqueAttr = `neo-progress-bar-color-${BarraProgresoColorDirective.counter++}`;

  constructor(private el: ElementRef) { 
    const nativeEl: HTMLElement = this.el.nativeElement;
    nativeEl.setAttribute(this.uniqueAttr,'');
    nativeEl.appendChild(this.styleEl);
  }

  ngOnChanges(changes: SimpleChanges): void{
    this.updateColor();
  }

  updateColor(): void{
    // update dynamic style with the uniqueAttr
    // this.styleEl.innerText = `
    //   [${this.uniqueAttr}] .mat-progress-bar-fill::after {
    //     background: linear-gradient(to left, green, yellow, red);
    //   }
    // `;
  //   this.styleEl.innerText = `
  //     [${this.uniqueAttr}] .mat-progress-bar-fill::after {
  //       background: linear-gradient(to left, yellow, red);
  //     }
  //  `;


    let estilo = (this.gradiente) 
    ?  `background: ${this.appProgressBarColor};` 
    : ` background-color: ${this.appProgressBarColor};`
    this.styleEl.innerText = `
      [${this.uniqueAttr}] .mat-progress-bar-fill::after {
        ${estilo}
      }
    `;
  }

}