import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Router} from '@angular/router';

export enum ModoBotonEnum {
  basic ='basic',
  raised ='raised',
  stroked ='stroked',
  flat ='flat',
  fab ='fab',
  miniFab ='miniFab',
}
//TODO: NO USAR EN DIALOGS POR INCOMPATIBILIDAD CON SAFARI
@Component({
  selector: 'neo-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {

  @Input() color: string;
  @Input() clase: string;
  @Input() text: string;
  @Input() disabled: false;
  @Input() basic = false;
  @Input() raised = false;
  @Input() stroked = false;
  @Input() flat = false;
  @Input() fab = false;
  @Input() miniFab = false;
  // TODO cambiar icono por icon, para seguir misma nomenclatura que boton angular neoMaterial
  @Input() icono = false; //button icon
  @Input() matprefix: boolean = true;
  @Input() matsuffix: boolean = false;



  // TODO cambiar icon por icono
  // TODO cambiar iconClass por icono
  @Input() iconClass: string; /**icon mediante clase*/
  @Input() icon: string; /** icon mat-icon que no lleva iconClass*/
  @Input() type = 'button';
  @Input() link: Router;

  @Output('accion') accion = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    if (this.matsuffix) {
      this.matprefix = false;
    }
    if (this.iconClass && this.text && !this.matprefix && !this.matsuffix) {
      this.matprefix = true;
      this.matsuffix = false;
    }
    
  }

  eventoClick(evento) {
    // console.log('Clickbutton ', evento);
    this.accion.emit(evento);
  }
}
