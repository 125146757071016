import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatCalendarHeader } from '@angular/material/datepicker';

@Component({
  selector: 'neo-campo-matdatapicker-customizado',
  templateUrl: './campo-matdatapicker-customizado.component.html',
  styleUrls: ['./campo-matdatapicker-customizado.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampoMatdatapickerCustomizadoComponent extends MatCalendarHeader<any> {
  /** Handles user clicks on the period label. */
  currentPeriodClicked(): void {
    this.calendar.currentView =
      this.calendar.currentView == 'month' ? 'multi-year' : 'month';
  }

  /** Handles user clicks on the previous button. */
  customPrev(): void {
    console.log(this.calendar.activeDate);
    this.previousClicked();
  }

  /** Handles user clicks on the next button. */
  customNext(): void {
    console.log(this.calendar.activeDate);
    this.nextClicked();
  }
}