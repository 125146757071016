import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {BusquedaComponent} from '@appNeo/neoShared/components/busqueda/busqueda.component';
import {AuxiliarService} from '@appNeo/neoShared/services/auxiliar/auxiliar.service';
import {DireccionArrastreEnum, DragDropService, IColumnaDragDrop} from '@appNeo/neoShared/services/drag-drop/drag-drop.service';

@Component({
  selector: 'neo-drag-drop',
  templateUrl: './drag-drop.component.html',
  styleUrls: ['./drag-drop.component.scss'],
})


export class DragDropComponent implements OnInit{
  @Input() ordenarColumnas = true;
  @Input() filtrado = true;
  @Input() titulo = 'Título de Drag & Drop';
  @Input() atributoOrdenacion = 'nombre';
  @Input() atributosBusqueda = ['nombre', 'id'];
  @Input() mostrarIdItem = false;

  @ViewChild('buscadorDragDrop') buscador: BusquedaComponent;

  private valorBusqueda = '';

  public columnaIzq: IColumnaDragDrop[] = [];

  public columnaDcha: IColumnaDragDrop[] = [];

  constructor(
    private auxiliarService: AuxiliarService,
    public dragDropService: DragDropService,
  ) {}

  ngOnInit(): void {
    this.subscripcionDataDragDrop();
  }

  subscripcionDataDragDrop() {
    this.dragDropService.columnaIzquierda$.subscribe(items => {
      this.columnaIzq = items;
    });
    this.dragDropService.columnaDerecha$.subscribe(items => {
      this.columnaDcha = items;
    });
  }

  private aplicarFiltrado(valorBuscador: string) {
    this.valorBusqueda = valorBuscador;
    this.gestionarVisibilidad();
  }

  private gestionarVisibilidad() {
    if (this.columnaIzq.length > 0) this.columnaIzq = Object.assign([], this.mapearVisibilidadFiltrado(this.columnaIzq, this.valorBusqueda));
    if (this.columnaDcha.length > 0) this.columnaDcha = Object.assign([], this.mapearVisibilidadFiltrado(this.columnaDcha, this.valorBusqueda));
  }

  private mapearVisibilidadFiltrado(items: IColumnaDragDrop[], textoBusqueda: string, atributosBusqueda: string[]  = this.atributosBusqueda) {
    items.forEach(element => {
      if (!textoBusqueda) {
        element.visibilidadFiltrado = true;
      } else if (!isNaN(Number(textoBusqueda))) {
        atributosBusqueda.forEach(atributo => {
            if (!isNaN(Number(element[atributo]))) {
              if (Number(element[atributo]) === Number(textoBusqueda)) {
                element.visibilidadFiltrado = true;
              } else {
                element.visibilidadFiltrado = false;
              }
            }
        });
      } else {
        let item = '';
        atributosBusqueda.map(value => {
          item += element[value];
        });

        item = this.auxiliarService.eliminarEspaciosEnBlancoString(item).toUpperCase().trim();
        textoBusqueda = this.auxiliarService.eliminarEspaciosEnBlancoString(textoBusqueda).toUpperCase().trim();
        item = this.auxiliarService.eliminarTildes(item);
        textoBusqueda = this.auxiliarService.eliminarTildes(textoBusqueda);

        if (item.includes(textoBusqueda)) {
          element.visibilidadFiltrado = true;
        } else {
          element.visibilidadFiltrado = false;
        }
      }
    });
    return items;
  }

  public seleccionarTodoColumnaIzq() {
    let auxiliarLeft = Object.assign([], this.columnaIzq);
    let auxiliarRight = Object.assign([], this.columnaDcha);
    for (let i = 0; i < auxiliarLeft.length; i++) {
      if (auxiliarLeft[i]?.visibilidadFiltrado) {
        auxiliarRight.push(auxiliarLeft[i]);
        delete auxiliarLeft[i];
      }
    }
    this.columnaIzq = Object.assign([], auxiliarLeft);
    this.columnaDcha = Object.assign([], auxiliarRight);
    this.cambioDrag();
  }

  public seleccionarTodoColumnaDcha() {
      let auxiliarLeft = Object.assign([], this.columnaIzq);
      let auxiliarRight = Object.assign([], this.columnaDcha);
      for (let i = 0; i < auxiliarRight.length; i++) {
        if (auxiliarRight[i]?.visibilidadFiltrado) {
          auxiliarLeft.push(auxiliarRight[i]);
          delete auxiliarRight[i];
        }
      }
      this.columnaIzq = Object.assign([], auxiliarLeft);
      this.columnaDcha = Object.assign([], auxiliarRight);
      this.cambioDrag();
  }

  public clickOutColumnaIzq(event: CdkDragDrop<string[]>, item) {
    const columnas = this.auxiliarService.intercambiarObjetoArrays(this.columnaIzq, this.columnaDcha, item);
    this.columnaIzq = columnas[0];
    this.columnaDcha = columnas[1];
    this.cambioDrag();
  }

  public clickOutColumnaDcha(item) {
    const columnas = this.auxiliarService.intercambiarObjetoArrays(this.columnaDcha, this.columnaIzq, item);
    this.columnaDcha = columnas[0];
    this.columnaIzq = columnas[1];
    this.cambioDrag();
  }

  public drop(event: CdkDragDrop<string[]>, direccionArrastre: DireccionArrastreEnum) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      const itemTemplateId = event.item.element.nativeElement.id;
      const itemId = itemTemplateId.replace('item-drag-', '');
      let item = {};
      let columnas = [];

      switch (direccionArrastre) {
        case DireccionArrastreEnum.Izquierda:
          item = this.buscarItemColumnaById(this.columnaDcha, itemId);
          columnas = this.auxiliarService.intercambiarObjetoArrays(this.columnaDcha, this.columnaIzq, item);
          this.columnaDcha = columnas[0];
          this.columnaIzq = columnas[1];
          break;
        case DireccionArrastreEnum.Derecha:
          item = this.buscarItemColumnaById(this.columnaIzq, itemId);
          columnas = this.auxiliarService.intercambiarObjetoArrays(this.columnaIzq, this.columnaDcha, item);
          this.columnaIzq = columnas[0];
          this.columnaDcha = columnas[1];
          break;
      }
      this.cambioDrag();
    }
  }

  private buscarItemColumnaById(columna: IColumnaDragDrop[], itemId: string): IColumnaDragDrop {
    let item: IColumnaDragDrop;
    columna.forEach(itemColumna => {
      if (itemColumna.id === itemId) {
        item = itemColumna;
      }
    });
    return item;
  }

  private cambioDrag(): void {
    this.dragDropService.columnaIzquierda = this.columnaIzq;
    this.dragDropService.columnaDerecha = this.columnaDcha;
    this.ordenacionColumnas();
  }

  private ordenacionColumnas() {
    if (this.ordenarColumnas) {
      this.columnaIzq = this.auxiliarService.ordenarArrayObjetosByAtributo(this.columnaIzq, this.atributoOrdenacion);
      this.columnaDcha = this.auxiliarService.ordenarArrayObjetosByAtributo(this.columnaDcha, this.atributoOrdenacion);
    }
  }
}
