<mat-form-field class="campo-textarea-contador" appearance="outline">
  <mat-label *ngIf="label"> {{ label }} </mat-label>
  <textarea matInput [formControl]="controlCampo" [placeholder]="placeholder"
    (keyup)="actualizarContadorCaracteres($event)">
    </textarea>
  <mat-icon color="warn" matSuffix *ngIf="!controlCampo.valid && controlCampo.touched && !controlCampo.disabled">
    <neo-icono-form [matsuffix]=true [iconoClase]="'mat-icon icon-20 icon-ibm--warning--filled'"></neo-icono-form>
  </mat-icon>

  <div class="contador" *ngIf="caracteresMaximo" data-testid="template-contado-mat-hint">
    <ng-template [ngTemplateOutlet]="templateContado"></ng-template>
  </div>

  <mat-hint *ngIf="textoAyuda || caracteresMaximo">
    <div *ngIf="textoAyuda">
      {{textoAyuda}}
    </div>
  </mat-hint>

  <mat-error class="invalid-feedback" *ngIf="controlCampo?.errors">
    <neo-mat-error-form [nombreCampo]="label" [controlCampo]="controlCampo" [customError]="true">
      <div *ngIf="contadorCaracteres && (contadorCaracteres >= caracteresMaximo)" data-testid="template-contado-mat-error">
        <!-- <ng-template [ngTemplateOutlet]="templateContado"></ng-template> -->
        </div>
        </neo-mat-error-form>
    </mat-error>
  </mat-form-field>



<ng-template #templateContado>
  {{contadorCaracteres}}<span>/</span>{{caracteresMaximo}}
</ng-template>
