import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'neo-span-inner-html',
  templateUrl: './span-inner-html.component.html',
  styleUrls: ['./span-inner-html.component.scss']
})
export class SpanInnerHtmlComponent  {

  @Input() contenido: string = ''

  formatear() {
    return (this.contenido && this.contenido.length) ?  this.contenido.split("\n").join("<br />") : ''; 
  }

}
