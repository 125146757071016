import { Component, Input } from '@angular/core';
import {IColumnaTablaEstado} from '@appNeo/neoShared/services/tabla/tabla.service';

@Component({
  selector: 'neo-estado-label',
  templateUrl: './estado-label.component.html',
  styleUrls: ['./estado-label.component.scss']
})
export class EstadoLabelComponent {

  // TODO crear interface estado general
  @Input() estado: IColumnaTablaEstado;
  constructor() { }

}
