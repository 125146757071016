import { Injectable } from '@angular/core';
import {StorageTipo} from '@appNeo/neoCore/helpers/enums/storage-tipo.enum';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  set(key: string, value: string, storage: StorageTipo) {
    switch (storage) {
      case StorageTipo.LocalStorage:
        localStorage.setItem(key, value);
    }
  }

  get(key: string, storage: StorageTipo) {
    switch (storage) {
      case StorageTipo.LocalStorage:
        return localStorage.getItem(key);
    }
  }

  remove(key: string, storage: StorageTipo) {
    switch (storage) {
      case StorageTipo.LocalStorage:
        localStorage.removeItem(key);
    }
  }
}
