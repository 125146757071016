<div class="contenedorEditor" [ngClass]="{'ng-editor-invalid': controlCampo.invalid && controlCampo.dirty}">
  <mat-label>{{label}}</mat-label>
    <ckeditor #myEditor
    [config]="ckeConfig"
    [formControl]="controlCampo"
      debounce="300"
      [editor]="editor" >
      (ready)="onReady($event)"
      (change)="onChange($event)"
    </ckeditor>
    <div id="word-count">
    </div>
  </div>
  <mat-error *ngIf="controlCampo.invalid && controlCampo.dirty" class="campo-error">
    <neo-mat-error-form
      [nombreCampo]="label"
      [controlCampo]="controlCampo">
    </neo-mat-error-form>
  </mat-error>
