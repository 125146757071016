import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncarTexto'
})
export class TruncarTextoPipe implements PipeTransform {

  transform(value: any, limit: number): any {
    if (typeof(value) === 'string') {
      return value.length < limit
      ? value
      : value.slice(0, limit) + '...';
    } else {
      return value;
    }
  }

}
