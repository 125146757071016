import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'neo-input-contrasena [controlCampo]',
  templateUrl: './input-contrasena.component.html',
  styleUrls: ['./input-contrasena.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputContrasenaComponent  {

  @Input() controlCampo: FormControl;
  
  @Input() minCaracteres: number = 6;
  @Input() maxCaracteres: number = 12;
  
  @Input() label: string = 'Contraseña';
  @Input() barraFortaleza: boolean = false;
  @Input() criterioValidez: boolean = false;  
  @Input() mostrarIconError: boolean = false;
  @Input() aviso: boolean = false;
  hide = true; // modo por defecto del icono para ver-texto/ocultar-texto contrasena
 
  
}
