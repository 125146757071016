import {Component, Input, ViewChild} from '@angular/core';
import { CirculoProgresoComponent } from '@appNeo/neoShared/components/circulo-progreso/circulo-progreso.component';

@Component({
  selector: 'neo-circulo-progreso-icono-completado [identificador]',
  templateUrl: './circulo-progreso-icono-completado.component.html',
  styleUrls: ['./circulo-progreso-icono-completado.component.scss']
})
export class CirculoProgresoIconoCompletadoComponent  {

  @Input('completado') completado : boolean = false;
  @Input('identificador') identificador : string;
  @Input ('visible') visible = true;

  @ViewChild('progresoComponente') progresoComponente: CirculoProgresoComponent;

  setProgreso(progreso) {
    this.progresoComponente?.setProgreso(progreso);
  }
}
