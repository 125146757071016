export enum FormatosFicherosEnum {
    pdf = '.pdf',
    jpg = '.jpg',
    jpeg ='.jpeg',
    png = '.png',
    gif = '.gif',
    json = '.json',
    zip = '.zip',
    csv = '.csv'
  }
  