<mat-chip class="etiqueta-input not-selectable"
  [style.background]="bgColor"
  [ngClass]="clase"
  [removable]="eliminable"
  [disabled]="desactivado"
  [color] ="'primary'"
  (click)="seleccionar.emit(true)"
  (removed)="eliminar.emit(true)">
     <neo-icono *ngIf="icono || iconoClase" [iconoClase]="iconoClase + ' mr-5 '" [icono]="icono"></neo-icono>
     <span class="text-truncate">
          <ng-template [ngTemplateOutlet]="contenidoTemplate || contenidoDefecto"></ng-template>
     </span>
     <neo-icono matChipRemove *ngIf="eliminable && !desactivado" [iconoClase]="'icon-18 icon-ibm--close'"></neo-icono>
</mat-chip>



<ng-template #contenidoDefecto>
     {{texto}}
</ng-template>
