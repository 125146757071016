import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { IconoDespliegueService } from '@appNeo/neoShared/services/icono-despliegue/icono-despliegue.service';

@Component({
  selector: 'neo-icono-despliegue [identificador]',
  templateUrl: './icono-despliegue.component.html',
  styleUrls: ['./icono-despliegue.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ],
  providers: [IconoDespliegueService]
})
export class IconoDespliegueComponent {

  @Input() identificador: string;

  constructor(
    public iconoDespliegueService : IconoDespliegueService
  ) {}

  async alternarExpandido() {
    await this.iconoDespliegueService.alternar();
  }

  expandir() {
    
    this.iconoDespliegueService.expandido = true;
  }

  colapsar() {
    this.iconoDespliegueService.expandido = false;
  }

}
