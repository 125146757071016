import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'neo-campo-radio [controlCampo]',
  templateUrl: './campo-radio.component.html',
  styleUrls: ['./campo-radio.component.scss']
})
export class CampoRadioComponent {

  @Input() controlCampo: FormControl;
  @Input() label = '';
  @Input() datos: [];
  @Input() color = 'primary';


  constructor() { }

}
