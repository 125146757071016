import { formatDate } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { INTERVALOMESESAGENDA } from '@appNeo/neoShared/components/calendario/calendario-configuracion';
// en vez de dataAdapter usamos directemnte la version date-fns
import { startOfDay, startOfWeek, endOfWeek, getDay, addDays, endOfDay, subDays, addMonths } from 'date-fns';


@Pipe({
  name: 'calendarioFecha'
})
export class CalendarioFechaPipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) private locale: string
  ) {}

  transform(
    date: Date,
    method: string,
    locale: string = this.locale,
    weekStartsOn:  0 | 1 | 2 | 3 | 4 | 5 | 6 = 0,
    excludeDays: number[] = [],
    daysInWeek?: number
  ): string {
    // console.log('method ', method, date);
    if (method === 'agendaViewTitle') {
        const { viewStart, viewEnd } = getWeekViewPeriod(
          date,
          weekStartsOn,
          excludeDays,
          daysInWeek
        );
        const format = (dateToFormat: Date, showYear: boolean) =>
        {
         let conAnhio = (showYear) ? ' de ' +  formatDate(dateToFormat, 'yyyy', locale) : '';
         return formatDate(dateToFormat, 'MMMM', locale) + conAnhio;
        }


        // let mes = formatDate(date, 'MMMM', locale);
        // let mesLetraInicial = mes.substring(0, 1).toUpperCase();
        // let mesLetraResto = mes.substring(1, mes.length).toLowerCase();
        // return mesLetraInicial + mesLetraResto + ' de ' + formatDate(date, 'y', locale);


        return `${format(
          viewStart,
          viewStart.getUTCFullYear() !== viewEnd.getUTCFullYear()
        )} - ${format(viewEnd, true)}`;
    }

    if (method === 'agendaViewColumnaFecha') {
      return formatDate(date, 'MMM, EEE', locale).replace(/\./g, '');
      // return formatDate(date, 'd MMM EEE', locale);
    }

    if (method === 'agendaViewColumnaFechaDia') {
      return formatDate(date, 'd', locale);
      // return formatDate(date, 'd MMM EEE', locale);
    }

    if (method === 'agendaViewColumnaIntervaloHorarioInicio') {
      const format = (dateToFormat: Date, mostrarMinutos: boolean) =>
        formatDate(dateToFormat, 'HH:mm', locale);
      // formatDate(dateToFormat, 'hh' + (mostrarMinutos ? ':mm' : ''), locale);
      return `${format(
        date,
        date.getMinutes()>0
      )}`;
    }

    if (method === 'agendaViewColumnaIntervaloHorarioFin') {
      const format = (dateToFormat: Date, mostrarMinutos: boolean) =>
        formatDate(dateToFormat, 'HH:mm', locale);
      // formatDate(dateToFormat, 'hh' + (mostrarMinutos ? ':mm' : '') + 'aa', locale);
      return `${format(
        date,
        date.getMinutes()>0
      )}`;
    }

    

    return method;
   
  }
}
export function getWeekViewPeriod(
  viewDate: Date,
  weekStartsOn: 0 | 1 | 2 | 3 | 4 | 5 | 6,
  excluded: number[] = [],
  daysInWeek?: number
): { viewStart: Date; viewEnd: Date } {
  let viewStart = daysInWeek
    ? startOfDay(viewDate)
    : startOfWeek(viewDate, { weekStartsOn });
  const _endOfWeek = endOfWeek(viewDate, { weekStartsOn });
  while (
    excluded.indexOf(getDay(viewStart)) > -1 &&
    viewStart < _endOfWeek
  ) {
    viewStart = addDays(viewStart, 1);
  }
  return { viewStart, viewEnd: addMonths(viewStart, 6) };
}

export function addDaysWithExclusions(
  date: Date,
  days: number,
  excluded: number[]
): Date {
  let daysCounter = 0;
  let daysToAdd = 0;
  const changeDays = days < 0 ? subDays : addDays;
  let result = date;
  while (daysToAdd <= Math.abs(days)) {
    result = changeDays(date, daysCounter);
    const day = getDay(result);
    if (excluded.indexOf(day) === -1) {
      daysToAdd++;
    }
    daysCounter++;
  }
  return result;
}