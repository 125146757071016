import {
  AfterViewInit,
  Component, ComponentFactoryResolver, ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort, Sort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {BusquedaComponent} from '@appNeo/neoShared/components/busqueda/busqueda.component';
import {
  AccionesTablaEnum, AnchoColumnaTablaEnum,
  IAccionRowTabla, IBotonAccionCabeceraTabla,
  IColumnaTabla,
  TablaService,
  TipoColumnaTablaEnum
} from '@appNeo/neoShared/services/tabla/tabla.service';
import {environment} from '@environments/environment';
import {FiltrosTablaComponent} from '@appNeo/neoShared/components/tabla/filtros-tabla/filtros-tabla.component';
import {SelectionModel} from '@angular/cdk/collections';
import {IFormInput} from '@appNeo/neoShared/helpers/interfaces/IForm-input';
import {FormularioService} from '@appNeo/neoShared/services/formulario/formulario.service';
import {FiltrosService} from '@appNeo/neoCore/services/filtros/filtros.service';
import {AuxiliarService} from '@appNeo/neoShared/services/auxiliar/auxiliar.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import  clonedeep from '@node_modules/lodash.clonedeep';
import {CdkTextareaAutosize} from '@node_modules/@angular/cdk/text-field';
import { Subscription } from 'rxjs';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { BotonDesplegableService, IAccionesBotonDesplegable } from '@appNeo/neoShared/services/boton-desplegable/boton-desplegable.service';
import { IPerfilPropiedad } from '@appNeo/neoShared/helpers/interfaces/IPerfilPropiedad';
import { PerfilPropiedadTagEnum } from '@appNeo/neoShared/helpers/enums/perfil-propiedad-tag.enum';
import { ContenedorMasinfoTablaComponent } from '../contenedor-masinfo-tabla/contenedor-masinfo-tabla.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {TableVirtualScrollDataSource} from '@node_modules/ng-table-virtual-scroll';
import {CdkVirtualScrollViewport} from '@node_modules/@angular/cdk/scrolling';
import {MatDialogRef} from '@node_modules/@angular/material/dialog';
import {IconoDespliegueComponent} from '@appNeo/neoShared/components/icono-despliegue/icono-despliegue.component';
import {TotalesComponent} from '@appNeo/neoShared/components/totales/totales.component';
import {distinctUntilChanged, map} from 'rxjs/operators';
import { TagCampoEnum } from '@appNeo/neoShared/helpers/enums/TagCampo.enum';

@Component({
  selector: 'neo-tabla-draggable',
  templateUrl: './tabla-draggable.component.html',
  styleUrls: ['./tabla-draggable.component.scss'],
  providers: [FormularioService, FiltrosService, BotonDesplegableService],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0px'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class TablaDraggableComponent implements OnInit, AfterViewInit {

  // Tabla Online => Filtrado y ordenación delegado en api
  @Input() tablaOnline = true;
  @Input() identificador = 'tabla-drag-1';
  //FILTRADO
  //Visualizar filtrado
  @Input() filtrado = true;
  //Filtrado en caliente
  @Input() filtradoSinBotonSubmit = false;
  @Input() keyFiltrado: string;
  @Input() valoresInicialesFiltrado: object;
  @Input() numeroFiltros: number = 0;
  //Inputs formlario filtrado
  @Input() inputsFormularioFiltrado: IFormInput[];

  // cabecera tabla
  @Input() mostrarTituloCabecera = true;
  @Input() titulo = '';
  @Input() mostrarAccionesCabecera = true;
  @Input() btnAccionesLote = false;
  @Input() btnDescargarPdfCabecera = false;
  @Input() accionesMultiplesDesplegable: IAccionesBotonDesplegable [] = [];
  @Input() botoneraAccionesCabecera: IBotonAccionCabeceraTabla [] = [
    {
      id: AccionesTablaEnum.Crear,
      color: 'primary',
      bloqueClass: 'btn-add',
      mostrarBtnLg: true,
      neoButtonClassLg: 'btn-crear-lg',
      iconClassLg: 'icon-16 icon-ibm--add',
      neoButtonInputClassLg: 'btn-sm',
      mostrarBtnXs: true,
      textoLg: 'Nueva Entrada',
      neoButtonClassXs: 'btn-crear-xs',
      iconClassXs: 'icon-16 icon-ibm--add',
      neoButtonInputClassXs: 'btn-sm',
      textoXs: '',
    }
  ];

  //Virtual Scroll (SOLO APLICA A TABLA ONLINE)
  @Input() ROW_HEIGHT = 70;

  //SOLO APLICA A TABLA OFFLINE
  @Input() columnasFiltro: string[];
  @Input() columnasFiltroFecha: [];
  @Input() ordenacionColumnas = false;

  // RESPONSIVO
  // por defecto, columna icono despligue contenido en subfila
  @Input() extraerMostrarColumnasOcultas = false;
  // accion en botonera fila
  @Input() mostrarColumnasOcultasEnDialogo = true;
  @Input() responsiveDinamico = true;

  //ACCIONES TABLA
  //Cabecera
  @Output() accionDescargarPdf = new EventEmitter<boolean>();
  @Output() accionClickRow = new EventEmitter<object>();
  @Output() accionCabeceraClick = new EventEmitter<[IBotonAccionCabeceraTabla]>();
  @Output() clickAccionRowTabla = new EventEmitter<[object, IAccionRowTabla []]>();
  @Output() accionMultiple = new EventEmitter<any>();
  @Output() accionVerInformacionRow = new EventEmitter<object>();
  @Output() filtrarBusqueda = new EventEmitter<string>();
  @Output() ordenarColumnas = new EventEmitter<string>();

  //SOLO APLICA A TABLA ONLINE
  @Output() aplicarFiltros = new EventEmitter<object>();

  valoresFiltrado: object;
  cambioValoresFiltrado = false;
  cambioValorBuscador = false;
  selection = new SelectionModel<object>(true, []);
  columnas: any[];

  dataSource: TableVirtualScrollDataSource<any> | MatTableDataSource<any>;
  totalResultados = 0;
  rowSeleccionadaBotonDesplegable: object;

  //Virtual Scroll
  offset: number;

  //VALORES POR DEFECTO CONTENIDO TABLA
  imgDefault = 'https://dummyimage.com/40/40';
  iconOcultarDefault = 'visibility_off';
  iconEditarDefault = 'edit';
  iconBorradoDefault = 'delete';
  iconoClaseOcultarDefault = 'icon-ibm--view--off icon-24';
  iconoClaseEditarDefault = 'icon-ibm--edit icon-24';
  iconoClaseBorradoDefault = 'icon-ibm--trash-can icon-24';

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('window') window: TemplateRef<any>;
  @ViewChild('tablaDraggable') tablaDraggable: any;
  @ViewChildren('textAreaColumnaEditable') textAreaColumnaEditable: QueryList<CdkTextareaAutosize>;

  // basico termino busqueda
  @ViewChild('filtrosBusqueda') filtrosBusqueda: BusquedaComponent;
  @ViewChildren(FiltrosTablaComponent) filtrosTabla: QueryList<FiltrosTablaComponent>;
  @ViewChild('rowTest') rowTest: ElementRef;
  @ViewChildren('columnaSinOrdenacion') columnaSinOrdenacion: QueryList<ElementRef>;
  @ViewChildren('columnaOrdenacion') columnaOrdenacion: QueryList<ElementRef>;
  @ViewChildren('columnaSelect') columnaSelect: QueryList<ElementRef>;

  // Virtual Scroll
  @ViewChild('row') rowTabla: ElementRef;
  @ViewChild('virtualScroller', {static: false}) virtualScroller: CdkVirtualScrollViewport;

  // Campos editables
  campoEditableFoco = null;
  textAreaOpen = false;
  posicionCampoEditableFoco = null;




  // responsivo
  currentScreenWidth: string = ''; // xs, md...
  flexMediaWatcher: Subscription; // susbscripcion resize dispositivo
  columnasTotales: IColumnaTabla[]; // maximo columnas a mostrar, backup columnas
  columnasOcultas:  IColumnaTabla[]; // cajon de columnas oculstas  mostrar en Ver mas info
  accionesBotonDesplegable: IAccionesBotonDesplegable []= [];
  subMenuDesplegableAccionesFila: Subscription;
  subMenuDespegableAccionesMultiples: Subscription;
  dialogoMasInfoRef: MatDialogRef<any, any>;
  propiedadesMasInfo;
  indiceFilaExpandida: number;
  filaExpandida: any | null;
  @ViewChildren('tableRow', { read: ViewContainerRef }) rowContainers;
  @ViewChildren(IconoDespliegueComponent) listadoIconoDespliegueComponent: QueryList<IconoDespliegueComponent>;
  @ViewChild('dialogoMasInfo') dialogoMasInfo: TemplateRef<any>;
  @ViewChild('dialogFiltrosResponsivos') dialogFiltrosResponsivos: TemplateRef<any>;
  botoneraDialogFiltrosResponsivos = [{
    label: 'Limpiar filtros',
    id: 'btn-limpiar',
    tipoAccion: 'accion',
    type: 'button',
    color: 'primary',
    disabled: this.deshabilitarLimpiarFiltros,
    activo: true,
    basic: true,
    iconoClase:'icon-20 icon-ibm--clean',
    matprefix: true
  },
    {
      label: 'Aplicar filtros',
      id: 'btn-filtrar',
      tipoAccion: 'accion',
      type: 'button',
      color: 'primary',
      disabled: false,
      activo: true,
      flat: true
    }];
  // columna automatica para el despliegue en caso de columnas ocultas
  columnaDespligueMasInfoKey = TipoColumnaTablaEnum.DespliegueMasInfo;
  columnaDespligueMasInfo = {key: this.columnaDespligueMasInfoKey, activa: false, nombre: '', tipo: TipoColumnaTablaEnum.DespliegueMasInfo, ordenacion: false, ancho: AnchoColumnaTablaEnum.xs};

  expandedElement: any | null;

  constructor(
    public dialog: MatDialog,
    public tablaService: TablaService,
    private router: Router,
    private formularioService: FormularioService,
    private filtrosService: FiltrosService,
    private auxiliarService: AuxiliarService,
    private mediaObserver: MediaObserver,
    private botonDesplegableService: BotonDesplegableService,
    private resolver: ComponentFactoryResolver,
    private element: ElementRef
  ) {}

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.flexMediaWatcher) {
      this.flexMediaWatcher.unsubscribe();
    }
    if (this.subMenuDesplegableAccionesFila){
      this.subMenuDesplegableAccionesFila.unsubscribe();
    }
  }
  ngOnInit() {
    if (this.filtrado) this.setInputsValidadoresFiltrado();
    this.numeroFiltros = (this.filtrado) ? this.numeroFiltros + 1 : 0;
    this.valoresFiltrado = Object.assign({}, this.getValoresFiltrado());
    if (JSON.stringify(this.valoresFiltrado) !== JSON.stringify(this.valoresInicialesFiltrado)) this.cambioValoresFiltrado = true;
    this.subscripcioncolumnasTabla();
    this.subscripcionTotalDataSourceTabla();
    this.subscripcionDataSourceTabla();
    this.determinarTotalElementos();
    this.subscripcionFilteredData();
    this.subscripcionSeleccionarTodo();
    this.subscripcionSeleccionarItem();
    this.subscripcionMenuDesplegableAccionesFila();
    this.botonDesplegableService.acciones = this.accionesMultiplesDesplegable;
    this.subscripcionMenuDesplegableAccionesMultiples();
    this.columnas = this.columnasTotales;
    if (!this.responsiveDinamico) {
      // Responsivo por configuracion prioridades segun classResponsive en conf tabla
      this.flexMediaWatcher = this.mediaObserver.media$.subscribe((change: MediaChange) => {
        if (change.mqAlias !== this.currentScreenWidth) {
          this.currentScreenWidth = change.mqAlias;
          this.columnasOcultas = [];

          this.columnas = this.columnasTotales.filter( columna =>
          {
            let mostrar = this.esColumnaVisible(columna.classResponsive) || (['select', 'iconDrag', 'acciones'].indexOf(columna.key)>=0 );
            if (!mostrar) {
              this.columnasOcultas.push(columna);
            }
            return mostrar;
          });
          // columna auto con icono despligue que se mete en total columnas ya filtradas si es que existe columnas ocultas
          if (!this.mostrarColumnasOcultasEnDialogo) {
            this.columnas = this.establecerVisibilidadColumnaDespligue(this.columnasOcultas.length>0, this.columnas);
          }
        }
      });
    }

  }

  ngAfterViewInit(): void {
    // this.columnas = this.columnasTotales;
    if(this.responsiveDinamico) {
    this.flexMediaWatcher = this.mediaObserver.media$.subscribe((change: MediaChange) => {
      if (change.mqAlias !== this.currentScreenWidth) {
        // console.log(' *** Dispositivo ', this.currentScreenWidth);
        this.currentScreenWidth = change.mqAlias;
        // console.warn('CAMBIO MEDIA ... ', this.keysColumnasTotales);
        setTimeout( () => {
          this.aplicarCompotamientoResponsivoDinamico();
        }, 500)
      }
     });
    }

    if (this.tablaOnline) {
      this.virtualScroller.scrolledIndexChange
        .pipe(
          map(() => this.virtualScroller.getOffsetToRenderedContentStart() * -1),
          distinctUntilChanged(),
        )
        .subscribe(offset => (this.offset = offset));

      this.virtualScroller.renderedRangeStream.subscribe(range => {
        this.offset = range.start * -this.ROW_HEIGHT;
      });
    }
  }

  determinarTotalElementos() {
    if (this.tablaOnline) {
      this.subscripcionTotalDataSourceTabla();
    } else if (this.dataSource?.data) {
      this.totalResultados = this.dataSource.filteredData.length;
    }
  }

  // no responsiveDinamico
  esColumnaVisible(puntoCorteVisibleColumna: string) {
    let permitido = true;
    if (puntoCorteVisibleColumna) {
      // console.log(puntoCorteVisibleColumna, this.currentScreenWidth);
      // console.log('DEBUG ESCOLUMNAVISIBLE(): ', puntoCorteVisibleColumna, this.currentScreenWidth);
      switch(this.currentScreenWidth) {
        case 'lg':
          permitido = puntoCorteVisibleColumna.includes('lg') || puntoCorteVisibleColumna.includes('md') || puntoCorteVisibleColumna.includes('sm') || puntoCorteVisibleColumna.includes('xs');
          break;
        case 'md':
          permitido =  puntoCorteVisibleColumna.includes('md') || puntoCorteVisibleColumna.includes('sm') || puntoCorteVisibleColumna.includes('xs');
          break;
        case 'sm':
          permitido =  puntoCorteVisibleColumna.includes('sm') || puntoCorteVisibleColumna.includes('xs');
          break;
        case 'xs':
          permitido =  puntoCorteVisibleColumna.includes('xs');
          break;
        default:
          permitido = true;
      }
    }
    return permitido;
  }

  get deshabilitarLimpiarFiltros() {
    if (!this.cambioValoresFiltrado && !this.cambioValorBuscador) {
      if (this.botoneraDialogFiltrosResponsivos) this.botoneraDialogFiltrosResponsivos[0].disabled = true;
      return true;
    }
    if (this.botoneraDialogFiltrosResponsivos) this.botoneraDialogFiltrosResponsivos[0].disabled = false;
    return false;
  }


   // responsiveDinamico
   calcularAnchoColumnas(arrKeyColumnas: string[]) {
    // // console.log('Estas key cogen ', arrKeyColumnas);
    // let widthRow =  this.rowTest.nativeElement.clientWidth;
    let anchoTotalColumna = 0;
    let celdas = document.querySelectorAll('[id="' + this.identificador + '"] [role="columnheader"]') as NodeList;
    // console.log('CEldas ', celdas.length);
    var arrayNodes = [].slice.call(celdas, 0);
    arrayNodes.forEach((valor, indice, array) => {
      // // console.log(celdas[indice]);
      let identificador = (array[indice] as HTMLElement).getAttribute('key');
      // console.log((array[indice] as HTMLElement).offsetWidth, (array[indice] as HTMLElement).hasAttribute('key'), (array[indice] as HTMLElement).getAttribute('key'));
      if (arrKeyColumnas.indexOf(identificador)>=0){
        anchoTotalColumna += (array[indice] as HTMLElement).offsetWidth;
      }
    });

    const anchoDisponible = this.element.nativeElement.querySelector('.table-container');
    let ancho = anchoDisponible.offsetWidth;


    let anchoTabla = (document.getElementsByClassName('mat-paginator')[0] as HTMLElement)?.offsetWidth;
    // console.log(`Ancho disponible ${ancho} opcion B ${anchoTabla} necesario ${anchoTotalColumna}`);
    //TODO: CUANTO MIDE FILA, COLUMNA, COGEN?, SI NO COGE LA ÚLTIMA COLUMNA ANTES DE ACCIONES LA QUITO
    // // console.log('ANCHO COLUMNAS: ', widthColumnas);
    // // console.log('ANCHO ROW: ', anchoTabla);
    // console.log('TODO OK: ', anchoTotalColumna, anchoTotalColumna<=anchoTabla);
    // // console.log("****************************************");
    // // console.log("****************************************");
    return anchoTotalColumna<=ancho;
  }

  aplicarCompotamientoResponsivoDinamico() {
    this.columnasOcultas = [];
    let columnasReverse = [];
    this.columnas = this.columnasTotales;
    setTimeout(() => {
      let columnaskey = this.keysColumnasTotales;
      let fuenteColumnas =  Object.assign([], this.columnasTotales);
      this.columnasOcultas = [];
      columnasReverse = (fuenteColumnas.reverse()).filter(columna => {
        let keyActual = columna.key;
        let mostrar = true;
        // console.log('-->', keyActual);
        if (['select', 'iconDrag', 'acciones'].indexOf(columna.key) < 0 && (!columna?.fija)) {
          // // console.log('-->', 'No fijo');
          if ( !this.calcularAnchoColumnas(columnaskey) ) {
            // console.log(`[RESPONSIVO] columna ${keyActual} ocultar`);
            mostrar = false;
            columnaskey = removeItemFromArr( columnaskey, keyActual );
            this.columnasOcultas.push(columna);
          }
        }
        return mostrar;
      });
      this.columnasOcultas = this.columnasOcultas.reverse();

      this.columnas = columnasReverse.reverse();

      // console.log('Columnas ocultas ', this.columnasOcultas);
      // console.log('Columnas ', this.columnas);

      // columna auto con icono despligue que se mete en total columnas ya filtradas si es que existe columnas ocultas
      if (!this.mostrarColumnasOcultasEnDialogo) {
        // console.log('Columnas ocultas son ', this.columnasOcultas.length);
        this.columnas = this.establecerVisibilidadColumnaDespligue(this.columnasOcultas.length>0, this.columnas);
      }
    }, 500)
  }


  // responsiveDinamico
  // calcularAnchoColumnas(arrKeyColumnas: string[]) {
  //   console.log('Estas key cogen ', arrKeyColumnas);
  //   // let widthRow =  this.rowTest.nativeElement.clientWidth;
  //   let anchoTotalColumna = 0;
  //   let celdas = document.querySelectorAll('mat-header-cell') as NodeList;
  //   var arrayNodes = [].slice.call(celdas, 0);
  //   arrayNodes.forEach((valor, indice, array) => {
  //     let identificador = (array[indice] as HTMLElement).getAttribute('key');
  //     console.log((array[indice] as HTMLElement).offsetWidth, (array[indice] as HTMLElement).hasAttribute('key'), (array[indice] as HTMLElement).getAttribute('key'));
  //     if (arrKeyColumnas.indexOf(identificador)>=0){
  //       anchoTotalColumna += (array[indice] as HTMLElement).offsetWidth;
  //     }
  //   });
  //   let anchoTabla = (document.getElementsByClassName('virtual-scroll wrapper')[0] as HTMLElement).offsetWidth;
  //   //TODO: CUANTO MIDE FILA, COLUMNA, COGEN?, SI NO COGE LA ÚLTIMA COLUMNA ANTES DE ACCIONES LA QUITO
  //   // console.log('ANCHO COLUMNAS: ', widthColumnas);
  //   console.log('ANCHO ROW: ', anchoTabla);
  //   console.log('TODO OK: ', anchoTotalColumna, anchoTotalColumna<=anchoTabla);
  //   console.log("****************************************");
  //   console.log("****************************************");
  //   return anchoTotalColumna<=anchoTabla;
  // }

  // aplicarCompotamientoResponsivoDinamico() {
  //   this.columnasOcultas = [];
  //   let columnasReverse = [];
  //   this.columnas = this.columnasTotales;
  //   setTimeout(() => {
  //     console.log(" K E Y S ", this.keysColumnasTotales);
  //     let columnaskey = this.keysColumnasTotales;
  //     let fuenteColumnas =  Object.assign([], this.columnasTotales);
  //     columnasReverse = (fuenteColumnas.reverse()).filter(columna => {
  //       let keyActual = columna.key;
  //       let mostrar = true;
  //       console.log('-->', keyActual);
  //       if (['select', 'iconDrag', 'acciones'].indexOf(columna.key) < 0 && (!columna?.fija)) {
  //         console.log('-->', 'No fijo');
  //         if ( !this.calcularAnchoColumnas(columnaskey) ) {
  //           mostrar = false;
  //           columnaskey = removeItemFromArr( columnaskey, keyActual );
  //           this.columnasOcultas.push(columna);
  //         }
  //       }
  //       return mostrar;
  //     }, 1000);
  //     this.columnasOcultas = this.columnasOcultas.reverse();
  //     this.columnas = columnasReverse.reverse();
  //   })
  // }


  get keysColumnas(): string[] {
    if (!this.columnas) {
      return [];
    }
    return this.columnas.map((column: object) => column['key']);
  }

  // Todo: No eliminar por el momento
  // public get inverseOfTranslation(): string {
  //   if (!this.virtualScroller || !this.virtualScroller["_renderedContentOffset"]) {
  //     return "-0px";
  //   }
  //   let offset = this.virtualScroller["_renderedContentOffset"];
  //   return `-${offset}px`;
  // }

  get keysColumnasTotales(): string[] {
    if (!this.columnasTotales) {
      return [];
    }
    return this.columnasTotales.map((column: object) => column['key']);
  }

  setInputsValidadoresFiltrado() {
    this.formularioService.inputs = this.inputsFormularioFiltrado;
  }

  getValoresFiltrado() {
    return this.filtrosService.getFiltros(this.keyFiltrado, this.valoresInicialesFiltrado);
  }

  subscripcioncolumnasTabla(): void {
    this.tablaService.columnas$.subscribe(columnas => {
      this.columnasTotales = columnas;
      this.columnas = columnas;
    });
  }

  obtenerAccionesBotonDesplegable (fila) {
    let accionesFila = [];
    fila.acciones.forEach(accionFila => {
      accionesFila.push({id: accionFila.id, iconoClase: accionFila.iconClass + ' mr-10 ', texto: accionFila.tooltip});
    });
    return accionesFila;
  }

  subscripcionMenuDesplegableAccionesFila() {
    this.subMenuDesplegableAccionesFila = this.botonDesplegableService.accionItemSeleccionados$.subscribe(accionItemSeleccionada => {
      this.clickAccionRowTabla.emit([this.rowSeleccionadaBotonDesplegable, accionItemSeleccionada]);
    });
  }

  subscripcionDataSourceTabla(): void {
    this.tablaService.data$.subscribe( data => {
      if (this.tablaOnline) {
        this.dataSource = new TableVirtualScrollDataSource(data.data);
      } else {
        this.dataSource = new MatTableDataSource(data.data.slice(0, 20));
      }
      this.determinarTotalElementos();
      this.setIconosAccionesDefault();
      if (this.filtrado) this.filtrar();
      if (!this.tablaOnline) this.tablaService.total = this.dataSource.filteredData.length;
    });
  }

  subscripcionFilteredData() {
    this.tablaService.filteredData$.subscribe(filteredData => {
      this.dataSource.filteredData = filteredData;
    });
  }

  subscripcionSeleccionarTodo(): void {
    this.tablaService.seleccionarTodo$.subscribe(seleccionarTodo => {
      seleccionarTodo ? this.seleccionarTodo() : this.deseleccionarTodo();
    });
  }

  subscripcionSeleccionarItem(): void {
    this.tablaService.seleccionarItems$.subscribe(items => {
      if (items) {
        items.forEach(item => {
          this.dataSource.data.forEach(row => {
            if (row.id && row.id === item['id']) {
              item['seleccionado'] ? this.selection.select(row) : this.selection.deselect(row);
            }
          });
        });
      }
    });
  }

  seleccionarTodo(): void {
    this.selection.select(...this.dataSource.data);
  }

  deseleccionarTodo(): void {
    this.selection.deselect(...this.dataSource.data);
  }

  setIconosAccionesDefault() {
    this.dataSource.data.forEach(rowTabla => {
      if(rowTabla?.acciones) {
        if (!rowTabla?.acciones?.iconoOcultar && rowTabla?.acciones?.ocultar) rowTabla.acciones.iconoClaseOcultar = this.iconoClaseOcultarDefault;
        if (!rowTabla?.acciones?.iconoEdicion && rowTabla?.acciones?.editar) rowTabla.acciones.iconoClaseEdicion = this.iconoClaseEditarDefault;
        if (!rowTabla?.acciones?.iconoBorrado && rowTabla?.acciones?.eliminar) rowTabla.acciones.iconoClaseBorrado = this.iconoClaseBorradoDefault;
      }
    });
  }



  actualizarDataSource() {
    this.tablaService.data = this.dataSource;
  }

  subscripcionTotalDataSourceTabla() {
    this.tablaService.total$.subscribe(totalResultados => {
      this.totalResultados = totalResultados;
    });
  }

  filtrar() {
    setTimeout(() => {
      this.filtrosTabla.forEach(filtroTabla => {
        filtroTabla.validar();
        if (!filtroTabla.determinarErroresValidacion()) {
          if (this.tablaOnline) {
            this.aplicarFiltros.emit(this.valoresFiltrado);
          } else {
            this.aplicarFiltrosOffline();
          }
        }
      });
    });
  }

  actualizarTablaOffline() {
    if ( this.dataSource ) {
      if (this.ordenacionColumnas) {
        this.dataSource.sort = this.sort ? this.sort : null;
      }
      if (this.filtrado && this.columnasFiltro) {
        this.dataSource.filterPredicate = this.buscarCoincidenciaRowTablaOffline.bind(this);
      }
      this.determinarTotalElementos();
    }
  }

  buscarCoincidenciaRowTablaOffline(row, filtroBusqueda): boolean {
    if (filtroBusqueda) {
      let coincidencias = [];
      for (let i = 0; i < this.columnasFiltro.length; i++) {
        let columnaFiltro = this.columnasFiltro[i];
        let valorFiltro = filtroBusqueda.trim().toLowerCase();
        let valorTabla = '';
        if (Date.parse(row[columnaFiltro])) {
          valorTabla = row[columnaFiltro];
        } else if (typeof row[columnaFiltro] === 'object') {
          valorTabla = row[columnaFiltro].nombre;
        } else {
          valorTabla = row[columnaFiltro];
        }


        if (valorTabla) {
          let coincidencia;
          if (Number(valorTabla) && Number(valorFiltro)) {
            Number(valorTabla) === Number(valorFiltro) ? coincidencia = true : coincidencia = false;
          } else {
            valorTabla = String(valorTabla).trim().toLowerCase();
            coincidencia = valorTabla.includes(valorFiltro);
          }
          coincidencias.push(coincidencia);
        }
      }

      if (coincidencias.includes(true)) {
        return true;
      }  else {
        return  false;
      }
    }

    return true;
  }

  aplicarFiltrosOffline() {
    this.actualizarTablaOffline();
    const inputsFiltro = this.valoresFiltrado['busqueda'];
    if (this.dataSource) {
      this.dataSource.filter = inputsFiltro;
      this.tablaService.total = this.dataSource.filteredData.length;
    }
  }

  eventoCambioBuscador(filterValue: string) {
    filterValue ? this.cambioValorBuscador = true : this.cambioValorBuscador = false;
    this.valoresFiltrado['busqueda'] = filterValue;
    this.guardarFiltrosStorage(this.valoresFiltrado);
    this.filtrar();
  }

  eventoCambioFormularioFiltros(entidadFormulario: object) {
    this.determinarCambioValoresFiltradoIniciales(entidadFormulario);
    this.guardarFiltrosStorage(entidadFormulario);
    this.valoresFiltrado = Object.assign({}, entidadFormulario);
    if (this.filtradoSinBotonSubmit) {
      if (this.filtrosBusqueda.termino) this.valoresFiltrado['busqueda'] = this.filtrosBusqueda.termino.trim().toLowerCase();
      this.filtrar();
    }
  }

  determinarCambioValoresFiltradoIniciales(entidadFormulario) {
    JSON.stringify(entidadFormulario) !== JSON.stringify(this.valoresInicialesFiltrado) ? this.cambioValoresFiltrado = true : this.cambioValoresFiltrado = false;
  }

  submitFiltrado() {
    if (this.filtrosBusqueda.termino) this.valoresFiltrado['busqueda'] = this.filtrosBusqueda.termino.trim().toLowerCase();
    this.guardarFiltrosStorage(this.valoresFiltrado);
    this.filtrar();
  }

  //TODO: AÑADIR FLAG PARA GUARDAR INPUT BÚSQUEDA
  guardarFiltrosStorage(valoresFiltrado: object) {
    let filtradoSinBusqueda = Object.assign({}, valoresFiltrado);
    delete filtradoSinBusqueda['busqueda'];
    this.filtrosService.setFiltros(this.keyFiltrado, filtradoSinBusqueda);
  }

  clearFilter() {
    this.filtrosService.clearFiltros(this.keyFiltrado);
    this.filtrosBusqueda.clearBuscador();
    this.filtrosTabla.forEach(filtroTabla => {
      filtroTabla.clearFilter();
    });
    this.cambioValoresFiltrado = false;
  }

  clearBuscador() {
    this.filtrosBusqueda.clearBuscador();
  }


  // get filasSeleccionadas() {
  //   return this.selection.selected;
  // }

  toogleMasterChecked(): boolean {
    return this.todasSeleccionadas();
  }

  toogleMasterIndeterminate(): boolean {
    return !this.todasSeleccionadas() && this.totalItemSeleccionados() > 0;
  }

  toogleMasterClick($event) {
    this.actualizarTablaOffline();
    $event.preventDefault();
    $event.stopPropagation();

    if (this.todasSeleccionadas()) {
      // this.selection.deselect(...rowsVisibles);
      this.dataSource.data.forEach(item => {
        item['seleccionado'] = false;
        console.log('DESELECCIONAR ITEM: ', item['seleccionado']);
      });
      this.tablaService.seleccionarItems = [];
    } else {
      // this.selection.select(...rowsVisibles);
      this.dataSource.data.forEach(item => {
        item['seleccionado'] = true;
      });
      this.tablaService.seleccionarItems = this.dataSource.data;
    }
  }

  get rowsSeleccionadas() {
    let seleccionadas = 0;
    this.dataSource.data.forEach(row => {
      if (row['seleccionado']) seleccionadas ++;
    });

    return seleccionadas;
  }

  seleccionarRow(row: object) {
    const seleccionadas = this.filasSeleccionadas();
    seleccionadas.forEach(item => {
      if (item['id'] === row['id']) {
        item['seleccionado'] === true ? item['seleccionado'] = false : item['seleccionado'] = true;
      }
    });
    this.tablaService.seleccionarItems = seleccionadas;
  }

  totalItemSeleccionados(): number {
    let rows;
    this.tablaOnline ? rows = this.dataSource.data : rows = this.dataSource.filteredData;
    let count = 0;
    for (let item of rows) {
     if (item['seleccionado'])  count++;
    }
    return count;
  }

  filasSeleccionadas(): object [] {
    let rows;
    this.tablaOnline ? rows = this.dataSource.data : rows = this.dataSource.filteredData;
    const rowsSeleccionadas = [];
    for (let row of rows) {
      rowsSeleccionadas.push(row);
    }
    return rowsSeleccionadas;
  }

  todasSeleccionadas(): boolean {
    let rows;
    this.tablaOnline ? rows = this.dataSource.data : rows = this.dataSource.filteredData;
    let count = 0;
    for (let item of rows) {
      if (item['seleccionado'])  count++;
    }

    return rows.length === count;
  }

  ordenarDataSourceByColumna(sort) {
    console.log('ONLINE!: ', this.tablaOnline);
    if (this.tablaOnline) {
      this.ordenarColumnas.emit(sort);
    } else {
      this.ordenarColumnaTablaOffline( sort );
    }
  }

  ordenarColumnaTablaOffline( sort: Sort ){
    const data = this.dataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }
    const keyColumna = sort.active;
    this.dataSource.data =  data.sort((rowA, rowB) => {
      const isAsc = sort.direction === 'asc';
      return this.compararColumnas(rowA[keyColumna], rowB[keyColumna], isAsc);
    });
    this.actualizarDataSource();
  }

  compararColumnas(rowA: any, rowB: any, isAsc: boolean) {
    switch (typeof rowA) {
      case 'string':
        rowA = rowA?.trim().toLowerCase();
        rowB = rowB?.trim().toLowerCase();
        break;
      case 'object':
        if (Number(rowA.nombre)) {
          rowA = Number(rowA.nombre);
          rowB = Number(rowB.nombre);
        } else if (typeof rowA.nombre === 'string') {
          rowA = rowA.nombre.trim().toLowerCase();
          rowB = rowB.nombre.trim().toLowerCase();
        }
        break;
    }

    return (rowA < rowB ? -1 : 1) * (isAsc ? 1 : -1);
  }


  //ACCIONES CABECERA TABLA
  descargarPdf() {
    this.accionDescargarPdf.emit();
  }

  // ACCIONES TABLA
  get deshabilitarAcciones() {
    if (this.totalItemSeleccionados() > 1) {
      return true;
    }
  }



  clickLink(page, id) {
    // console.log('Click link', page, id);
    if (page) {
      if (id) {
        this.router.navigate([page, id]);
      } else {
        this.router.navigate([page]);
      }
    }
  }

  verInformacionRowClick(event) {
    this.obtenerContenidoVerInformacion(event);

    if ( this.extraerMostrarColumnasOcultas ) {
      this.accionVerInformacionRow.emit(this.propiedadesMasInfo);
    } else {
      if (this.mostrarColumnasOcultasEnDialogo) {
        this.abrirDialogoVerInformacion();
      }
    }
  }

  abrirDialogoVerInformacion() {
    this.dialogoMasInfoRef = this.dialog.open(this.dialogoMasInfo);
  }

  establecerVisibilidadColumnaDespligue(visible: boolean, columnasMapeadasVisibles) {
    if (visible) {
      let indice = columnasMapeadasVisibles[0].tipo === TipoColumnaTablaEnum.Selector ? 1 : 0;
      let cabecera = columnasMapeadasVisibles.slice(0,indice);
      let cola = columnasMapeadasVisibles.slice(indice,columnasMapeadasVisibles.length);
      return cabecera.concat([this.columnaDespligueMasInfo]).concat(cola);
    }
    return columnasMapeadasVisibles;
  }

  incrustarComponente(row, indiceFila: number) {
    if ( !this.extraerMostrarColumnasOcultas && !this.mostrarColumnasOcultasEnDialogo ) {
      let identificadorIconoDespliegueFila = 'fila_' + indiceFila + '_';
      let iconoDespligueFila = this.listadoIconoDespliegueComponent.find((iconoDespligueComponent: IconoDespliegueComponent, index: number, array) =>
      {
        iconoDespligueComponent.colapsar();
        return iconoDespligueComponent.identificador === identificadorIconoDespliegueFila;
      });



      if (this.indiceFilaExpandida != null) {
        // clear old content
        this.rowContainers.toArray()[this.indiceFilaExpandida].clear();
      }
      if (this.columnasOcultas.length) {
        this.filaExpandida = this.filaExpandida === row ? null : row;
        this.obtenerContenidoVerInformacion(row);
        if (this.indiceFilaExpandida === indiceFila) {
          this.indiceFilaExpandida = null;
          // this.filaExpandida = null;
        } else {
          if (iconoDespligueFila) {
            iconoDespligueFila.expandir();
          }
          // const container = this.rowContainers.toArray()[indiceFila];
          // const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(ContenedorMasinfoTablaComponent);
          // const inlineComponent = container.createComponent(factory);

          // inlineComponent.instance.propiedades = this.propiedadesMasInfo;
          this.indiceFilaExpandida = indiceFila;
          // this.filaExpandida =row;
        }
      }
    }

  }

  clickRow( event , indiceFila: number) {
    console.log('Click row', event, indiceFila);
    this.accionClickRow.emit(event);
    if (!this.extraerMostrarColumnasOcultas) {
      this.incrustarComponente(event, indiceFila);
    }
  }

  deshabilitarAccionRow(accion) {
    return accion.disabled || this.deshabilitarAcciones;
  }


  subscripcionMenuDesplegableAccionesMultiples() {
    if (this.subMenuDespegableAccionesMultiples) {
      this.subMenuDespegableAccionesMultiples.unsubscribe();
    }
    this.subMenuDespegableAccionesMultiples = this.botonDesplegableService.accionSeleccionada$.subscribe(accionSeleccionada => {
      this.accionMultiple.emit({accion: accionSeleccionada, seleccion: this.selection.selected});
    });
  }

  obtenerContenidoVerInformacion(event){
    let propiedades:  IPerfilPropiedad[] = [];
    this.columnasOcultas.forEach( columna =>
      {
        let tipo = <unknown>columna.tipo; //conversion de tipoColumna a tipoPerfilPropiedad
        let tagCampo = <unknown>columna.tagCampo; //conversion de tipoColumna a tipoPerfilPropiedad
        let valor = event[columna.key];
        if(tipo == PerfilPropiedadTagEnum.Imagen) {
          valor = (event[columna.key].src) ? event[columna.key].src : this.imgDefault;
        }
        return propiedades.push({label: columna.nombre, tag: <PerfilPropiedadTagEnum>tipo,tagCampo: <TagCampoEnum>tagCampo, identificador: columna.key, valor, unidad: columna.unidad});
      }
    );
    this.propiedadesMasInfo = propiedades;
  }

  eventoDrag(event: CdkDragDrop<string[]>) {
    // console.log('CURRENT INDEX: ', event.currentIndex);
    if (this.tablaOnline) {
      const vsStartIndex = this.virtualScroller.getRenderedRange().start;
      moveItemInArray(this.dataSource.data, event.previousIndex + vsStartIndex, event.currentIndex + vsStartIndex);
      this.dataSource.data = clonedeep(this.dataSource.data);
    } else {
      const itemArrastrado = JSON.parse(event.item.element.nativeElement['dataActual']);
      let indexDataOrigen = -1;
      let indexDataDestino = -1;
      var itemReferencia: object;

      moveItemInArray(this.dataSource.filteredData, event.previousIndex, event.currentIndex);
      itemReferencia = clonedeep(this.getItemReferenciaDataFiltrada(itemArrastrado, event.previousIndex, event.currentIndex));
      // console.log('ITEM REFERENCIA: ', itemReferencia);
      // console.log('ITEM ARRASTRADO: ', itemArrastrado);

      const posiciones = this.posicionesDataSinFiltrar(itemArrastrado, itemReferencia);
      indexDataOrigen = posiciones[1];
      indexDataDestino = posiciones[0];

      moveItemInArray(this.dataSource.data, indexDataOrigen, indexDataDestino);
      this.dataSource.filteredData = clonedeep(this.dataSource.filteredData);
      this.actualizarDataSource();
    }
  }

  getItemReferenciaDataFiltrada(itemArrastrado, previousIndex, currentIndex): object {
    for (let i = 0; i < this.dataSource.filteredData.length; i ++) {
      if (JSON.stringify(itemArrastrado) === JSON.stringify(this.dataSource.filteredData[i])) {
        if (currentIndex < previousIndex) {
          return clonedeep(this.dataSource.filteredData[i + 1]);
        } else {
          return clonedeep(this.dataSource.filteredData[i - 1]);
        }
      }
    }
  }

  posicionesDataSinFiltrar(itemArrastrado: object, itemReferencia: object) {
    let indexDataOrigen = -1;
    let indexDataDestino = -1;
    // console.log('ITEM ARRASTRADO: ', itemArrastrado);
    // console.log('ITEM REFERENCIA: ', itemReferencia);
    // console.log('POS ITEM ARRASTRADO: ', this.dataSource.data.indexOf(JSON.stringify(itemArrastrado)));
    // console.log('DATA: ', this.dataSource.data);
    for (let i = 0; i < this.dataSource.data.length; i ++) {
      if (itemArrastrado['id'] === this.dataSource.data['id']) {
        // console.log('COINCIDENCIA DESTINO: ', i);
        indexDataOrigen = i;
      }
      if (itemReferencia['id'] === this.dataSource.data['id']) {
        // console.log('COINCIDENCIA DESTINO: ', i);
        indexDataDestino = i;
      }
    }

    return [indexDataOrigen, indexDataDestino];
  }

  focusInputMascaraTextareaEdicion(row, posicionRow: number) {

    if (this.posicionCampoEditableFoco >= 0) {
      this.focusOutTextAreaEdicion(this.posicionCampoEditableFoco);
    }
    this.campoEditableFoco = row;
    this.textAreaColumnaEditable['_results'][posicionRow];
    setTimeout(()=>{
      this.focusTextAreaEdicion(posicionRow);
    },100)
  }


  focusTextAreaEdicion(posicionRow: number) {
    this.posicionCampoEditableFoco = posicionRow;
    this.textAreaOpen[posicionRow] = true;
    if (this.textAreaColumnaEditable['_results'][posicionRow] && this.textAreaColumnaEditable['_results'][posicionRow].maxRows ) {
      this.textAreaColumnaEditable['_results'][posicionRow].maxRows = this.numeroRowsCeldaEditableFocus;
    }
  }


  focusOutTextAreaEdicion(posicionRow: number) {

    this.posicionCampoEditableFoco = null;
    this.textAreaOpen[posicionRow] = false;
    if (this.textAreaColumnaEditable['_results'][posicionRow] && this.textAreaColumnaEditable['_results'][posicionRow].maxRows ) {

      this.textAreaColumnaEditable['_results'][posicionRow].maxRows = this.numeroRowsCeldaEditableInicial;
    }
    this.actualizarDataSource();
    this.campoEditableFoco = undefined;
  }


  get numeroRowsCeldaEditableFocus(): number {
    return environment.numero_rows_celda_editable_focus;
  }


  get numeroRowsCeldaEditableInicial(): number {
    return environment.numero_rows_celda_editable_inicial;
  }

  esString(valor) {
    return typeof valor === 'string' || valor instanceof String
  }

  abrirFiltros(){
    this.dialog.open(this.dialogFiltrosResponsivos, {disableClose: true});
  }

  submitAccionDialogFiltrosResponsivos(accionSeleccionada){
    switch(accionSeleccionada.id) {
      case 'btn-filtrar':
        this.filtrar();
        if (JSON.stringify(this.filtrosTabla.first.valoresFiltrado) !== JSON.stringify(this.filtrosTabla.last.valoresFiltrado)) {
          this.filtrosTabla.first.setValores(this.filtrosTabla.last.valoresFiltrado);
        }
        // this.dialogoMasInfoRef.close();
        if (!this.filtrosTabla.last.determinarErroresValidacion()) {
          this.dialog.closeAll();
          this.submitFiltrado();
        }
        break;
      case 'btn-limpiar':
        this.clearFilter();
        break;
    }
  }
}

function removeItemFromArr( arr, item ) {
  return arr.filter( function( e ) {
      return e !== item;
  } );
};
