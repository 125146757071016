import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

export enum DireccionArrastreEnum {
  Izquierda = 'izquierda',
  Derecha = 'derecha',
}

export interface IColumnaDragDrop {
  id: string;
  nombre: string;
  visibilidadFiltrado: boolean;
}


@Injectable()
export class DragDropService {
  public columnaIzquierda$: BehaviorSubject<IColumnaDragDrop[]> = new BehaviorSubject([]);
  public columnaDerecha$: BehaviorSubject<[]> = new BehaviorSubject([]) ;

  constructor() {}

  set columnaIzquierda(itemsColumnaIzq: IColumnaDragDrop[]){
    this.columnaIzquierda$.next(itemsColumnaIzq);
  }

  set columnaDerecha(itemsColumnaDcha){
    this.columnaDerecha$.next(itemsColumnaDcha);
  }

  get _DireccionArrastreEnum() {
    return DireccionArrastreEnum;
  }
}
