import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Subject, Subscription} from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ITodoNodoItem, ITodoNodoItemPlano } from '@appNeo/neoShared/helpers/interfaces/ITodo-nodo-item';
import { ArbolService } from '@appNeo/neoShared/services/arbol/arbol.service';
import { BotonDesplegableService } from '@appNeo/neoShared/services/boton-desplegable/boton-desplegable.service';
import { MatDialog } from '@angular/material/dialog';
import { IBotonAccionesDialog } from '@appNeo/neoShared/components/acciones-dialog/acciones-dialog.component';
import { FormularioComponent } from '@appNeo/neoShared/components/formulario/formulario.component';
import { FormularioService } from '@appNeo/neoShared/services/formulario/formulario.service';
import { ClasesCampoLayoutCamposPorcentajeFormularioEnum, IFormInput } from '@appNeo/neoShared/helpers/interfaces/IForm-input';
import { FormTagEnum } from '@appNeo/neoShared/helpers/enums/FormTag.enum';


@Component({
  selector: 'neo-arbol',
  templateUrl: './arbol.component.html',
  styleUrls: ['./arbol.component.scss'],
  providers: [BotonDesplegableService, FormularioService]
})


export class ArbolComponent implements OnInit{

  @Input('busqueda') busqueda: boolean = true;
  @Input('btnAccion') btnAccion: boolean = true;

   /** Asignar de nodo plano a nodo anidado. Esto nos ayuda a encontrar el nodo anidado que se va a modificar. */
   mapeoNodoPlano = new Map<ITodoNodoItemPlano, ITodoNodoItem>();

   /** Asignar de nodo anidado a nodo aplanado. Esto nos ayuda a mantener el mismo objeto para la selección. */
   mapeoNodoAnidado = new Map<ITodoNodoItem, ITodoNodoItemPlano>();

   controlArbolPlano: FlatTreeControl<ITodoNodoItemPlano>;

   aplanadorArbol: MatTreeFlattener<ITodoNodoItem, ITodoNodoItemPlano>;

   fuenteDatos: MatTreeFlatDataSource<ITodoNodoItem, ITodoNodoItemPlano>;

   /** La selección para la lista de verificación */
   seleccionChecklist = new SelectionModel<ITodoNodoItemPlano>(true /* multiple */);

  /** subscripcion al filtro de busqueda */
  filtroBusqueda: Subject<string> = new Subject<string>();

  /** Control de existencia de elementos visualizados a la vista de usuario */
  elementosVisualizados: boolean = true;

  /** término de búsqueda actual*/
  termino: string = '';

  // acciones nodo
  nodoEdicion: ITodoNodoItemPlano;
  nodoSeleccionado: ITodoNodoItemPlano;

  @ViewChild('dialogConfirmarEliminar') dialogConfirmarEliminar: TemplateRef<any>;


  @Output('submitArbol') submitArbol = new EventEmitter<any[]>();

  formInputEdicion: IFormInput[] = [
    {
      tag: FormTagEnum.input,
      formControlName: 'item',
      type: 'text',
      obligatorio: true,
      validadores: [],
      clasePersonalizadaLayoutCamposPorcentaje: [ClasesCampoLayoutCamposPorcentajeFormularioEnum.campoPorcent6x]
    }
  ];

  subMenuDespegableItem: Subscription;
  subMenuDespegableAccionesMultiples: Subscription;

  @ViewChild('dialogConfirmarEliminarNodo') dialogConfirmarEliminarNodo: TemplateRef<any>;
  @ViewChild('dialogEditarNodo') dialogEditarNodo: TemplateRef<any>;
  @ViewChild('formularioEdicion') formularioEdicion: FormularioComponent;

  constructor(
    public arbolServicio: ArbolService,
    public dialog: MatDialog,
    private botonDesplegableService: BotonDesplegableService,
    private element: ElementRef,
    private formularioService: FormularioService,
  ) {}

  ngOnInit(): void {
    this.botonDesplegableService.acciones = [
      {id: '3', iconoClase: 'icon-20 icon-mdi--select-all', texto: 'Seleccionar todo'},
      {id: '4', iconoClase: 'icon-20 icon-ibm--collapse-all', texto: 'Expandir todo'},
      {id: '5', iconoClase: 'icon-20 icon-ibm--layers', texto: 'Colapsar todo'},
    ];
    this.subscripcionMenuDesplegableAccionesMultiples();

    this.aplanadorArbol = new MatTreeFlattener(this.transformar, this.obtenerNivel,
    this.esExpandible, this.obtenerHijos);
    this.controlArbolPlano = new FlatTreeControl<ITodoNodoItemPlano>(this.obtenerNivel, this.esExpandible);
    this.fuenteDatos = new MatTreeFlatDataSource(this.controlArbolPlano, this.aplanadorArbol);
    this.arbolServicio.getDatos().subscribe(data => {
      this.fuenteDatos.data = data;
      this.iniciarVistaArbol();
      this.subscripcionMenuDesplegableItem();

   });
  }

  iniciarVistaArbol() {
    this.controlArbolPlano.expandAll();
    this.filtroBusqueda.pipe(debounceTime(500), distinctUntilChanged())
    .subscribe(termino => {
      if (termino && termino.length >= 3) {
        this.controlArbolPlano.collapseAll();
        this.termino = termino;
        this.filtrarPorNombre(termino);
      } else {
        this.controlArbolPlano.expandAll();
        this.filtroLimpiar();
      }

      // preseleccionados

    });
  }

   obtenerNivel = (nodo: ITodoNodoItemPlano) => nodo.level;

   esExpandible = (nodo: ITodoNodoItemPlano) => nodo.expandable;

   obtenerHijos = (nodo: ITodoNodoItem): ITodoNodoItem[] => nodo.children;

   tieneHijo = (_: number, _dataNodo: ITodoNodoItemPlano) => _dataNodo.expandable;

   noTieneContenido = (_: number, _dataNodo: ITodoNodoItemPlano) => _dataNodo.item === '';

   /**
    * Transformador para convertir un nodo anidado en un nodo plano. Registre los nodos en mapas para su uso posterior de recuperación data.
    */
   transformar = (nodo: ITodoNodoItem, nivel: number) => {
     const existeNodoPlano = this.mapeoNodoAnidado.get(nodo);
     const nodoPlano = existeNodoPlano && existeNodoPlano.item === nodo.item
         ? existeNodoPlano
         : new ITodoNodoItemPlano();
     nodoPlano.item = nodo.item;
     nodoPlano.level = nivel;
     nodoPlano.visible = true;
     nodoPlano.coincidenciaBusqueda = false;
     nodoPlano.expandable = !!nodo.children?.length;
     nodoPlano.id = nodo.id;
     this.mapeoNodoPlano.set(nodoPlano, nodo);
     this.mapeoNodoAnidado.set(nodo, nodoPlano);
     return nodoPlano;
   }

   /** Si se seleccionan todos los descendientes del nodo.. */
   descendientesTodosSeleccionados(nodo: ITodoNodoItemPlano): boolean {
     const descendientes = this.controlArbolPlano.getDescendants(nodo);
     const descendientesTodoSeleccionados = descendientes.length > 0 && descendientes.every(child => {
       return this.seleccionChecklist.isSelected(child);
     });
     return descendientesTodoSeleccionados;
   }

   /** Si se selecciona parte de los descendientes */
   descendientesParcialmenteSeleccionados(nodo: ITodoNodoItemPlano): boolean {
     const descendientes = this.controlArbolPlano.getDescendants(nodo);
     const resultado = descendientes.some(child => this.seleccionChecklist.isSelected(child));
     return resultado && !this.descendientesTodosSeleccionados(nodo);
   }

   /** Alternar la selección de elementos de tareas pendientes. Seleccionar / deseleccionar todos los nodos descendientes*/
   alternarSeleccionDesdencientes(nodo: ITodoNodoItemPlano): void {
     this.seleccionChecklist.toggle(nodo);
     const descendientes = this.controlArbolPlano.getDescendants(nodo);
     this.seleccionChecklist.isSelected(nodo)
       ? this.seleccionChecklist.select(...descendientes)
       : this.seleccionChecklist.deselect(...descendientes);

     // Force update for the parent
     descendientes.forEach(child => this.seleccionChecklist.isSelected(child));
     this.checkAllParentsSelection(nodo);

     //  expandir si seleccion y esta colpasado
     if (this.seleccionChecklist.isSelected(nodo) ) {
      this.controlArbolPlano.expandDescendants(nodo);
     } else {
      this.controlArbolPlano.collapseDescendants(nodo);
     }
   }

   /** Alternar una selección de elementos de tareas pendientes de hoja. Verifique a todos los padres para ver si cambiaron */
   todoLeafItemSelectionToggle(nodo: ITodoNodoItemPlano): void {
     this.seleccionChecklist.toggle(nodo);
     this.checkAllParentsSelection(nodo);
     this.controlArbolPlano.expand(nodo);
   }

   /* Comprueba todos los partodoLeafItemSelectionToggleents cuando un nodo hoja está seleccionado / deseleccionado */
   checkAllParentsSelection(nodo: ITodoNodoItemPlano): void {
     let padre: ITodoNodoItemPlano | null = this.obtenerNodoPadre(nodo);
     while (padre) {
       this.checkRootNodeSelection(padre);
       padre = this.obtenerNodoPadre(padre);
     }
   }

   /** Verifique el estado verificado del nodo raíz y cámbielo en consecuencia */
   checkRootNodeSelection(node: ITodoNodoItemPlano): void {
     const nodeSelected = this.seleccionChecklist.isSelected(node);
     const descendants = this.controlArbolPlano.getDescendants(node);
     const descAllSelected = descendants.length > 0 && descendants.every(child => {
       return this.seleccionChecklist.isSelected(child);
     });
     if (nodeSelected && !descAllSelected) {
       this.seleccionChecklist.deselect(node);
     } else if (!nodeSelected && descAllSelected) {
       this.seleccionChecklist.select(node);
     }
   }

   /* Obtener el nodo padre de un nodo */
   obtenerNodoPadre(node: ITodoNodoItemPlano): ITodoNodoItemPlano | null {
     const currentLevel = this.obtenerNivel(node);

     if (currentLevel < 1) {
       return null;
     }

     const startIndex = this.controlArbolPlano.dataNodes.indexOf(node) - 1;

     for (let i = startIndex; i >= 0; i--) {
       const currentNode = this.controlArbolPlano.dataNodes[i];

       if (this.obtenerNivel(currentNode) < currentLevel) {
         return currentNode;
       }
     }
     return null;
   }

  //  Funciones desarrolldas para cubrir nuevas funcionalidades del componente mat-tree
  /*******************************************************
  * Filtrado
  *******************************************************/
  filtroCambiado(filtro: string): void {
    this.filtroBusqueda.next(filtro);
  }

  filtrarPorNombre(termino: string ): void {
    const itemsFiltrados = this.controlArbolPlano.dataNodes.filter(
      x => x.item.toLowerCase().indexOf(termino.toLowerCase()) === -1
    );
    itemsFiltrados.map(x => {
      x.visible = false;
      x.coincidenciaBusqueda = false;
    });

    const itemVisibles = this.controlArbolPlano.dataNodes.filter(
      x => x.item.toLowerCase().indexOf(termino.toLowerCase()) > -1
    );
    itemVisibles.map( x => {
      x.visible = true;
      x.coincidenciaBusqueda = true;
      this.hacerVisibleAscendientes(x);
      this.hacerVisibleDescendientes(x);
    });
    this.elementosVisualizados = (itemVisibles.length) ? true  : false;
    // opción 1: expandir todo
    // this.controlArbolPlano.expandAll();
    // opción 2: expandir sólo rama filtrada
    this.expandirRamaFiltrada();

    // if (this.elementosVisualizados){
    //   this.focusPrimeraCoincidencia();
    // }
  }

  filtroLimpiar() {
    this.termino = '';
    this.elementosVisualizados = true;
    this.controlArbolPlano.dataNodes.forEach(x =>  {
      x.coincidenciaBusqueda = false;
      x.visible = true
    });
  }

  esBusqueda() {
    return !this.termino || (this.termino && this.termino.length==0);
  }

  focusPrimeraCoincidencia() {
    const span = this.element.nativeElement.querySelector('[class="nodo-resaltado"]');
    if (span) {
      span.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
    }
  }

  /** Hacer visibles todos los nodos ascendientes de un nodo incluídos sus hermanos*/
  hacerVisibleAscendientes(nodo: ITodoNodoItemPlano) {
    let padre: ITodoNodoItemPlano | null = this.obtenerNodoPadre(nodo);
    let padreNodo = this.mapeoNodoPlano.get(padre);
    while (padre) {
      padre.visible = true;

      // visibles hijos sin profundizar en siguiente nivel descendientes
      padreNodo.children.forEach( nodoItemHijo => {
        const nodoHijo = this.mapeoNodoAnidado.get(nodoItemHijo);
        nodoHijo.visible = true;
      });

      padre = this.obtenerNodoPadre(padre);
      padreNodo = this.mapeoNodoPlano.get(padre);
    }
  }

  /** Hacer visibles todos los nodos descendientes de un nodo incluídos sus hermanos*/
  hacerVisibleDescendientes(nodo: ITodoNodoItemPlano) {
    let nodoItem = this.mapeoNodoPlano.get(nodo);
    if (nodoItem && nodoItem.children && nodoItem.children.length) {
      let hijosItem = nodoItem.children;

      hijosItem.forEach( nodoItemHijo => {
        const nodoHijo = this.mapeoNodoAnidado.get(nodoItemHijo);
        nodoHijo.visible = true;
        this.hacerVisibleDescendientes( nodoHijo );
      });
    }
  }

  obetenerSeleccion() {
    return this.seleccionChecklist.selected.map( nodo => nodo.id );
  }

  /*******************************************************
   * Expansión
   ********************************************************/
  convertirColapsarExpandir() {
    if ( this.estanTodosExpandidos()) {
      this.controlArbolPlano.collapseAll()
    } else {
      this.controlArbolPlano.expandAll();
    }
  }

  estanTodosExpandidos(): boolean {
    let nodosExpandidos = this.controlArbolPlano.dataNodes.filter( nodo => this.controlArbolPlano.isExpanded(nodo));
    return this.controlArbolPlano.dataNodes.length == nodosExpandidos.length;
  }

  // Expande solo los nodos ascendentes hasta su padre, no expande hermanos ni propio nodo.
  expandirRamaFiltrada() {
    this.controlArbolPlano.dataNodes.map( (item: ITodoNodoItemPlano) => {
      if (item.coincidenciaBusqueda) {
        this.expandirPadre(item);
      }
    })
  }

  expandirPadre( nodo: ITodoNodoItemPlano ) {
    let nodoPadre = this.obtenerNodoPadre(nodo);
    if (nodoPadre) {
      this.controlArbolPlano.expand(nodoPadre);
      if ( nodoPadre?.level >  0 ) {
        this.expandirPadre(nodoPadre)
      }
    }
  }

  /*******************************************************
   * Selección
   ********************************************************/
  convertirSeleccion():void {
    if ( this.estanTodosSeleccionados() ) {
      this.deseleccionarTodos();
    } else {
      this.seleccionarTodos();
    }
  }

  estanTodosSeleccionados(): boolean {
    return this.controlArbolPlano.dataNodes.length == this.seleccionChecklist.selected.length;
  }

  invertirSeleccionTodos():void {
    this.controlArbolPlano.dataNodes.map( nodo => this.todoLeafItemSelectionToggle(nodo));
  }

  seleccionarTodos():void {
    this.controlArbolPlano.dataNodes.map( (nodo:ITodoNodoItemPlano) =>  this.seleccionChecklist.select(nodo));
    this.controlArbolPlano.expandAll();
  }

  deseleccionarTodos():void {
    this.controlArbolPlano.dataNodes.map( (nodo:ITodoNodoItemPlano) =>  this.seleccionChecklist.deselect(nodo));
  }

  submit() {
    let seleccion = this.obetenerSeleccion().filter( id => id);
    this.submitArbol.emit( seleccion );
  }

  subscripcionMenuDesplegableItem() {
    if (this.subMenuDespegableItem) {
      this.subMenuDespegableItem.unsubscribe();
    }
    this.subMenuDespegableItem = this.botonDesplegableService.accionItemSeleccionados$.subscribe(accionItemSeleccionados => {
      this.nodoSeleccionado = accionItemSeleccionados.item;
      switch(accionItemSeleccionados.idAccion) {
        case '1':
          this.formularioService.inputs = this.formInputEdicion;
          this.dialog.open(this.dialogEditarNodo, {disableClose: true, panelClass: ['dialogoEditarNodo', 'dialogoFormulario']});
        break;
        case '2':
          this.dialog.open(this.dialogConfirmarEliminarNodo, {panelClass: 'dialogoConfirmarEliminarNodo'});
        break;
      }
    });
  }

  subscripcionMenuDesplegableAccionesMultiples() {
    if (this.subMenuDespegableAccionesMultiples) {
      this.subMenuDespegableAccionesMultiples.unsubscribe();
    }
    this.subMenuDespegableAccionesMultiples = this.botonDesplegableService.accionSeleccionada$.subscribe(accionSeleccionada => {
      switch(accionSeleccionada) {
        case '3':
          this.convertirSeleccion()
        break;
        case '4':
          this.controlArbolPlano.expandAll();
        break;
        case '5':
          this.controlArbolPlano.collapseAll()
        break;
      }
    });
  }



  submitAccionDialogConfirmarEliminar(button: IBotonAccionesDialog) {
    if (button.id = 'btn-confirmar') {
     this.arbolServicio.eliminarNodo(this.nodoSeleccionado);
      this.cerrarDialogo();
    }
  }

  submitAccionDialogEditar(button: IBotonAccionesDialog) {
    if (button.id = 'btn-guardar') {
      let objeto = this.formularioEdicion.validaCampos( false );
      if (objeto && objeto.formulario && objeto.formulario.valid) {
        let nodoAnidado = this.mapeoNodoPlano.get(this.nodoSeleccionado);
        this.arbolServicio.actualizarNodo(nodoAnidado,objeto.formulario.value.item);
        this.cerrarDialogo();
      }
    }
  }
  // prototipos
  cerrarDialogo() {
    this.dialog.closeAll();
  }

  desactivarEfectoCheckbox(checkbox){
    // meter clase accion
    checkbox._elementRef.add
    document.getElementById(checkbox.id).classList.add('accionButon');
  }

  activarEfectoCheckbox(checkbox){
    // meter calse accionButton
    document.getElementById(checkbox.id).classList.remove('accionButon');
  }

}


