import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class CaptchaService {
  private _captcha$: BehaviorSubject<string>;

  constructor() {
    this._captcha$ = new BehaviorSubject(null);
  }

  get captcha$(){
    return this._captcha$.asObservable();
  }

  generate(): void {
    const mayusc = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const minusc = 'abcdefghijklmnopqrstuvwxyz';
    const numeric = '0123456789';
    const length = 6;

    const captcha = [];
    captcha.push(mayusc.charAt(Math.floor(Math.random() * length)));
    captcha.push(mayusc.charAt(Math.floor(Math.random() * length)));
    captcha.push(minusc.charAt(Math.floor(Math.random() * length)));
    captcha.push(minusc.charAt(Math.floor(Math.random() * length)));
    captcha.push(numeric.charAt(Math.floor(Math.random() * length)));
    captcha.push(numeric.charAt(Math.floor(Math.random() * length)));
    this._captcha$.next(this.messArray(captcha).join(''));
  }

  messArray(arr: any[]): any[] {
    return arr.sort((a, b) => Math.random() - 0.5);
  }
}
