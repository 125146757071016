import {Component, Input} from '@angular/core';
import {EventEmitter, OnInit, Output} from '@angular/core';
import { ClasesCampoLayoutCamposAnchoFijoFormularioEnum, IFormInput } from '@appNeo/neoShared/helpers/interfaces/IForm-input';
import {FormTagEnum} from '@appNeo/neoShared/helpers/enums/FormTag.enum';
import {FormularioService} from '@appNeo/neoShared/services/formulario/formulario.service';

export enum AccionesTotalesEnum {
  todos = 'Todos',
  pagina = 'Página',
  seleccion = 'Selección',
}

export interface ISeccionTotales {
  id: string;
  titulo: string;
  resultado: number;
}

@Component({
  selector: 'neo-totales',
  templateUrl: './totales.component.html',
  styleUrls: ['./totales.component.scss'],
  providers: [FormularioService]
})
export class TotalesComponent implements OnInit {

  @Input() mostrarSelectOpciones = true;
  @Input() data: [ISeccionTotales];
  @Output() cambioOpcionSeleccionada = new EventEmitter<object>();

  input: IFormInput = {
    tag: FormTagEnum.select,
    label: 'Select',
    type: '',
    placeholder: 'Select',
    formControlName: 'selectorTotales',
    opcionGeneral: false,
    multiple: false,
    datos: [{id: 1, nombre: AccionesTotalesEnum.todos}, {id: 2, nombre: AccionesTotalesEnum.pagina}, {id: 3, nombre: AccionesTotalesEnum.seleccion}],
    obligatorio: true,
    validadores: [],
    clasePersonalizadaLayoutCamposAnchoFijo: [ClasesCampoLayoutCamposAnchoFijoFormularioEnum.campoPorcent100]
  };
  valoresIniciales = {
    selectorTotales: '1'
  };

  constructor(
    private formularioService: FormularioService
  ) { }

  ngOnInit(): void {
    this.cambioOpcionSeleccionada.emit(this.valoresIniciales);
  }

  get _AccionesTotalesEnum() {
    return AccionesTotalesEnum;
  }

  cambioSelectOpciones(opcionSeleccionada: any) {
    this.cambioOpcionSeleccionada.emit(opcionSeleccionada);
  }

}
