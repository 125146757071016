import {
    Component, Input,
  } from '@angular/core';
import { environment } from '@environments/environment';


  
  @Component({
    selector: 'neo-visor-color-picker',
    templateUrl: './visor-color-picker.component.html',
    styleUrls: ['./visor-color-picker.component.scss'],
  })
  export class VisorColorPickerComponent  {
   
    @Input() color = '';
    colorDefecto = environment.color_defecto_colorPicker;
    constructor() {}

    
  }