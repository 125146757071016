import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

export interface IAccionesBotonDesplegable {
  id: string;
  icono?: string;
  iconoClase?: string;
  texto: string;
  disabled?: boolean;
  clase?: string;
}


@Injectable({
  providedIn: 'root'
})
export class BotonDesplegableService {
  public acciones$: BehaviorSubject<IAccionesBotonDesplegable []> = new BehaviorSubject(null);
  // Devuelve accion seleccionada
  public accionSeleccionada$: BehaviorSubject<string> = new BehaviorSubject('');
  // Devuelve accion e item sobre la que se debe ejecutar
  public accionItemSeleccionados$: BehaviorSubject<any> = new BehaviorSubject({idAccion: '', item: {}});


  constructor() { }

  set acciones(acciones: IAccionesBotonDesplegable[]){
    this.acciones$.next(acciones);
  }

  set accionSeleccionada(idAccionSeleccionada) {
    this.accionSeleccionada$.next(idAccionSeleccionada);
  }

  set accionItemSeleccionados(accionItem) {
    this.accionItemSeleccionados$.next(accionItem);
  }
}
