import { Component, Input, OnInit } from '@angular/core';
import { IValorMultiple } from '@appNeo/neoShared/helpers/interfaces/IValorMultiple';



@Component({
  selector: 'neo-perfil-valores-multiples',
  templateUrl: './perfil-valores-multiples.component.html',
  styleUrls: ['./perfil-valores-multiples.component.scss']
})
export class PerfilValoresMultiplesComponent implements OnInit {

    @Input() valores: IValorMultiple[];
    constructor() { }
  
    ngOnInit(): void {
        
    }

    esArray (): boolean {
      if (Array.isArray(this.valores)) {
        return true;
      }else{
        console.log('PROBLERMAS no es array valor multiple ', this.valores);
        return false;
      }
    }
  }
  