import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {
  CirculoProgresoIconoCompletadoComponent
} from '@appNeo/neoShared/components/circulo-progreso-icono-completado/circulo-progreso-icono-completado.component';
import {Subscription, timer} from '@node_modules/rxjs';
import {Input, QueryList, ViewChildren} from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'neo-campo-fichero [controlCampo]',
  templateUrl: './campo-fichero.component.html',
  styleUrls: ['./campo-fichero.component.scss']
})
export class CampoFicheroComponent implements OnInit, AfterViewInit {
  @Input() controlCampo = new FormControl();
  @Input() nombreCampo: string;
  @Input() placeholder: string;
  @Input() label: string;
  @Input() textoAyuda: string;
  @Input() formatosPermitidos: string = environment.fichero_formatos_permitidos.toString();

  @ViewChildren('circuloProgresoInputFile') circulosProgreso: QueryList<CirculoProgresoIconoCompletadoComponent>;


  storeFicheros = [];
  timerSubidaFicheros: Subscription;

  constructor() { }

  ngOnInit(): void {
    console.log('CONTROL CAMPO: ', this.controlCampo);
  }

  ngAfterViewInit() {
    this.ocultarCirculosProgresoInputsFile();
  }

  ocultarCirculosProgresoInputsFile() {
    this.circulosProgreso.forEach(circulo => {
      circulo.visible = false;
    });
  }

  handlerSubidaFicheros(files: FileList, formControlName: string) {
    this.storeFicheros[formControlName] = files;
    if (files.length) {
      const f = files[0];
      const count = files.length > 1 ? `(+${files.length - 1} files)` : '';
      this.controlCampo.patchValue(`${f.name}${count}`);
      const componenteCirculoProgreso = this.findComponenteCirculoProgresoFile(formControlName);
      componenteCirculoProgreso.visible = true;
      componenteCirculoProgreso.setProgreso(0);
      this.iniciarCirculoProcesoCargaFichero(formControlName);
    } else {
      this.controlCampo.patchValue('');
    }
  }

  private findComponenteCirculoProgresoFile(hash: string): CirculoProgresoIconoCompletadoComponent  {
    const componenteCirculoProgreso = this.circulosProgreso?.find((componenteCirculoProgreso: CirculoProgresoIconoCompletadoComponent, index: number, array) =>
    {
      return componenteCirculoProgreso.identificador === hash;
    });
    return componenteCirculoProgreso;
  }

  iniciarCirculoProcesoCargaFichero(hash: string) {
    const componenteCirculoProgreso = this.findComponenteCirculoProgresoFile(hash);
    if (componenteCirculoProgreso) this.timerSubidaFicheros = timer(100, 10).subscribe((x) => this.establecerValorProgreso(componenteCirculoProgreso, x));
  }

  private establecerValorProgreso (componente: CirculoProgresoIconoCompletadoComponent, valor ) {
    if (valor < 101 ) {
      componente.setProgreso(valor);
    }else {
      this.timerSubidaFicheros.unsubscribe();
      componente.visible = false;
    }
  }

  eliminarFicheros(formControl, formControlName: string) {
    formControl.patchValue('');
    const componenteCirculoProgreso = this.findComponenteCirculoProgresoFile(formControlName);
    componenteCirculoProgreso.visible = false;
    componenteCirculoProgreso.completado = false;
    this.storeFicheros[formControlName] = null;
    this.storeFicheros = this.storeFicheros.filter(item => {
      return item != null;
    });
    return formControl;
  }


}
