<div class="mat-calendar-header">
    <div class="mat-calendar-controls">
      <button mat-button type="button" class="mat-calendar-period-button"
              (click)="currentPeriodClicked()" [attr.aria-label]="periodButtonLabel"
              cdkAriaLive="polite">
        {{periodButtonText}}
        <div class="mat-calendar-arrow"
             [class.mat-calendar-invert]="calendar.currentView != 'month'"></div>
      </button>
  
      <div class="mat-calendar-spacer"></div>
  
      <ng-content></ng-content>
  
      <!-- <button mat-icon-button type="button" class="mat-calendar-previous-button"
              [disabled]="!previousEnabled()" (click)="customPrev()"
              [attr.aria-label]="prevButtonLabel">
      </button> -->
      <neo-button
           [icono]="true"
            [clase]="'btn-outline btn-margin-left'"
            [iconClass]="'icon-20 icon-ibm--chevron--left'"
            [disabled]="!previousEnabled()" 
            (click)="customPrev()"
            [attr.aria-label]="prevButtonLabel"
      ></neo-button>
      <neo-button
           [icono]="true"
            [clase]="'btn-outline btn-margin-left'"
            [iconClass]="'icon-20 icon-ibm--chevron--right'"
            [disabled]="!nextEnabled()" 
            (click)="customNext()"
            [attr.aria-label]="nextButtonLabel"
      ></neo-button>
      <!-- <button mat-icon-button type="button" class="mat-calendar-next-button"
              [disabled]="!nextEnabled()" (click)="customNext()"
              [attr.aria-label]="nextButtonLabel">
      </button> -->
    </div>
  </div>