import {AfterViewInit, ChangeDetectorRef, Component, Input, SimpleChanges, ViewEncapsulation} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { TipoDatePickerEnum } from '@appNeo/neoShared/helpers/enums/TipoDatePicker.enum';
import { AuxiliarService } from '@appNeo/neoShared/services/auxiliar/auxiliar.service';
import { CampoMatdatapickerCustomizadoComponent } from '../campo-matdatapicker-customizado/campo-matdatapicker-customizado.component';

export interface IConfiguracionDia {
  dia: number,
  configuracion: string,
  clase: string,
}
@Component({
  selector: 'neo-campo-datatimepicker',
  templateUrl: './campo-datatimepicker.component.html',
  styleUrls: ['./campo-datatimepicker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
// Todo: Revisar y ajustar nomenclaturas @input min,max...
export class CampoDatatimepickerComponent implements AfterViewInit {

  @Input() label: string;
  @Input() placeholder: string;
  @Input() textoAyuda = '';
  @Input() controlCampo: FormControl = new FormControl(false);
  @Input() tipo:string;
  @Input() min: Date;
  @Input() max: Date;
  @Input() horaMin: number;
  @Input() minutoMin;
  @Input() horaMax: number;
  @Input() minutoMax;
  @Input() configuracionDia: IConfiguracionDia[];
  fechaMinFormat;
  fechaMaxFormat;
  campoMatdatapickerCustomizadoComponent = CampoMatdatapickerCustomizadoComponent;

  constructor(
    public auxiliarService: AuxiliarService
  ) { }



  ngAfterViewInit(): void {
    setTimeout(()=>{
      if (this.tipo === 'hora' || this.esTipoFechaHoraConRango) {
        this.fechaMinFormat = new Date();
        this.fechaMaxFormat = new Date();
  
        if (this.min) this.fechaMinFormat = this.min;
  
        if (this.max) this.fechaMaxFormat = this.max;
  
        this.fechaMinFormat.setHours(this.horaMin);
  
        this.minutoMin ? this.fechaMinFormat.setMinutes(this.minutoMin) : this.fechaMinFormat.setMinutes(0);
  
        this.fechaMaxFormat.setHours(this.horaMax);
  
        this.minutoMax ? this.fechaMaxFormat.setMinutes(this.minutoMax) : this.fechaMaxFormat.setMinutes(0);
  
        if (this.tipo === 'hora') this.validarLimiteSuperiorHora();
      }



    })

  }

  //Función fix bug si no se selecciona una hora con los handlers añade como valor al input la hora actual independientemente validador de rango Max y Min
  validarLimiteSuperiorHora() {
    this.controlCampo.valueChanges.subscribe(valueForm => {
        if (valueForm && new Date(valueForm)) {
          let date =  new Date(valueForm);
          if (date.getHours() > this.horaMax) {
            this.controlCampo.setValue(this.fechaMaxFormat);
          } else if (date.getHours() === this.horaMax && date.getMinutes() > this.minutoMax) {
            this.controlCampo.setValue(this.fechaMaxFormat);
          }
          if (date.getHours() < this.horaMin) {
            this.controlCampo.setValue(this.fechaMinFormat);
          } else if (date.getHours() === this.horaMin && date.getMinutes() < this.minutoMin) {
            this.controlCampo.setValue(this.fechaMinFormat);
          }
        }
      });
  }

  get esTipoFechaHoraConRango() {
    return this.tipo === 'fechaHora' && this.min && this.max;
  }

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {

    // Only highligh dates inside the month view.
    if (view === 'month' && this.configuracionDia) {
      const date = new Date(cellDate).getTime();      
      let confDia: any = this.configuracionDia.find(conf => conf.dia === date);
      if (confDia) {
        // console.log('Configuracion dia ', confDia, cellDate);

        setTimeout(() => {
          // aplicamos estilo en linea
          let numeroDia = new Date(cellDate).getDate() - 1;
          // aplicar color
          let contenidoDia = document.querySelectorAll('.mat-calendar-body-cell-content')[numeroDia] as HTMLElement;
          contenidoDia.style.background = this.auxiliarService.obtenerRgba(confDia?.color, 0.4);
        })
        return '';
        // mete clase predefinida en css
        // return 'dia-' + confDia.clase + ' diaConColor ' + ' color_' + this.auxiliarService.obtenerRgba(confDia?.color, 0.4) + '_';
      } else {
        return '';
      }

      // parche
      // let celdas = document.querySelectorAll('[role="columnheader"]') as NodeList;
    }
    return '';
  };


}
