import {Component, EventEmitter, Input, Output} from '@angular/core';
import { AuxiliarService } from '@appNeo/neoShared/services/auxiliar/auxiliar.service';

@Component({
  selector: 'neo-etiqueta-input[texto]',
  templateUrl: './etiqueta-input.component.html',
  styleUrls: ['./etiqueta-input.component.scss']
})
export class EtiquetaInputComponent  {
  @Input('bgColor') bgColor = '';
  @Input('clase') clase: string;
  @Input('icono') icono: string;
  @Input('iconoClase') iconoClase: string;
  @Input('texto') texto: string;
  @Input('contenidoTemplate') contenidoTemplate;  
  @Input('desactivado') desactivado = false;
  @Input('eliminable') eliminable = true;
  @Output() eliminar = new EventEmitter();
  @Output() seleccionar = new EventEmitter();
  
  constructor() {}

}
