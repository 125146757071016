import { Component, Input } from '@angular/core';
import { ProgressService } from '@appNeo/neoShared/services/progress/progress.service';

@Component({
  selector: 'neo-circulo-progreso [identificador]',
  templateUrl: './circulo-progreso.component.html',
  styleUrls: ['./circulo-progreso.component.scss'],
  providers: [ProgressService]
})
export class CirculoProgresoComponent  {
  @Input('identificador') identificador: string;

  constructor(
    public progressService: ProgressService
  ) { }

  setProgreso( progreso) {
    this.progressService.setProgreso(progreso);

  }
}
