export class Paginador {
  private inicio: number;
  private totalFilas: number;
  private pagina: number;

  get _inicio(): number {
    return this.inicio;
  }

  get _pagina(): number {
    return this._pagina;
  }

  set _pagina(value: number) {
    this._pagina = value;
  }

  set _inicio(value: number) {
    this.inicio = value;
  }

  get _totalFilas(): number {
    return this.totalFilas;
  }

  set _totalFilas(value: number) {
    this.totalFilas = value;
  }
}
