import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BotonDesplegableService, IAccionesBotonDesplegable} from '@appNeo/neoShared/services/boton-desplegable/boton-desplegable.service';
import {MatDialog} from '@node_modules/@angular/material/dialog';
import { IBotonAccionesDialog, TipoAccionBotonEnum } from '@appNeo/neoShared/components/acciones-dialog/acciones-dialog.component';
import { ModoBotonEnum } from '@appNeo/neoShared/components/button/button.component';

export enum IAlineacionEnum{
  izquierda= 'left',
  derecha= 'right',
  centrada= 'center',
}


@Component({
  selector: 'neo-boton-desplegable',
  templateUrl: './boton-desplegable.component.html',
  styleUrls: ['./boton-desplegable.component.scss']
})
export class BotonDesplegableComponent implements OnInit {

  @Input() referenciaElemento: string = 'desplegable';
  @Input() disabled = true;
  @Input() modoBotonPrincipal = ModoBotonEnum.basic;
  // solo para modo dialogo
  @Input() proyeccionBotonPersonalizado = false;
  @Input() textoBotonPrincipal = 'Acciones en lote';
  @Input() miniFabBotonPrincipal = false;
  @Input() basicBotonPrincipal = true;
  @Input() iconoTipoBotonPrincipal = false;
  @Input() strokedBotonPrincipal = false;
  @Input() colorBotonPrincipal = 'primary';
  @Input() iconoBotonPrincipal = 'arrow_drop_down';
  @Input() claseBotonPrincipal = '';
  @Input() iconoClaseBotonPrincipal = 'icon-ibm--overflow-menu--vertical icon-20';
  // @Input() claseBotonPrincipal = '';

  @Input() item: any;
  // posibilidad de meterlas por servicio o por INPUT
  @Input() accionesBotonDesplegable: IAccionesBotonDesplegable[];
  @Input() alineacionAccionesBotonDespegable: IAlineacionEnum = IAlineacionEnum.izquierda;

  //Configuración mostrar a través de dialog
  @Input() mostrarEnDialog = false;
  @Input() tituloDialog = 'Acciones múltiples';
  @Input() botonCancelarDialog = false;
  botoneraDialogoDefecto: IBotonAccionesDialog[] = [
    {
      label: 'Cancelar',
      id: 'btn-cancelar',
      tipoAccion: TipoAccionBotonEnum.cerrar,
      type: 'button',
      color: 'primary',
      disabled: false,
      activo: true,
      flat: true
    }
  ];
  botoneraDialogo: IBotonAccionesDialog[] = [];

  acciones: IAccionesBotonDesplegable[];

  @ViewChild('dialogBtnDesplegable') dialogBtnDesplegable: TemplateRef<any>;
  @ViewChild('dialogBtnDesplegable') templateAcciones: TemplateRef<any>;

  refDialog;

  constructor(
    public botonDesplegableService: BotonDesplegableService,
    public  dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.botoneraDialogo = (this.botonCancelarDialog)  ? this.botoneraDialogoDefecto : [];
    if ( !this.accionesBotonDesplegable?.length ) {
      this.subscriptionAcciones();
    } else {
      this.acciones = this.accionesBotonDesplegable;
    }
    if (this.strokedBotonPrincipal || this.miniFabBotonPrincipal || this.iconoTipoBotonPrincipal) {
      this.basicBotonPrincipal = false;
    }
  }

  subscriptionAcciones() {
    this.botonDesplegableService.acciones$.subscribe(accionesBoton => {
      this.acciones = accionesBoton;
    });
  }

  desencadenarAccion(idAccion: string) {
    if (this.item) {
      this.botonDesplegableService.accionItemSeleccionados = {idAccion, item: this.item};
    } else {
      this.botonDesplegableService.accionSeleccionada = idAccion;
    }
    if ( this.mostrarEnDialog ) {
      this.cerrarDialogo();
    }
  }

  abrirDialog(): void {
    this.refDialog = this.dialog.open(this.dialogBtnDesplegable, {panelClass: 'dialogoBoton', disableClose: true});
    }

  cerrarDialogo(): void {
    if (this.refDialog)
      this.refDialog.close();
  }

}
