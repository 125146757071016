import { Component, Input } from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'neo-textarea-contador [controlCampo]',
  templateUrl: './textarea-contador.component.html',
  styleUrls: ['./textarea-contador.component.scss']
})
export class TextareaContadorComponent  {

  // Únicamente visual, es necesario igualmente agregar el validador al FormControl
  @Input() caracteresMaximo = 0;

  @Input() controlCampo: FormControl;

  @Input() label = '';
  @Input() textoAyuda = '';
  @Input() placeholder = '';

  contadorCaracteres = 0;

  constructor() { }

  actualizarContadorCaracteres(event){
    this.contadorCaracteres = event.target.value.length;
  }

}

