import { Component, OnInit, Input, EventEmitter, Output} from '@angular/core';

// Esta Variante aplica cuando necesitamos controlar el valor mediante ngModel (Caso Tabla)
@Component({
  selector: 'neo-input-cantidad-model [cantidad]',
  templateUrl: './input-cantidad-model.component.html',
  styleUrls: ['./input-cantidad-model.component.scss', '../input-cantidad.component.scss']
})
export class InputCantidadModelComponent implements OnInit {
  @Input() cantidad: string;
  @Input() label = '';
  @Input() textoAyuda = '';
  @Input() placeholder = '0';
  @Input() min: any;
  @Input() max: any;
  @Input() disabled = false;

  @Output() nuevaCantidad = new EventEmitter<string>();


  constructor() { }

  ngOnInit(): void {
  }

}
