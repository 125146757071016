<div class="form-field field-cantidad">
  <mat-form-field appearance="outline">
    <mat-label *ngIf="label">{{label}}</mat-label>
    <input matInput class="text-right" type="number" name="{{ controlCampo }}"
           min="{{ min }}"
           max="{{ max }}"
           [formControl]="controlCampo" placeholder="{{placeholder}}">
    <mat-hint *ngIf="textoAyuda">{{textoAyuda}}</mat-hint>
    <mat-error class="invalid-feedback" *ngIf="controlCampo?.errors && mostrarMatError">
      <neo-mat-error-form
        [nombreCampo]="label"
        [controlCampo]=controlCampo>
      </neo-mat-error-form>
    </mat-error>
  </mat-form-field>
</div>

