import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

export interface IKanvanItemAvatar {
  nombre?: string,
  src?:string,
}

export interface IKanvanItemExtra {
  iconoClase?: string,
  label?:string,
  texto?:string
}

// TODO plantear extencion de IKanbanItem
export interface IKanbanItem {
  id: string;
  nombre: string;
  texto?: string,
  avatar?: IKanvanItemAvatar;
  flag?: boolean; // para alarmas o iconos extra
  extra?: IKanvanItemExtra[]
  visibilidadFiltrado?: boolean;
  meta?: any
}

export interface IListaDragDrop {
  id: string;
  items: IKanbanItem[],
  nombre?: string;
  totalCabecera?: string,
}



@Injectable()
export class KanbanService {
  private _columnas: IListaDragDrop[] = [];
  public columnas$: BehaviorSubject<IListaDragDrop[]> = new BehaviorSubject([]) ;

  constructor() {}

  establecerColumna(columna: IListaDragDrop) {
    let indice = this._columnas.findIndex(list => list.id === columna.id);
    if (indice >= 0) {
      this._columnas[indice]['items'] = columna.items;
      if (columna.totalCabecera)
        this._columnas[indice]['totalCabecera'] = columna.totalCabecera;
    } else {
      if (!columna.nombre) {
        columna.nombre = columna.id
      }
      this._columnas.push(columna)
    }
    this.columnas$.next(this._columnas);
  }

  eliminarColumnas() {
    this._columnas = [];
    this.columnas$.next(this._columnas);
  }

}
