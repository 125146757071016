import { FormatosFicherosEnum } from "@appNeo/neoShared/helpers/enums/FormatosFicheros.enum";

export const environment = {
  production: true,
  base_url: 'https://crm.mgser.es/',
  api_base_url: 'https://crm.mgser.es/backend/api',
  api_base_url_backoffice: 'https://crm.mgser.es/backend/api',
  base_url_backoffice: 'https://crm.mgser.es/backend/api/',
  base_url_backoffice_cron: 'https://crm.mgser.es/backend/',
  // BORRAR despues de migracionNEO
  close_message_delay_ms: 3000,
  numeroElementosPaginador: null,
  numeroElementosPaginadorDefault: 10,
  time_interval: 300000,

  close_alert_delay_ms: 3000,
  debug_interceptor_api: true,
  title_page: 'MGS',
  title_page_compose: 'MGS',
  // Simulación api
  simulacion_datos_login: false,
  simulacion_datos_maquetacion: false,
  // TABLA
  numero_rows_celda_editable_inicial: 1,
  numero_rows_celda_editable_focus: 30,
  // PAGINADOR
  numero_elementos_paginador: 10,
  paginador_opciones_numero_elementos: [10, 50, 70],
  paginador_label_elementos: 'Elementos por página',
  paginador_label_anterior: 'Página anterior',
  paginador_label_siguiente: 'Página siguiente',
  paginador_label_primera: 'Primera página',
  paginador_label_ultima: 'Última página',
  // SPINNER
  spinner_delay_ms: 1000,
  delay_finalizacion_peticiones_auth_ms: 1200,
  delay_finalizacion_peticiones_admin_ms: 0,
  delay_finalizacion_peticiones_public_ms: 450,
  // MENU BARRA LATERAL IZQUIEDA
  barra_desplegada: true,

  // PUNTO DE CORTE
  MEDIA_MOBILE: 768,
  MEDIA_TABLETV: 1024,
  MEDIATOTABLETV: 1023,

  // FORMULARIOS
  formulario_busqueda_debounce: 200,
  formulario_captcha_debounce: 500,
  longuitud_truncar_textos_defecto: 30,
  numero_maximo_etiquetas_defecto: 3,

  // BOTÓN DESPLEGABLE
  boton_desplegable_debounce: 200,
  // FICHEROS
  fichero_formatos_permitidos: [FormatosFicherosEnum.png, FormatosFicherosEnum.jpg, FormatosFicherosEnum.jpeg],
  fichero_tamano_maximo: 2000000000000000000,
  fichero_numero_maximo: 20,
  ruta_images: 'assets/images/',
  ruta_padre_admin: 'admin/',
  ruta_padre_areaCliente: 'areaCliente/',
  pagina_aterrizaje_areaCliente: '',
  pagina_aterrizaje: 'dashboard',
  ruta_padre_admin_demo: 'adminDemo/',
  pagina_aterrizaje_demo: 'adminDemo/dashboard',
  pagina_aterrizaje_front: '',
  pagina_login: '/auth/login',
  adminDemo: false, // router aterrizaje a [aaaa]_demo
  // ROLES PERMISOS
  activo_store_auth: true,
  activo_cron_store_auth: false,
  time_interval_cron_store_auth: 300000,
  idioma_defecto: 1,
  codigo_idioma_defecto: 'es',
  color_defecto_colorPicker: '#00D385',
  MAPA_APIKEY_DESARROLO: 'AIzaSyCOOVBdj0Z0z098DfUO0ruqvlq3Va5z0YA',
  MAPA_APIKEY_PRODUCCION: '',
  stripePublicKey: '',
  pagina_login_front: '/authF/login',
  debug: false,
  formatFechaApi: 'dd/MM/yyyy',
  descripcionMetaDefecto: 'MGS',
  cookie_bot_id: '',
  configuracionLogin: { registroLoginGoogle: false, opcionRegistro: false, opcionRecuperarContrasena: false },
  errorServidorSinCodigo: 'Error servidor, no se ha recibido respuesta',
  errorServidorUnknown: 'Servicio api no configurado',
  validar401Logout: false
};

