import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'neo-campo-boolean-palanca [controlCampo]',
  templateUrl: './campo-boolean-palanca.component.html',
  styleUrls: ['./campo-boolean-palanca.component.scss']
})
export class CampoBooleanPalancaComponent {

  @Input() label: string;
  @Input() texto: string;
  @Input() iconoClase: string;
  @Input() color: string = 'primary';
  @Input() controlCampo: FormControl = new FormControl(false);
  @Input() mostrarError = true;
}
