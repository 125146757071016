<mat-form-field class="form-field field-autocomplete" [ngClass]="{'field-autocomplete-multilinea': !responsivo}" appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <div class="form-cont">
    <mat-chip-list #chipList [formControl]="itemControl">
      <ng-container  *ngIf="chipItemsSeleccionados?.length > this.numeroEtiquetas(); else individual">
        <!-- <neo-etiqueta-input class="etiqueta-input" #campoChip
            *ngFor="let seleccion of chipItemsSeleccionados | slice:1:this.numeroEtiquetas(); let i = index;"
            data-testid="etiqueta-abreviada-texto"
                               [desactivado]="(seleccion?.disabled || itemControl.disabled)"
                               [texto]="seleccion.nombre"
                               (eliminar)="removeItem(seleccion)"
          ></neo-etiqueta-input>
          <neo-etiqueta-input class="etiqueta-input etiqueta-abreviada-contador"
            *ngIf="chipItemsSeleccionados | slice:numeroEtiquetas() - 1 as restoSeleccion"
            data-testid="etiqueta-abreviada-contador"
                              [desactivado]="(seleccion?.disabled || itemControl.disabled)"
                              [texto]="(this.numeroEtiquetas() == 1) ? chipItemsSeleccionados.length + ' Seleccionados' : '+' + (restoSeleccion.length).toString()"
                              (eliminar)="borrarEtiquetaGrupo(restoSeleccion)"
          ></neo-etiqueta-input> -->
  
          <mat-chip 
          *ngFor="let seleccion of chipItemsSeleccionados | slice:1:this.numeroEtiquetas(); let i = index;"
          class="etiqueta-input not-selectable"
          [removable]="!(seleccion?.disabled || itemControl.disabled)"
          [disabled]="(seleccion?.disabled || itemControl.disabled)"
          [color] ="'primary'"
          (removed)="removeItem(seleccion)">
          <span class="text-truncate">{{seleccion.nombre}}</span>
          <neo-icono matChipRemove *ngIf="chipItemsSeleccionadosBorrable"
              [iconoClase]="'icon-18 icon-ibm--close'"
          ></neo-icono>
        </mat-chip>
      
        <mat-chip
          *ngIf="chipItemsSeleccionados | slice:this.numeroEtiquetas() - 1 as restoSeleccion"
          class="etiqueta-input  not-selectable" [ngClass]="{'chip-abreviado-contador': this.numeroEtiquetas() > 1 }"
          [removable]="!(seleccion?.disabled || itemControl.disabled)"
          [disabled]="(seleccion?.disabled || itemControl.disabled)"
          [color] ="'primary'"
          (removed)="borrarEtiquetaGrupo(restoSeleccion)">
          <span class="text-truncate">{{(this.numeroEtiquetas() == 1) ? chipItemsSeleccionados.length + ' Seleccionados' : '+' +
            (restoSeleccion.length).toString()}}</span>
          <neo-icono matChipRemove *ngIf="chipItemsSeleccionadosBorrable"
              [iconoClase]="'icon-18 icon-ibm--close'"
          ></neo-icono>
        </mat-chip>
  
      </ng-container>
      <ng-template  #individual>
        <!-- <neo-etiqueta-input class="etiqueta-input" *ngFor="let seleccion of chipItemsSeleccionados"
        data-testid="etiqueta-texto"
                  [desactivado]="(seleccion?.disabled || itemControl.disabled)"
                  [texto]="seleccion.nombre"
                  (eliminar)="removeItem(seleccion)"
      ></neo-etiqueta-input> -->
      <mat-chip 
        *ngFor="let item of chipItemsSeleccionados"
        class="etiqueta-input not-selectable"
        [removable]="!(item?.disabled || itemControl.disabled)"
        [disabled]="(item?.disabled || itemControl.disabled)"
        [color] ="'primary'"
        (removed)="removeItem(item)">
        <span class="text-truncate">{{item.nombre}}</span>
        <neo-icono matChipRemove *ngIf="chipItemsSeleccionadosBorrable"
            [iconoClase]="'icon-18 icon-ibm--close'"
        ></neo-icono>
      </mat-chip>
      </ng-template >
  
  
  
  
      <input
        #itemInput
        [formControl]="inputControl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]=true
        (matChipInputTokenEnd)="addItem($event)">
        <neo-icono matSuffix *ngIf="!botonLimpiar"
                   [icono]="icono"
                   [iconoClase]="iconoClase"
        ></neo-icono>
    </mat-chip-list>
  </div>
  
  <button *ngIf="inputControl.value && botonLimpiar" matSuffix mat-icon-button aria-label="Clear" (click)="limpiarSeleccion()">
    <i class="icon-ibm--close icon-16"></i>
  </button>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="itemSelected($event)" autoActiveFirstOption="true">
    <mat-option *ngFor="let thisItem of itemsFiltrados$ | async" [value]="thisItem.nombre">
      <div class="avatar">
        <img *ngIf="thisItem?.avatar" class="avatar-img" src="{{thisItem.avatar}}">&nbsp;&nbsp;
        <div class="avatar-cont">
          <p class="avatar-nombre">{{thisItem.nombre}}</p>
          <p class="avatar-descripcion" *ngIf="thisItem?.descripcion && thisItem?.descripcion.length">
            {{thisItem?.descripcion}}</p>
        </div>
      </div>
    </mat-option>
  </mat-autocomplete>

  <mat-error class="invalid-feedback" *ngIf="itemControl?.errors">
    <neo-mat-error-form
        [nombreCampo]="label"
        [controlCampo]="itemControl">
    </neo-mat-error-form>
  </mat-error>
  <mat-hint>{{textoAyuda}}</mat-hint>
</mat-form-field>
