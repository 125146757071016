<mat-form-field class="field-telefono" appearance="outline">
  <mat-label *ngIf="label">{{label}}</mat-label>
  <ngx-mat-intl-tel-input #ngxTel
    [preferredCountries]="['es']"
    [enablePlaceholder]="true"
    [enableSearch]="true"
    [formControl]="controlCampo"></ngx-mat-intl-tel-input>
  <mat-hint *ngIf="textoAyuda">{{textoAyuda}}</mat-hint>
  <mat-error class="invalid-feedback" *ngIf="controlCampo.errors">
    <neo-mat-error-form
      [nombreCampo]="label"
      [controlCampo]=controlCampo>
      <mat-error *ngIf="controlCampo.errors.validatePhoneNumber">El formato no es correcto</mat-error>
    </neo-mat-error-form>
  </mat-error>
</mat-form-field>
