import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'neo-historial-estado [item]',
  templateUrl: './historial-estado.component.html',
  styleUrls: ['./historial-estado.component.scss']
})
export class HistorialEstadoComponent implements OnInit {

  @Input() item;
  constructor() { }

  ngOnInit(): void {
  }

}
