import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'neo-sin-elementos',
  templateUrl: './sin-elementos.component.html',
  styleUrls: ['./sin-elementos.component.scss']
})
export class SinElementosComponent implements OnInit {

  @Input('texto') texto: string = 'No se han encontrado registros.';
  constructor() { }

  ngOnInit(): void {
  }

}
