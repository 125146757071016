import { Component, EventEmitter, Input, Output } from '@angular/core';

// Esta Variante aplica cuando necesitamos controlar el valor mediante ngModel (Caso tabla)
@Component({
  selector: 'neo-campo-boolean-palanca-model',
  templateUrl: './campo-boolean-palanca-model.component.html',
  styleUrls: ['../campo-boolean-palanca.component.scss']
})
export class CampoBooleanPalancaModelComponent {

  @Input() label: string;
  @Input() texto: string;
  @Input() iconoClase: string;
  @Input() color: string = 'secondary';
  @Input() valor: boolean;
  @Input() disabled = false;

  @Output() nuevoValor = new EventEmitter<boolean>();

}
