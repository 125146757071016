import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'neo-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit {

  // ckEditor5 ClassicEditor;
  @Input() controlCampo: FormControl;
  @Input() label: string  = '';
  @Input() textoAyuda: string  = '';
  @Input() placeholder: string  = '';
  public editor = ClassicEditor;
  ckeConfig: any;
 
  constructor() { }

  ngOnInit() {
   // editor
    this.ckeConfig = {
      placeholder: this.placeholder,
      toolbar: {
        items: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 
         '|', 'outdent', 'indent', '|',
          '|', 'blockQuote', 'insertTable', 'undo', 'redo'
      ]
      },
      table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
      }
    };
    
  }

  onReady(editor) {
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      );
      const wordCountPlugin = editor.plugins.get( 'WordCount' );
      const wordCountWrapper = document.getElementById( 'word-count' );
      console.log(wordCountPlugin.wordCountContainer);
      wordCountWrapper.appendChild( wordCountPlugin.wordCountContainer );
  }

  onChange() {}
  

}
