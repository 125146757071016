import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { environment } from '@environments/environment';
import { Subscription } from 'rxjs';
import { AuthService } from '@appNeo/neoCore/services/auth/auth.service';
import { UsuarioService } from '@appNeo/neoCore/services/usuario/usuario.service';
import { BarsService } from './shared/services/bars/bars.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'clientes-mgs';
  subUsuarioSesion: Subscription;
  subUsuarioPermisosRol: Subscription;

  constructor(
    public routerVisibility: BarsService,
    public router: Router,
    public authService: AuthService,
    private usuarioService: UsuarioService
  ) {

    if (!this.authService.user$.value) {
      this.router.navigate(['auth/login']);
    }

  }
  ngOnInit(): void {
    // if (environment.activo_cron_store_auth && environment.activo_store_auth) {
    if (environment.activo_store_auth) {
      this.subUsuarioSesion = this.authService.user$.subscribe( usuario => {
        if (usuario) {
          this.subUsuarioPermisosRol = this.usuarioService.lecturaPermisosRolCron().subscribe(data => {});
       }
    });
    }
  }

  ngOnDestroy(): void {
   if (this.subUsuarioPermisosRol) {
      this.subUsuarioPermisosRol.unsubscribe();
    }

    if (this.subUsuarioSesion) {
      this.subUsuarioSesion.unsubscribe();
    }
  }
}
