<span class="error-form" *ngIf="controlCampo.errors">
  <ng-container *ngIf="!overrideRequiredError && controlCampo.hasError('required')">{{nombreCampo}} obligatorio</ng-container>
  <ng-container *ngIf="!overrideMaxlengthError && controlCampo.hasError('maxlength')">Debe tener como máximo {{controlCampo.errors?.maxlength?.requiredLength}} carácteres</ng-container>
  <ng-container *ngIf="!overrideMinlengthError && controlCampo.hasError('minlength')">Debe tener como mínimo {{controlCampo.errors?.minlength?.requiredLength}} carácteres</ng-container>
  <ng-container *ngIf="!overrideEmailError && controlCampo.hasError('email')">No es un email válido</ng-container>
  <ng-container *ngIf="!overridePatternError && controlCampo.hasError('pattern')">Formato incorrecto</ng-container>
  <ng-container *ngIf="!overrideErrorApiError && controlCampo.hasError('errorApi')">{{controlCampo.errors?.errorApi}}</ng-container>
  <ng-container *ngIf="!overrideMaxError && controlCampo.hasError('max')">  Máximo permitido {{controlCampo.errors?.max?.max}} </ng-container>
  <ng-container *ngIf="!overrideMinError && controlCampo.hasError('min')">  Mínimo permitido {{controlCampo.errors?.min?.min}} </ng-container>
  <ng-container *ngIf="!overrideMatStartDateInvalid && controlCampo.hasError('matStartDateInvalid')">  Fecha inicio inválida {{controlCampo.errors?.matStartDateInvalid}} </ng-container>
  <ng-container *ngIf="!overrideMatEndDateInvalid && controlCampo.hasError('matEndDateInvalid')">  Fecha fin inválida {{controlCampo.errors?.matEndDateInvalid}} </ng-container>
</span>

<span class="error-form" *ngIf="anyOverrideError || customError">
  <ng-content></ng-content>
</span>
