import {
  ElementRef,
  AfterViewInit,
  Directive,
  Host,
  Optional,
  Renderer2,
  Self,
  ViewContainerRef,
  Input
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";

export interface IPaginadorIconosBotonera  {
  navigationFirst ?: string;
  navigationPrevious ?: string;
  navigationNext ?: string;
  navigationLast ?: string;
}


@Directive({
  selector: "[iconosClasesPaginador]"
})
export class CustomizadoPaginadorDirective {
  @Input() iconosClasesPaginador: IPaginadorIconosBotonera = {};

  constructor(
    @Host() @Self() @Optional() private readonly matPag: MatPaginator,
    private vr: ViewContainerRef,
    private ren: Renderer2
  ) {
    //Sub to rerender buttons when next page and last page is used
    this.matPag.page.subscribe((v)=>{
      this.switchPage(v.pageIndex);
    })
  }

  private sobreescribirIconoMatPaginator() {

     // botones
    const buttonNavigationFirst = this.vr.element.nativeElement.querySelector(
      "button.mat-paginator-navigation-first span.mat-button-wrapper"
    );    
    const buttonNavigationPrevious = this.vr.element.nativeElement.querySelector(
      "button.mat-paginator-navigation-previous span.mat-button-wrapper"
    );
    const buttonNavigationNext = this.vr.element.nativeElement.querySelector(
      "button.mat-paginator-navigation-next  span.mat-button-wrapper"
    );
    const buttonNavigationLast = this.vr.element.nativeElement.querySelector(
      "button.mat-paginator-navigation-last  span.mat-button-wrapper"
    );

    // borramos el contenido, el svg por defecto
    if ( buttonNavigationFirst ) {
      let icono = (this.iconosClasesPaginador && this.iconosClasesPaginador?.navigationFirst ) ?  this.iconosClasesPaginador.navigationFirst:  '';
      if ( icono ) {
        buttonNavigationFirst.childNodes.forEach(contenido => {
          this.ren.removeChild(buttonNavigationFirst, contenido);
        });        
        this.ren.appendChild(buttonNavigationFirst, this.crearIcono(icono));
      }
    }

    if ( buttonNavigationPrevious ) {
      
      let icono = (this.iconosClasesPaginador && this.iconosClasesPaginador?.navigationPrevious ) ? this.iconosClasesPaginador.navigationPrevious: '';
      if ( icono ) {
        buttonNavigationPrevious.childNodes.forEach(contenido => {
          this.ren.removeChild(buttonNavigationPrevious, contenido);
         });
        this.ren.appendChild(buttonNavigationPrevious, this.crearIcono(icono));
      }
    }

    if ( buttonNavigationNext ) {
      let icono = (this.iconosClasesPaginador && this.iconosClasesPaginador?.navigationNext ) 
      ? this.iconosClasesPaginador.navigationNext: '';
      if (icono) {
        buttonNavigationNext.childNodes.forEach(contenido => {
          this.ren.removeChild(buttonNavigationNext, contenido);
         });
        this.ren.appendChild(buttonNavigationNext, this.crearIcono(icono));
      }
    }

    if ( buttonNavigationLast ) {
      let icono = (this.iconosClasesPaginador && this.iconosClasesPaginador?.navigationLast ) 
      ? this.iconosClasesPaginador.navigationLast: '';
      if (icono) {
        buttonNavigationLast.childNodes.forEach(contenido => {
          this.ren.removeChild(buttonNavigationLast, contenido);
        });
        this.ren.appendChild(buttonNavigationLast, this.crearIcono(icono));
      }
    }
  }

  
  private crearIcono(iconoClase: string) {
    const linkBtn = this.ren.createElement("i");
    this.ren.addClass(linkBtn, 'icon-20');
    this.ren.addClass(linkBtn, iconoClase);
    return linkBtn;
  }

  private initPageRange(): void {
    this.sobreescribirIconoMatPaginator();
  }

  private switchPage(i: number): void {
    this.initPageRange();
  }

  public ngAfterViewInit() {
    this.initPageRange();
  }
}
