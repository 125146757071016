export enum DialogActionEnum {
  Create = "create",
  Update = 'update',
  UpdatePassword = 'updatePassword',
  Delete = 'delete',
  DeleteMultiple = 'deleteMultiple',
  DeleteFile = 'deleteFile',
  Import = 'import',
  Upload = 'upload',
  Send = 'send',
  Ver = 'ver'
}
 
