import {NgModule, Optional, SkipSelf} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import { CommonModule } from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { LayoutComponent } from './layout/layout.component';
import {LayoutsModule} from '@appNeo/neoShared/modules/layouts/layouts.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    MatButtonToggleModule,
    LayoutsModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    FlexLayoutModule,
  ],
  exports: [
    LayoutComponent,
    FlexLayoutModule
  ]
})
export class NeoCoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: NeoCoreModule) {
    if (parentModule) {
      throw new Error(
        'NeoCoreModule is already loaded. Import it in the AppModule only.');
    }
  }

}
