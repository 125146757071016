import { Component, ViewChild, ElementRef, Input, Output, EventEmitter, HostListener, AfterViewInit } from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatChipInputEvent, MatChipList } from '@angular/material/chips';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

export interface Item {
  nombre: string;
  avatar?: string;
  descripcion?: string;
  id: number;
  disabled?:boolean;
}

@Component({
  selector: 'neo-chips-autocomplete',
  templateUrl: './chips-autocomplete.component.html',
  styleUrls: ['./chips-autocomplete.component.scss']
})
export class ChipsAutocompleteComponent  implements AfterViewInit{

  @Input() responsivo: boolean = false;
  @Input() itemControl: FormControl = new FormControl();
  @Input() textoAyuda: string;
  @Input() inputControl: FormControl = new FormControl();
  @Input() numMaximoEtiquetas: number = 9999;
  @Input() fuenteItems: any[] = [];


  @Input() chipItemsSeleccionados: Item[] = [];
  @Input() chipItemsSeleccionadosBorrable: boolean = true;

  @Input() label: string   = 'label';
  @Input() placeholder: string   = 'Nuevo item';
  //Todo, no debería ocultarse y mostrarse en función de si hay texto en el input??
  @Input() botonLimpiar: boolean = true;
  // si botonLimpiar activo, icono suffix desactivado
  @Input() icono: string   = 'face';
  @Input() iconoClase: string   = '';


  public itemsFiltrados$: Observable<Item[]>;

  //
  // Set this to false to ensure items are from fuenteItems list only.
  // Set this to true to also allow 'free text' items.
  //
  private allowFreeTextAddItem = true;


  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('itemInput') itemInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild("chipList") chipList: MatChipList;

  @HostListener('window:resize', ['$event'])
  windowResizecall(event) {
    this.calcularEtiquetas();
  }

  constructor(
    private element: ElementRef
  ) {

  }
  ngOnInit() {
    this.cargarElementosPreestablecidos();
    this.filterOnValueChange(null);
    this.itemsFiltrados$ = this.inputControl.valueChanges.pipe(
      startWith(null),
      map(itemName => this.filterOnValueChange(itemName))
    );
  }

  ngAfterViewInit() { //Recién en este punto tendrás acceso al valor
    setTimeout(() => {
      this.calcularEtiquetas();
    });
  }

  cargarElementosPreestablecidos() {
    this.itemControl.setValue(this.idSelected.toString());
    this.calcularEtiquetas();
    // filtrado fuente datos para no permitir selccion elementos preestablecidos
    
    // let fuente = this.fuenteItems.filter(item => {
    //   return !this.idSelected.includes(item.id);
    // })
    // this.fuenteItems = fuente;
  }
  public addItem(event: MatChipInputEvent): void {
    if (!this.allowFreeTextAddItem) {
      // only allowed to select from the filtered autocomplete list

      return;
    }

    //
    // Only add when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    //
    if (this.matAutocomplete.isOpen) {
      return;
    }

    // Add our item
    const value = event.value;
    if ((value || '').trim()) {
      this.selectItemByName(value.trim());

    }

  
    this.resetInputs();
  }

  private selectItemByName(itemName): void {
    const foundItem = this.fuenteItems.filter(item => item.nombre === itemName);

    if (foundItem.length) {
      //
      // We found the item name in the fuenteItems list
      //
      this.chipItemsSeleccionados.push(foundItem[0]);
    } else {
      //
      // Create a new item, assigning a new higher id
      // This is the use case when allowFreeTextAddItem is true
      //
      // let highestid = Math.max(...this.chipItemsSeleccionados.map(item => item.id), 0);
      this.chipItemsSeleccionados.push({ nombre: itemName, id: itemName });
      this.itemControl.setValue(this.idSelected.toString());
      // this.cambioSeleccion.emit(this.idSelected);
    }
    this.calcularEtiquetas();
  }

  public removeItem(item: Item): void {
    const index = this.chipItemsSeleccionados.indexOf(item);
    if (index >= 0) {
      this.chipItemsSeleccionados.splice(index, 1);
      this.resetInputs();
    }

    this.itemControl.setValue(this.idSelected.toString());
    this.calcularEtiquetas();
    // this.cambioSeleccion.emit(this.idSelected);
  }


  borrarEtiquetaGrupo(topping){
    topping.map(item => this.removeItem(item));
    this.calcularEtiquetas();
  }

  public itemSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectItemByName(event.option.value);
    this.itemControl.setValue(this.idSelected.toString());
    // this.cambioSeleccion.emit(this.idSelected);
    this.resetInputs();
  }

  private resetInputs(): void {
    // clear input element
    this.itemInput.nativeElement.value = '';
    // clear control value and trigger itemControl.valueChanges event
    this.inputControl.setValue(null);
  }

  //
  // Compute a new autocomplete list each time control value changes
  //
  private filterOnValueChange(itemName: string | null): Item[] {

    let result: Item[] = [];
    //
    // Remove the items we have already selected from all items to
    // get a starting point for the autocomplete list.
    //
    const fuenteItemsLessSelected = this.fuenteItems.filter(item => this.chipItemsSeleccionados.indexOf(item) < 0);

    if (itemName) {
      result = this.filterItem(fuenteItemsLessSelected, itemName);
    } else {
      result = fuenteItemsLessSelected.map(item => item);
    }

    return result;
  }

  private filterItem(itemList: Item[], itemName: String): Item[] {
    let filteredItemList: Item[] = [];
    const filterValue = itemName.toLowerCase();
    const itemsMatchingItemName = itemList.filter(item => item.nombre.toLowerCase().indexOf(filterValue) === 0);
    if (itemsMatchingItemName.length || this.allowFreeTextAddItem) {
      //
      // either the item name matched some autocomplete options
      // or the name didn't match but we're allowing
      // non-autocomplete item names to be entered
      //
      filteredItemList = itemsMatchingItemName;
    } else {
      //
      // the item name didn't match the autocomplete list
      // and we're only allowing items to be selected from the list
      // so we show the whjole list
      //
      filteredItemList = itemList;
    }
    //
    // Convert filtered list of item objects to list of item
    // name strings and return it
    //
    return filteredItemList.map(item => item);
  }



  get idSelected() {
    return this.chipItemsSeleccionados.map( item => item.id );
  }

  public limpiarSeleccion() {
    this.itemControl.reset();
    this.chipItemsSeleccionados = [];
    this.resetInputs();
  }

  numeroEtiquetas() {
    return this.numMaximoEtiquetas;
  }

  calcularEtiquetas() {
    if (this.responsivo == true) {
      let matChipAncho = 140;
      const matChip = this.element.nativeElement.querySelectorAll('mat-chip');
      if (matChip.length && matChip[0]) {
        matChipAncho = matChip[0].offsetWidth + 10;
      }
  
      const espacioSelect = this.element.nativeElement.querySelector('.mat-chip-list-wrapper');
      let ancho = espacioSelect.offsetWidth;
  
      let numEtiquetas = (ancho > 0 && ancho > matChipAncho) ? Math.floor(ancho / matChipAncho) : this.numMaximoEtiquetas;
      this.numMaximoEtiquetas = numEtiquetas;
    }
  }

 }
