import { Component, Input } from '@angular/core';

@Component({
  selector: 'neo-icono-form',
  templateUrl: './icono-form.component.html',
  styleUrls: ['./icono-form.component.scss']
})
export class IconoFormComponent  {

  @Input() matsuffix: boolean = true;
  @Input() matprefix: boolean = false;
  @Input() iconoClase = 'mat-icon icon-20 icon-ion--information-circle';
  constructor() { }

}
