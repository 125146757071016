<table *ngIf="data?.length" mat-table [dataSource]="propiedades">
    <!-- Item Column -->
    <ng-container matColumnDef="label">
      <th mat-cell *matCellDef="let propiedad">
          <span class="perfil-label">
            {{propiedad.label}}
            </span>
        </th>
    </ng-container>

    <!-- Cost Column -->
    <ng-container matColumnDef="valor">
        <td mat-cell *matCellDef="let propiedad">
            <container-element [ngSwitch]="propiedad?.tag">
                <some-element *ngSwitchCase="_PerfilPropiedadTagEnum.Boolean">
                  <neo-campo-boolean-palanca-model
                    [color]="'primary'"
                    [valor]="propiedad['valor']"
                    (nuevoValor)="propiedad['valor'] = $event">
                  </neo-campo-boolean-palanca-model>
                </some-element>

                <some-element *ngSwitchCase="_PerfilPropiedadTagEnum.Estado">
                    <neo-estado-label [estado]="propiedad['valor']"></neo-estado-label>
                </some-element>

                <some-element *ngSwitchCase="_PerfilPropiedadTagEnum.Icono">
                    {{icon?.name}}
                </some-element>

                <some-element *ngSwitchCase="_PerfilPropiedadTagEnum.Avatar">
                    <div class="avatar-txt">
                        <ngx-avatar name="{{propiedad['valor']?.nombre}}"  initialsSize="1"  src="{{propiedad['valor']?.src}}" size="40" class="ngx-mi-avatar"></ngx-avatar>
                        <span>{{propiedad['valor']?.nombre}}</span>
                    </div>
                </some-element>

                <some-element *ngSwitchCase="_PerfilPropiedadTagEnum.Imagen">
                    <img [src]="propiedad?.valor"  alt="" />
                    <!-- <img [src]="propiedad['valor']?.src ? propiedad['valor']?.src : imgDefault"  alt="{{propiedad['valor']?.alt}}" /> -->
                </some-element>

                <some-element *ngSwitchCase="_PerfilPropiedadTagEnum.EnlacePrincipal">
                    <a class="link linkPrincipal" (click)="$event.stopPropagation()" (click)="clickLink(propiedad['valorMapping']?.page, propiedad['valorMapping']?.id)">
                        {{ propiedad['valor'] }} {{propiedad.unidad}}
                    </a>
                </some-element>

                <some-element *ngSwitchCase="_PerfilPropiedadTagEnum.EnlaceSecundario">
                    <a class="link linkSecundario" (click)="$event.stopPropagation()" (click)="clickLink(propiedad['valorMapping']?.page, propiedad['valorMapping']?.id)">
                        {{ propiedad['valor'] }} {{propiedad.unidad}}
                    </a>
                </some-element>


                <some-element *ngSwitchCase="_PerfilPropiedadTagEnum.Editable">
                    <ng-container *ngIf="(propiedad.tagCampo === tablaService._TipoColumnaEditable.Input ||
                        propiedad.tagCampo === tablaService._TipoColumnaEditable.Cantidad ||
                        propiedad.tagCampo === tablaService._TipoColumnaEditable.Importe); else textAreaEdicion">
                        <container-element [ngSwitch]="propiedad.tagCampo">

                        <some-element *ngSwitchCase="tablaService._TipoColumnaEditable.Input">
                            <mat-form-field appearance="outline" floatLabel="never">
                            <input class="campo-editable-ellipsis" matInput placeholder="Introducir texto" [value]="propiedad['valor'].nombre" (change)="cambioCeldaEditable.emit({row: propiedad, columnaKey: propiedad.identificador, valorAnterior: propiedad['valor'].nombre, valorActualizado: $event.target['value']})">
                            </mat-form-field>
                        </some-element>

                        <some-element *ngSwitchCase="tablaService._TipoColumnaEditable.Importe">
                            <neo-input-importe-model
                            [importe]="propiedad['valor'].nombre"
                            (nuevoImporte)="actualizarDataSource({row: propiedad, columnaKey: propiedad.identificador, valorAnterior: propiedad['valor'].nombre, valorActualizado: $event})"
                            ></neo-input-importe-model>
                        </some-element>

                        <some-element *ngSwitchCase="tablaService._TipoColumnaEditable.Cantidad">
                            <neo-input-cantidad-model
                            [cantidad]="propiedad['valor'].nombre"
                            (nuevaCantidad)="actualizarDataSource({row: propiedad, columnaKey: propiedad.identificador, valorAnterior: propiedad['valor'].nombre, valorActualizado: $event})"
                            ></neo-input-cantidad-model>
                        </some-element>
                        </container-element>
                    </ng-container>
                    <ng-template #textAreaEdicion>
                        <mat-form-field appearance="outline" floatLabel="never" class="campo-editable-textarea" [ngClass]="{'textarea-abierto': true}">
                            <!-- TODO buscar solucion para aplicar clase  campo-editable-ellipsis, mientras usamos pipe-->

                            <textarea matInput
                            cdkTextareaAutosize
                            #textAreaColumnaEditable = "cdkTextareaAutosize"
                            cdkAutosizeMinRows = "{{numeroRowsCeldaEditableInicial}}"
                            cdkAutosizeMaxRows = "{{numeroRowsCeldaEditableInicial}}"
                            [value]="propiedad['valor'].nombre"
                            (click)="$event.stopPropagation()"
                            (focus)="focusTextAreaEdicion(propiedades.indexOf(propiedad))"
                            (focusout)=" focusOutTextAreaEdicion(propiedades.indexOf(propiedad)); actualizarDataSource({row: propiedad, columnaKey: propiedad.identificador, valorAnterior: propiedad['valor'].nombre, valorActualizado: $event.target['value']})"
                            ></textarea>
                        </mat-form-field>
                    </ng-template>

                </some-element>

                <some-element *ngSwitchCase="_PerfilPropiedadTagEnum.Importe">
                    {{ propiedad['valor'] }} {{propiedad.unidad}}
                </some-element>

                <some-element *ngSwitchCase="_PerfilPropiedadTagEnum.Hora">
                    {{propiedad['valor'] | date: 'HH:mm'}}
                </some-element>
                <some-element *ngSwitchCase="_PerfilPropiedadTagEnum.Fecha">
                    {{propiedad['valor'] | date: 'd/M/y'}}
                </some-element>
                <some-element *ngSwitchCase="_PerfilPropiedadTagEnum.FechaHora">
                    {{propiedad['valor'] | date: 'd/M/y, h:mm'}
                </some-element>

                <some-element *ngSwitchDefault>
                    {{propiedad['valor']}} {{propiedad?.unidad}}
                </some-element>

            </container-element>
        </td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>

