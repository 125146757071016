import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { CriterioValidezEnum } from '@appNeo/neoShared/helpers/enums/CriterioValidez.enum';



@Component({
  selector: 'neo-fortaleza-contresena',
  templateUrl: './fortaleza-contresena.component.html',
  styleUrls: ['./fortaleza-contresena.component.scss']
})
export class FortalezaContresenaComponent implements OnChanges {

  @Input() controlCampo: FormControl;
  @Input() contrasena: string;


  gradiente = false;
  porcentage = 0;


  @Input()
  validators: CriterioValidezEnum[] = Object.keys(CriterioValidezEnum).map(key => CriterioValidezEnum[key]);

  @Output()
  onStrengthChanged: EventEmitter<number> = new EventEmitter<number>();

  criteriosFortalezaMap = new Map<CriterioValidezEnum, RegExp>();


  containAtLeastEightChars: boolean;
  containAtLeastOneLowerCaseLetter: boolean;
  containAtLeastOneUpperCaseLetter: boolean;
  containAtLeastOneDigit: boolean;
  containAtLeastOneSpecialChar: boolean;
  passwordFormControl: AbstractControl;
  // private _strength: number;


  private _color: string;

  constructor() {
    this.criteriosFortalezaMap.set(CriterioValidezEnum.at_least_eight_chars, RegExp(/^.{8,63}$/));
    this.criteriosFortalezaMap.set(CriterioValidezEnum.at_least_one_lower_case_char, RegExp(/^(?=.*?[a-z])/));
    this.criteriosFortalezaMap.set(CriterioValidezEnum.at_least_one_upper_case_char, RegExp(/^(?=.*?[A-Z])/));
    this.criteriosFortalezaMap.set(CriterioValidezEnum.at_least_one_digit_char, RegExp(/^(?=.*?[0-9])/));
    this.criteriosFortalezaMap.set(CriterioValidezEnum.at_least_one_special_char, RegExp(/^(?=.*?[" !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~"])/));



  }

  ngOnChanges(changes: SimpleChanges): void {

    this.contrasena && this.contrasena?.length && this.contrasena.length > 0 ?
      this.calculatePasswordStrength() : this.reset();
  }


  get strength(): number {
    return this.porcentage ? this.porcentage : 0;
  }


  get color(): any {


    if (this.porcentage <= 20) {
      return {color: 'red', gradiente: false};
    } else if (this.porcentage <= 80) {
      return  {color: 'linear-gradient(to right, red, orange)', gradiente: true};
    } else {
      return   {color: 'linear-gradient(to right, red, orange, green)', gradiente: true};
    }
  }


  private _containAtLeastEightChars(): boolean {
    this.containAtLeastEightChars = this.contrasena.length >= 8;
    return this.containAtLeastEightChars;
  }


  private _containAtLeastOneLowerCaseLetter(): boolean {
    this.containAtLeastOneLowerCaseLetter =
      this.criteriosFortalezaMap
        .get(CriterioValidezEnum.at_least_one_lower_case_char)
        .test(this.contrasena);
    return this.containAtLeastOneLowerCaseLetter;
  }


  private _containAtLeastOneUpperCaseLetter(): boolean {
    this.containAtLeastOneUpperCaseLetter =
      this.criteriosFortalezaMap
        .get(CriterioValidezEnum.at_least_one_upper_case_char)
        .test(this.contrasena);
    return this.containAtLeastOneUpperCaseLetter;
  }


  private _containAtLeastOneDigit(): boolean {
    this.containAtLeastOneDigit =
      this.criteriosFortalezaMap
        .get(CriterioValidezEnum.at_least_one_digit_char)
        .test(this.contrasena);
    return this.containAtLeastOneDigit;
  }


  private _containAtLeastOneSpecialChar(): boolean {
    this.containAtLeastOneSpecialChar =
      this.criteriosFortalezaMap
        .get(CriterioValidezEnum.at_least_one_special_char)
        .test(this.contrasena);
    return this.containAtLeastOneSpecialChar;
  }


  calculatePasswordStrength() {
    const requirements: Array<boolean> = [];
    const unit = 100 / 5;


    requirements.push(
      this._containAtLeastEightChars(),
      this._containAtLeastOneLowerCaseLetter(),
      this._containAtLeastOneUpperCaseLetter(),
      this._containAtLeastOneDigit(),
      this._containAtLeastOneSpecialChar());


    this.porcentage = requirements.filter(v => v).length * unit;
    this.onStrengthChanged.emit(this.strength);
  }


  reset() {
    this.porcentage = 0;
    this.containAtLeastEightChars =
      this.containAtLeastOneLowerCaseLetter =
        this.containAtLeastOneUpperCaseLetter =
          this.containAtLeastOneDigit =
            this.containAtLeastOneSpecialChar = false;
  }
}
