<mat-form-field class="form-field field-select" appearance="outline">
  <mat-label *ngIf="label">{{label}}</mat-label>
  <ng-container *ngIf="multiple; else selectIndividual" >
    <div class="mat-select-container">
      <mat-select #campoSelectMultiple [formControl]="controlCampo" multiple [ngClass]="{'matSelectChips': totalSeleccionados() > 1 }" (selectionChange)="cambiaSeleccion($event)">
        <mat-option *ngFor="let option of datos" value="{{option.id}}">
          <ng-container *ngTemplateOutlet="templateOption;context:{option:option,i:i}"></ng-container>          
        </mat-option>
        <mat-select-trigger  *ngIf="totalSeleccionados() === 1" class="flex">
          <ng-container *ngTemplateOutlet="templateOption;context:{option:valorOpcionSeleccionada(controlCampo?.value[0]),i:0}"></ng-container>
        </mat-select-trigger>
        <mat-select-trigger  *ngIf="totalSeleccionados() > 1" class="flex">
          
          <ng-container *ngIf="totalSeleccionados() > this.numeroEtiquetas(); else individual">
            <neo-etiqueta-input class="etiqueta-input" #campoChip
              *ngFor="let seleccion of controlCampo?.value | slice:1:this.numeroEtiquetas(); let i = index;"
              data-testid="etiqueta-abreviada-texto"
                                 [icono]="valorOpcionSeleccionada(controlCampo?.value[i])?.icono"
                                 [iconoClase]="valorOpcionSeleccionada(controlCampo?.value[i])?.iconoClase"
                                 [texto]="valorOpcionSeleccionada(controlCampo?.value[i])?.nombre"
                                 (eliminar)="borrarEtiquetaIndividual([controlCampo?.value[i]])"
            ></neo-etiqueta-input>
            <neo-etiqueta-input class="etiqueta-input" [ngClass]="{'etiqueta-abreviada-contador': this.numeroEtiquetas() > 1 }"
              *ngIf="controlCampo?.value | slice:this.numeroEtiquetas() - 1 as restoSeleccion"
              data-testid="etiqueta-abreviada-contador"
                                [texto]="(this.numeroEtiquetas() == 1) ? totalSeleccionados() + ' Seleccionados' : ' + ' + (restoSeleccion.length).toString()"
                                (eliminar)="borrarEtiquetaGrupo(restoSeleccion)"
            ></neo-etiqueta-input>
          </ng-container>
          <ng-template  #individual>
            <neo-etiqueta-input class="etiqueta-input" *ngFor="let seleccion of controlCampo?.value ; let i = index;"
              data-testid="etiqueta-texto"
                                 [icono]="valorOpcionSeleccionada(controlCampo?.value[i])?.icono"
                                 [iconoClase]="valorOpcionSeleccionada(controlCampo?.value[i])?.iconoClase"
                                 [texto]="valorOpcionSeleccionada(controlCampo?.value[i])?.nombre"
                                 (eliminar)="borrarEtiquetaIndividual([controlCampo?.value[i]])"
            ></neo-etiqueta-input>
          </ng-template>
        </mat-select-trigger>
      </mat-select>
      <neo-button class="btn-limpiar-filtro"
                  *ngIf="totalSeleccionados() === 1 && !controlCampo.disabled"
                  [icono]="true"
                  [iconClass]="'icon-ibm--close icon-18'"
                  [disabled]="controlCampo.disabled"
                  (accion)="vaciarInput(); $event.stopPropagation();"
      ></neo-button>
    </div>
  </ng-container>

  <ng-template  #selectIndividual>
    <div class="mat-select-container">
      <mat-select [formControl]="controlCampo" #campoSelectIndividual>
        <mat-select-trigger>
          <ng-container *ngTemplateOutlet="templateOption;context:{option:valorOpcionSeleccionada(controlCampo?.value),i:i}"></ng-container>
        </mat-select-trigger>
        <mat-option *ngIf="opcionGeneral" value=""> {{opcionGeneralNombre}} </mat-option>
        <mat-option *ngFor="let option of datos" value="{{option.id}}">
          <ng-container *ngTemplateOutlet="templateOption;context:{option:option,i:i}"></ng-container>          
        </mat-option>
      </mat-select>
      <neo-button class="btn-limpiar-filtro"
                  *ngIf="totalSeleccionados() === 1 && !controlCampo.disabled"
                  [icono]="true"
                  [iconClass]="'icon-ibm--close icon-18'"
                  [disabled]="controlCampo.disabled"
                  (accion)="vaciarInput(); $event.stopPropagation();"
      ></neo-button>
    </div>
  </ng-template>
  <mat-icon *ngIf="icon" matSuffix>{{icon}}</mat-icon>

  <mat-error class="invalid-feedback" *ngIf="controlCampo?.errors">
    <neo-mat-error-form
      [nombreCampo]="label"
      [controlCampo]="controlCampo">
    </neo-mat-error-form>
  </mat-error>
  <mat-hint *ngIf="textoAyuda">
    {{textoAyuda}}
  </mat-hint>
</mat-form-field>


<ng-template #templateOption  let-option="option" let-i="i" >
  <neo-icono *ngIf="option?.icono || option?.iconoClase"
    [icono]="option?.icono"
    [iconoClase]="option?.iconoClase + ' mr-5 '"
    [color]="option?.color"
  ></neo-icono>          
  <span [ngStyle]="{'color': option?.color}">{{ option?.nombre }}</span>
</ng-template>
