import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output, QueryList,
    SimpleChanges,
    TemplateRef,
    ViewChild, ViewChildren
  } from '@angular/core';
import { FormControl } from '@angular/forms';
import { environment } from '@environments/environment';

  
  @Component({
    selector: 'neo-campo-color-picker [controlCampo]',
    templateUrl: './campo-color-picker.component.html',
    styleUrls: ['./campo-color-picker.component.scss'],
  })
  export class CampoColorPickerComponent implements AfterViewInit  {

    @Input() controlCampo: FormControl;
    @Input() label = '';
    color = environment.color_defecto_colorPicker;


    
    constructor() {}

    ngAfterViewInit(): void {
      setTimeout(()=>{
        if (!this.controlCampo.value) {
          this.controlCampo.setValue(this.color);
        }
        this.color = this.controlCampo.value;
      })
    }

    public openPicker(): void {
      let colorPicker: HTMLElement = document.getElementById(
        'colorPickerElement'
      );
        colorPicker.click();
    }



    public onEventLog(event: string, data: any): void {
      if (data.color) {
        this.color = data.color;
      }
      if (this.color) {
        this.controlCampo.setValue(this.color);
      }
    }
  
    
  }