import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnChanges,
  OnInit,
  OnDestroy,
  LOCALE_ID,
  Inject,
  TemplateRef,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import {
  WeekDay,
  CalendarEvent,
  WeekViewAllDayEvent,
  WeekView,
  ViewPeriod,
  WeekViewHourColumn,
  WeekViewTimeEvent,
  WeekViewHourSegment,
  WeekViewHour,
  WeekViewAllDayEventRow,
} from 'calendar-utils';
import { ResizeEvent } from 'angular-resizable-element';
import {
  DragEndEvent,
  DropEvent,
  DragMoveEvent,
  ValidateDrag,
} from 'angular-draggable-droppable';
import { PlacementArray } from 'positioning';
import { CalendarEventTimesChangedEvent, CalendarUtils, CalendarWeekViewBeforeRenderEvent, CalendarWeekViewComponent, DateAdapter } from 'angular-calendar';
import { WeekViewAllDayEventResize } from 'angular-calendar/modules/week/calendar-week-view.component';
import { addMonths, eachDayOfInterval, format, getDay, isFuture, isPast, isToday, isWeekend } from 'date-fns';
import { VistasCalendarioExtendidoEnum } from '../calendario/calendario.component';
import { INTERVALOMESESAGENDA } from '../calendario-configuracion';
import { AuxiliarService } from '@appNeo/neoShared/services/auxiliar/auxiliar.service';

interface WeekDayExtendido extends WeekDay {
  eventosDia?: CalendarEvent<any>[]
}

@Component({
  selector: 'neo-calendario-agenda-vista',
  templateUrl: './calendario-agenda-vista.component.html',
  styleUrls: ['./calendario-agenda-vista.component.scss']
})
export class CalendarioAgendaVistaComponent  extends CalendarWeekViewComponent{

  @Output() clickEvento = new EventEmitter<Object>();

  @Input() configuracionDias;
  @Input() claseDesactivar;
 /**
   * The current view date
   */
  @Input() viewDate: Date;

  /**
   * An array of events to display on view
   * The schema is available here: https://github.com/mattlewis92/calendar-utils/blob/c51689985f59a271940e30bc4e2c4e1fee3fcb5c/src/calendarUtils.ts#L49-L63
   */
  @Input() events: CalendarEvent[] = [];

  /**
   * An array of day indexes (0 = sunday, 1 = monday etc) that will be hidden on the view
   */
  @Input() excludeDays: number[] = [];

  /**
   * An observable that when emitted on will re-render the current view
   */
  @Input() refresh: Subject<any>;

  /**
   * The locale used to format dates
   */
  @Input() locale: string;

  /**
   * The placement of the event tooltip
   */
  @Input() tooltipPlacement: PlacementArray = 'auto';

  /**
   * A custom template to use for the event tooltips
   */
  @Input() tooltipTemplate: TemplateRef<any>;

  /**
   * Whether to append tooltips to the body or next to the trigger element
   */
  @Input() tooltipAppendToBody: boolean = true;

  /**
   * The delay in milliseconds before the tooltip should be displayed. If not provided the tooltip
   * will be displayed immediately.
   */
  @Input() tooltipDelay: number | null = null;

  /**
   * The start number of the week.
   * This is ignored when the `daysInWeek` input is also set as the `viewDate` will be used as the start of the week instead.
   * Note, you should also pass this to the calendar title pipe so it shows the same days: {{ viewDate | calendarDate:(view + 'ViewTitle'):locale:weekStartsOn }}
   * If using the moment date adapter this option won't do anything and you'll need to set it globally like so:
   * ```
   * moment.updateLocale('en', {
   *   week: {
   *     dow: 1, // set start of week to monday instead
   *     doy: 0,
   *   },
   * });
   * ```
   */
  @Input() weekStartsOn: number;

  /**
   * A custom template to use to replace the header
   */
  @Input() headerTemplate: TemplateRef<any>;

  /**
   * A custom template to use for week view events
   */
  @Input() eventTemplate: TemplateRef<any>;

  /**
   * A custom template to use for event titles
   */
  @Input() eventTitleTemplate: TemplateRef<any>;

  /**
   * A custom template to use for event actions
   */
  @Input() eventActionsTemplate: TemplateRef<any>;

  /**
   * The precision to display events.
   * `days` will round event start and end dates to the nearest day and `minutes` will not do this rounding
   */
  @Input() precision: 'days' | 'minutes' = 'days';

  /**
   * An array of day indexes (0 = sunday, 1 = monday etc) that indicate which days are weekends
   */
  @Input() weekendDays: number[];

  /**
   * Whether to snap events to a grid when dragging
   */
  @Input() snapDraggedEvents: boolean = true;

  /**
   * The number of segments in an hour. Must divide equally into 60.
   */
  @Input() hourSegments: number = 2;

  /**
   * The duration of each segment group in minutes
   */
  @Input() hourDuration: number;

  /**
   * The height in pixels of each hour segment
   */
  @Input() hourSegmentHeight: number = 30;

  /**
   * The minimum height in pixels of each event
   */
  @Input() minimumEventHeight: number = 30;

  /**
   * The day start hours in 24 hour time. Must be 0-23
   */
  @Input() dayStartHour: number = 0;

  /**
   * The day start minutes. Must be 0-59
   */
  @Input() dayStartMinute: number = 0;

  /**
   * The day end hours in 24 hour time. Must be 0-23
   */
  @Input() dayEndHour: number = 23;

  /**
   * The day end minutes. Must be 0-59
   */
  @Input() dayEndMinute: number = 59;

  /**
   * A custom template to use to replace the hour segment
   */
  @Input() hourSegmentTemplate: TemplateRef<any>;

  /**
   * The grid size to snap resizing and dragging of hourly events to
   */
  @Input() eventSnapSize: number;

  /**
   * A custom template to use for the all day events label text
   */
  @Input() allDayEventsLabelTemplate: TemplateRef<any>;

  /**
   * The number of days in a week. Can be used to create a shorter or longer week view.
   * The first day of the week will always be the `viewDate` and `weekStartsOn` if set will be ignored
   */
  @Input() daysInWeek: number;

  /**
   * A custom template to use for the current time marker
   */
  @Input() currentTimeMarkerTemplate: TemplateRef<any>;

  /**
   * Allow you to customise where events can be dragged and resized to.
   * Return true to allow dragging and resizing to the new location, or false to prevent it
   */
  @Input() validateEventTimesChanged: (
    event: CalendarEventTimesChangedEvent
  ) => boolean;

  
  /**
   * Called when a header week day is clicked. Adding a `cssClass` property on `$event.day` will add that class to the header element
   */
  @Output() dayHeaderClicked = new EventEmitter<{
    day: WeekDay;
    sourceEvent: MouseEvent;
  }>();

  /**
   * Called when an event title is clicked
   */
  @Output() eventClicked = new EventEmitter<{
    event: CalendarEvent;
    sourceEvent: MouseEvent | KeyboardEvent;
  }>();

  /**
   * Called when an event is resized or dragged and dropped
   */
  @Output() eventTimesChanged =
    new EventEmitter<CalendarEventTimesChangedEvent>();

  /**
   * An output that will be called before the view is rendered for the current week.
   * If you add the `cssClass` property to a day in the header it will add that class to the cell element in the template
   */
  @Output() beforeViewRender =
    new EventEmitter<CalendarWeekViewBeforeRenderEvent>();

  /**
   * Called when an hour segment is clicked
   */
  @Output() hourSegmentClicked = new EventEmitter<{
    date: Date;
    sourceEvent: MouseEvent;
  }>();

  /**
   * @hidden
   */
  days: WeekDay[];

  /**
   * @hidden
   */
  view: WeekView;

  /**
   * @hidden
   */
  refreshSubscription: Subscription;

  /**
   * @hidden
   */
  allDayEventResizes: Map<WeekViewAllDayEvent, WeekViewAllDayEventResize> =
    new Map();

  /**
   * @hidden
   */
  timeEventResizes: Map<CalendarEvent, ResizeEvent> = new Map();

  /**
   * @hidden
   */
  eventDragEnterByType = {
    allDay: 0,
    time: 0,
  };

  /**
   * @hidden
   */
  dragActive = false;

  /**
   * @hidden
   */
  dragAlreadyMoved = false;

  /**
   * @hidden
   */
  validateDrag: ValidateDrag;

  /**
   * @hidden
   */
  validateResize: (args: any) => boolean;

  /**
   * @hidden
   */
  dayColumnWidth: number;

  /**
   * @hidden
   */
  calendarId = Symbol('angular calendar week view id');

  /**
   * @hidden
   */
  lastDraggedEvent: CalendarEvent;

  /**
   * @hidden
   */
  rtl = false;


  vistaActual = VistasCalendarioExtendidoEnum.Agenda;
  diasAgenda: WeekDayExtendido[] = [];
  eventosAgenda: CalendarEvent<any>; 
  existenEventos:boolean = false;
  intervaloMesesAgenda: number = INTERVALOMESESAGENDA;

  constructor(
    cdr: ChangeDetectorRef,
    utils: CalendarUtils,
    dateAdapter: DateAdapter,
    element: ElementRef<HTMLElement>,
    public auxiliarService: AuxiliarService
  ) {
    // locale metido directamente por sincronias pero se recibe en this.locale
    super(cdr, utils, 'es', dateAdapter, element);
  }

  refreshBody() {
    return null;
  }

  // ngOnInit(): void {
  //     console.log(this.viewDate);
  //     console.log(this.days);
  //     console.log(this.events);
  // }

  ngOnChanges(changes: any): void {
    super.ngOnChanges(changes);
    this.recalcularDias();
  }

  recalcularDias(){
    this.existenEventos = false;
    let dias: Date[] = eachDayOfInterval(
      { start: this.days[0].date, end:  addMonths(this.days[0].date, this.intervaloMesesAgenda) }
    );
    this.diasAgenda = [];
    dias.forEach( dia => {
      let _eventos = this.eventosDiaAgenda(dia);
      if (_eventos?.length>0) {
        this.existenEventos = true;
      }
      let codigoClaseColorDia = this.aplicarClasesDias(dia);
      _eventos.map(eve => eve.cssClass = codigoClaseColorDia);
      this.diasAgenda.push(
        {
          date: dia,
          day: getDay(dia),
          isPast: isPast(dia),
          isToday: isToday(dia),
          isFuture: isFuture(dia),
          isWeekend: isWeekend(dia),
          cssClass: this.aplicarClasesDias(dia),
          eventosDia: _eventos
        }
      )
    }
    );
   
  }
 
  eventosDiaAgenda(dia) {
    let fechaActual = format(dia, 'dd-LL-yyyy');
    return this.events?.filter(evento =>  {
      if (evento.start instanceof Date && !isNaN(evento.start.getTime())) {
        return format(evento.start, 'dd-LL-yyyy') === fechaActual;
      } else {
        return false;
      }
    });
  }

  esHoy(diaAgenda) {
    let fechaActual = new Date();
    let dia = fechaActual.getDate();
    let mes = fechaActual.getMonth() + 1;
    let anio = fechaActual.getFullYear();
    let fecha_actual = String(mes + "/" + dia + "/" + anio);

    let hoy = new Date(fecha_actual).getTime();
    let _diaAgenda = diaAgenda.getTime();
    return hoy == _diaAgenda;
  }


  // ****************************************************************** //
  // ******************* DESHABILITAR FECHAS                 ********** //
  // ****************************************************************** //
  esFechaValida(date: Date): boolean {
    let validacion = true;
    // if (this.minFecha && this.maxFecha) {
    //   validacion = date >= this.minFecha && date <= this.maxFecha;
    // } else {
    //   validacion = (this.minFecha || this.maxFecha) ?
    //   (this.minFecha) ? date >= this.minFecha : date <= this.maxFecha
    //   : true;
    // }
    // if (validacion && this.deshabilitarDiasSinClaseEspecificada && !this.modoSeleccion) {
    //   // control de fechas que no tienen clase aplicada
    //   let item = this.auxiliarService.busquedaPropiedad(this.configuracionDias, 'dia', date.getTime());
    //   validacion = (item && item.clase.length);
    // }
    return validacion;
  }

  // hay fecha
  private aplicarClasesDias(dia): string {
    if (!this.esFechaValida(dia.date)) {
      return this.claseDesactivar;
    } else {
      let itemPersonalizado = this.busquedaPropiedad(this.configuracionDias, 'dia', dia.getTime());
      if (itemPersonalizado) {
        console.log(itemPersonalizado.clase);
        return itemPersonalizado.clase;
      } else {
        return '';
      }
    }
  }

  private busquedaPropiedad(elementos, propiedad, valor) {
    return elementos?.find(element => element[propiedad] === valor);
  }



}
