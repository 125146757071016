import { Injectable } from '@angular/core';
import { ITodoNodoItem } from '@appNeo/neoShared/helpers/interfaces/ITodo-nodo-item';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ArbolService {

  private cambioDatos$ = new BehaviorSubject<ITodoNodoItem[]>([]);
  public nivelProfundidadMaximo = 3;

  get data(): ITodoNodoItem[] { return this.cambioDatos$.value; }

  constructor() {}

  getDatos():BehaviorSubject<ITodoNodoItem[]>{
    return this.cambioDatos$;
  }


  setDatos(datos: any) {
    /**
   * The Json object for to-do list data.
   */
    if ( datos !== null ) {
      this.inicializar( datos );
    }
  }

  inicializar(datos: any) {
    // Build the tree nodes from Json object. The result is a list of `TodoNodoItem` with nested
    //     file node as children.
    const data = this.construirArbolDataArray( datos, 0);

    // Notify the change.
    this.cambioDatos$.next(data);
  }

  /**
   * Build the file structure tree. The `value` is the Json object, or a sub-tree of a Json object.
   * The return value is the list of `TodoNodoItem`.
   */
  construirArbol(obj: {[key: string]: any}, level: number): ITodoNodoItem[] {
    return Object.keys(obj).reduce<ITodoNodoItem[]>((acumulador, key) => {
      const value = obj[key];
      const node = new ITodoNodoItem();
      node.item = key;

      if (value != null) {
        if (typeof value === 'object') {
          // definido nivel profundidad maximo
          if ( level + 1 <= this.nivelProfundidadMaximo ) {
            node.children = this.construirArbol(value, level + 1);
          }
        } else {
          node.item = value;
        }
      }

      return acumulador.concat(node);
    }, []);
  }

  // interface libre de la que se espera para cada item hijos, id, nombre
  construirArbolDataArray(obj: any[], level: number): ITodoNodoItem[] {

      return obj.reduce<ITodoNodoItem[]>((acumulador, item) => {
      const value = item.children;
      const node = new ITodoNodoItem();
      node.item = item.item;
      node.id = item.id;

      if( value && value.length ) {
        if ( level + 1 <= this.nivelProfundidadMaximo ) {
          node.children = this.construirArbolDataArray(value, level + 1);
        }
      }

      return acumulador.concat(node);
    }, []);
  }

  actualizarNodo(nodo: ITodoNodoItem, nombre: string) {
    nodo.item = nombre;
    // asgurar que data tiene actualizado el dato!!!!
    this.cambioDatos$.next(this.data);
  }

  eliminarNodo(nodo: ITodoNodoItem) {
    this.cambioDatos$.next(this.data);
  }


}
