import { Injectable } from '@angular/core';
import { ITodoNodoItem } from '@appNeo/neoShared/helpers/interfaces/ITodo-nodo-item';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ArbolAnidadoService {

  private cambioDatos$ = new BehaviorSubject<ITodoNodoItem[]>([]);
  public nivelProfundidadMaximo = 3;

  get data(): ITodoNodoItem[] { return this.cambioDatos$.value; }

  constructor() {}

  getDatos():BehaviorSubject<ITodoNodoItem[]>{
    return this.cambioDatos$;
  }


  setDatos(datos: any) {
    console.log('DATOS ANIDADOS',datos);
    /**
   * The Json object for to-do list data.
   */
    if ( datos !== null ) {
      this.cambioDatos$.next(datos);
    }
  }

  // SIMULAMOS DESDE SERVICIO; PERO VENDRA DESDE PADRE CON CRUD
  actualizarNodo(nodo: ITodoNodoItem, nombre: string) {
    nodo.item = nombre;
    // asgurar que data tiene actualizado el dato!!!!
    this.cambioDatos$.next(this.data);
  }


  eliminarNodo(nodo: ITodoNodoItem) {
    this.cambioDatos$.next(this.data);
  }

}
