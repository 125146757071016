import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { IconEnum } from '@appNeo/neoShared/helpers/enums/Icon.enum';
import { NotificacionEnum } from '@appNeo/neoShared/helpers/enums/Notificacion.enum';
import { INotificacion } from '@appNeo/neoShared/helpers/interfaces/INotificacion';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'any'
})
export class NotificacionService {

  private readonly subject: any;
  private keepAfterRouteChange = false;

  constructor(private router: Router) {
    this.subject = new ReplaySubject<INotificacion>();
    router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        if (this.keepAfterRouteChange) {
          this.keepAfterRouteChange = false;
        } else {
          this.eliminarNotificaciones();
        }
      }
    });
  }

  obtenerNotificaciones(): Observable<any> {
    return this.subject.asObservable();
  }

  // TODO darle una vuelta a IconEnum, si clases de icono o tipado que se resuelve en calse, lo unico que asi no tenemos libertar para mandar clase concreta
  crearNotificacion(tipo: NotificacionEnum, titulo:string, mensaje: string = '', btnCerrar: boolean = true, icono: IconEnum = IconEnum.Error, iconoClasePersonalizado:string = '', keepAfterRouteChange = false, cierreProgramado = false ) {
    this.keepAfterRouteChange = keepAfterRouteChange;
    this.subject.next( { tipo, titulo, mensaje, btnCerrar, icono, iconoClasePersonalizado, cierreProgramado} as INotificacion);
  }

  eliminarNotificaciones() {
    this.subject.next();
  }

}
