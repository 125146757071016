import { Component, Input } from '@angular/core';
import {EventEmitter, Output} from '@angular/core';
import {IActualizacionCeldaEditable} from '@appNeo/neoShared/services/tabla/tabla.service';

@Component({
  selector: 'neo-contenedor-masinfo',
  template: '<neo-perfil-vertical [propiedades]="propiedades" (cambioCeldaEditable)="cambioCeldaEditable.emit($event)"></neo-perfil-vertical>',
  styleUrls: ['./contenedor-masinfo-tabla.component.scss']
})
export class ContenedorMasinfoTablaComponent {

  @Input() propiedades: any;
  @Output() cambioCeldaEditable = new EventEmitter<IActualizacionCeldaEditable>();

  constructor() { }
}
