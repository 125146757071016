import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClasesFormularioAnchoEnum } from '@appNeo/neoShared/helpers/interfaces/IForm-input';
import { AuxiliarService } from '@appNeo/neoShared/services/auxiliar/auxiliar.service';
import { CalendarView } from 'angular-calendar';
import { INPUTS_SELECCIONVISTACALENDARIO } from '../calendario-configuracion';
import { VistasCalendario, VistasCalendarioExtendidoEnum } from '../calendario/calendario.component';

@Component({
  selector: 'neo-calendario-cabecera',
  templateUrl: './calendario-cabecera.component.html',
  styleUrls: ['./calendario-cabecera.component.scss']
})
export class CalendarioCabeceraComponent implements OnInit{

  @Input() vista: VistasCalendario;

  @Input() botonHoy: boolean;
  @Input() vistaSemana: boolean;
  @Input() vistaDia: boolean;
  @Input() vistaAgenda: boolean;

  @Input() diaVista: Date;

  @Input() locale: string = 'es';

   /**
   * Days to skip when going back by 1 day
   */
  @Input() excludeDays: number[] = [];

    /**
     * The number of days in a week. If set will subtract this amount of days instead of 1 week
     */
  @Input() daysInWeek: number;
  
  @Output() vistaCambia = new EventEmitter<VistasCalendario>();

  @Output() disVistaCambia = new EventEmitter<Date>();

  CalendarView = CalendarView;

  VistasCalendarioExtendidoEnum = VistasCalendarioExtendidoEnum;
  // inicio semana

   // congiguracion layout formularios
  clasesFormularioAnchoEnum = ClasesFormularioAnchoEnum.formularioAnchoCompleto;
  aplicarFlex: boolean = true;
  inputsFormularioVistaCalendario = INPUTS_SELECCIONVISTACALENDARIO;

  constructor(
    private auxiliarService: AuxiliarService
  ) {}
  
  ngOnInit(): void {
    let opcionesVista = [{
      id: CalendarView.Month as string,
      nombre: 'Mes'
    }];
    if (this.vistaSemana) {
      opcionesVista.push(
        {
          id: CalendarView.Week as string,
          nombre: 'Semana'
        }
      );
    }
    if (this.vistaDia) {
      opcionesVista.push(
        {
          id: CalendarView.Day as string,
          nombre: 'Día'
        },
      );
    }
    if (this.vistaAgenda) {
      opcionesVista.push(
        {
          id: VistasCalendarioExtendidoEnum.Agenda as string,
          nombre: 'Agenda'
        },
      );
    }
    this.auxiliarService.busquedaPropiedad(this.inputsFormularioVistaCalendario, 'formControlName', 'vista').datos = opcionesVista;      
    this.auxiliarService.busquedaPropiedad(this.inputsFormularioVistaCalendario, 'formControlName', 'vista').valor = CalendarView.Month;      
  }

  onCambioVista(eventos){
    this.vistaCambia.emit(eventos.event.vista)
  }
}
