import {Component, Input, ViewChild} from '@angular/core';
import { FormControl } from '@angular/forms';
import {NgxMatIntlTelInputComponent} from '@node_modules/ngx-mat-intl-tel-input';




@Component({
  selector: 'neo-campo-telefono [controlCampo]',
  templateUrl: './campo-telefono.component.html',
  styleUrls: ['./campo-telefono.component.scss']
})
export class CampoTelefonoComponent {
  @Input() controlCampo: FormControl;
  @Input() controlName: string;
  @Input() label = '';
  @Input() textoAyuda: string;
  @ViewChild('ngxTel') input: NgxMatIntlTelInputComponent;

  public resetInput(valueReset) {
    this.input.reset();
    this.controlCampo.setValue(valueReset);
  }
  // numeroInternacional = '+34';

  // constructor(
  //   private element: ElementRef
  // ) { }

  // ngAfterViewInit(){
  //   if (!this.controlCampo.value) {
  //     this.controlCampo.setValue(this.numeroInternacional);
  //   }
  // }

}
