import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[appDnd]'
})
export class DndDirective {

  @HostBinding('class.archivoEncima') archivoEncima: boolean;
  @Output() fileDropped = new EventEmitter<any>();

  constructor() { }

  // Dragover listener -> INDICAMOS AL USUARIO MEDIANTE UN CAMBIO EN EL BACKGROUND QUE EL ARCHIVO ARRASTABLE ESTÁ EN LA ZONA CORRECTA
  @HostListener('dragover', ['$event']) onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.archivoEncima = true;
  }

  // Dragleave listener
  @HostListener('dragleave', ['$event']) public onDragLeave(evt) {    
    evt.preventDefault();
    evt.stopPropagation();
    this.archivoEncima = false;
  }

  // Drop listenerç 
  @HostListener('drop', ['$event']) public ondrop(evt) {
    console.log(evt);
    evt.preventDefault();
    evt.stopPropagation();
    this.archivoEncima = false;
    let files = evt.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
      //this.fileDropped.emit(evt);
    }
  }
}
