import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  transparent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  setLoading(loading: boolean): void {

    if (loading === true) {
          this.loading.next(true);
    } else {
      this.loading.next(false);
    }
  }

  setTransparent(transparent: boolean): void {
    if (transparent === true) {
          this.transparent.next(true);
    } else {
      this.transparent.next(false);
    }
  }
}
