<div class="comentario-contenedor">
    <div class="comentario-cabecera">
        <h5 class="comentario-titulo"> {{ item.titulo }} </h5>
    </div>
    <div class="comentario-cuerpo">
        <neo-span-inner-html [contenido]="item.texto"></neo-span-inner-html>
    </div>
    <div class="comentario-pie text-muted">
        {{ item.fecha | date: 'H:mm a' |  uppercase }} - {{ item.fecha | date: 'shortDate' }}
    </div>
</div>