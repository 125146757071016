import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {

  private progreso$ = new BehaviorSubject<number>(0);

  constructor() { }

  setProgreso(progreso){
    this.progreso$.next(progreso);
  }

  get progresoAsObservable(){
    return this.progreso$.asObservable();
  }

}
