export enum AliasFicherosEnum {
    imagen = 'Imagen',
    documento = 'Documento',
    generico = 'Archivo'
  }
export enum AliasPluralFicherosEnum {
    imagen = 'Imágenes',
    documento = 'Documentos',
    generico = 'Archivos'
}
export enum IconoTipoFicherosEnum {
  imagen = 'icon-ibm--image',
  documento = 'icon-ibm--document--blank',
  generico = 'icon-ibm--document--blank'
}
