import { CalendarDateFormatter, DateFormatterParams } from 'angular-calendar';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
// https://www.w3.org/TR/NOTE-datetime
@Injectable()
export class CalendarioCustomizarFechasService extends CalendarDateFormatter {
  // you can override any of the methods defined in the parent class

  public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return (formatDate(date, 'EEE', locale)).replace(/\./g, '');
  }

  // public monthViewDayNumber({ date, locale }: DateFormatterParams): string {
  //   return false;
  // }

  public monthViewTitle({ date, locale }: DateFormatterParams): string {
    let mes = formatDate(date, 'MMMM', locale);
    let mesLetraInicial = mes.substring(0, 1).toUpperCase();
    let mesLetraResto = mes.substring(1, mes.length).toLowerCase();
    return mesLetraInicial + mesLetraResto + ' de ' + formatDate(date, 'y', locale);
  }

  public weekViewTitle({ date, locale }: DateFormatterParams): string {
    let mes = formatDate(date, 'MMMM', locale);
    let mesLetraInicial = mes.substring(0, 1).toUpperCase();
    let mesLetraResto = mes.substring(1, mes.length).toLowerCase();
    return mesLetraInicial + mesLetraResto + ' de ' + formatDate(date, 'y', locale);
  }

  public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
    return (formatDate(date, 'EEE', locale)).replace(/\./g, '');
  }

  public weekViewColumnSubHeader({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'd', locale);
  }

  // public weekViewHour({ date, locale }: DateFormatterParams): string {
  //   return false;
  // }

   public weekViewHour({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'HH:mm', locale);
    // return false;
  }

  // public weekViewTitle({ date, locale }: DateFormatterParams): string {
  //   return false
  // }

  public dayViewTitle({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'd', locale) + ' de ' + formatDate(date, 'MMMM', locale) + ' de ' + formatDate(date, 'y', locale);
  }


  public dayViewHour({ date, locale }: DateFormatterParams): string {
    return formatDate(date, 'HH:mm', locale);
  }
}