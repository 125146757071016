import {Component, Input, OnInit} from '@angular/core';
import {Observable, Subscription, timer} from 'rxjs';
import {environment} from '@environments/environment';
import { SpinnerService } from '@appNeo/neoCore/services/spinner/spinner.service';

export enum spinnerModeEnum {
  determinate = 'determinate',
  indeterminate = 'indeterminate'
}

@Component({
  selector: 'neo-block-spinner',
  templateUrl: './block-spinner.component.html',
  styleUrls: ['./block-spinner.component.scss'],
})
export class BlockSpinnerComponent implements OnInit {
  //Común 3 spinners
  @Input() load = false;
  @Input() diameter = 50;

  //Común spinner's neoMaterial
  @Input() block = true;
  @Input() valorSpinnerDeterminate = 50;
  @Input() color = 'primary';
  @Input() mode = spinnerModeEnum.indeterminate;
  @Input() strokeWidth: number;


  //Spinner img
  @Input() rutaImagen: string;

  subscription: Subscription;
  timer: Observable<any>;
  public delayFinish = false;

  constructor(
    private spinnerService: SpinnerService
  ) {
    this.delayInicioSpinner();
  }

  ngOnInit(): void {
    this.validaciones();
    this.spinnerService.showSpinner$.subscribe( data => {
      if (data ){
        this.load = true;
      }else {
        this.load = false;
      }
    })
  }

  validaciones(): void {
    if (this.mode === spinnerModeEnum.indeterminate) {
      this.valorSpinnerDeterminate = null;
    } else {
      if (this.valorSpinnerDeterminate > 100) this.valorSpinnerDeterminate = 100;
    }
  }


  delayInicioSpinner() {
    this.timer = timer(environment.spinner_delay_ms);
    this.subscription = this.timer.subscribe(() => {
      this.delayFinish = true;
    });
  }

  getImgSpinnerStyles() {
    const styles = {
      width: `${this.diameter} px`,
      height: `${this.diameter} px`,
    };
    return styles;
  }
}
