import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'neo-historial-comentario [item]',
  templateUrl: './historial-comentario.component.html',
  styleUrls: ['./historial-comentario.component.scss']
})
export class HistorialComentarioComponent implements OnInit {

  @Input() item; 

  constructor() { }

  ngOnInit(): void {
  }

}
