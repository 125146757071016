<div class="criterio-validez">
  <p>La contraseña debe contener al menos: </p>
  <div class="list-criterios">
    <ul>
      <li *ngFor="let criterio of criteriosValidez">
        <!-- icono final: icon-ibm--checkmark--outline -->
        <neo-icono [iconoClase]="(criterio?.completado) ? 'icon-14 icon-ibm--checkmark--filled text-success' : 'icon-14 icon-ibm--radio-button text-grey3' " ></neo-icono> {{criterio.texto}}
      </li>
    </ul>
  </div>
</div>
