import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IconEnum } from '@appNeo/neoShared/helpers/enums/Icon.enum';
import { NotificacionEnum } from '@appNeo/neoShared/helpers/enums/Notificacion.enum';
import { NotificacionComponent } from '../notificacion/notificacion.component';

@Component({
  selector: 'neo-input-clipboard [controlCampo]',
  templateUrl: './input-clipboard.component.html',
  styleUrls: ['./input-clipboard.component.scss']
})
export class InputClipboardComponent {

  @Input() controlCampo: FormControl;
  @Input() label: string  = '';
  @Input() tipo: string  = 'text';
  @Input() textoAyuda: string  = '';
  @Input() placeholder: string  = '';
  @Input() disabled: boolean  = false;
  @ViewChild('notificacionInputClipboard') notificacionInputClipboard: NotificacionComponent;

  copiado() {
    this.notificacionInputClipboard.eliminarNotificaciones();
    this.notificacionInputClipboard.crearNotificacion(NotificacionEnum.Informacion, '','Contenido copiado al portapapeles', true, IconEnum.Informacion, '', true);
  }
}
