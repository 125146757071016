<div class="inline-flex flex-column align-items-center">
    <mat-label *ngIf="label">{{ label }} </mat-label>
    <neo-visor-color-picker 
        [color]="color"
        (click)="openPicker()"
    ></neo-visor-color-picker>    
</div>

<input
#colorPickerElement
id="colorPickerElement"
class="looks-like-a-button"
[style.background]="color"
[(colorPicker)]="color"
(colorPickerOpen)="onEventLog('colorPickerOpen', $event)"
(colorPickerClose)="onEventLog('colorPickerClose', $event)"
(cpInputChange)="onEventLog('cpInputChange', $event)"
(cpSliderDragStart)="onEventLog('cpSliderDragStart', $event)"
(cpSliderDragEnd)="onEventLog('cpSliderDragEnd', $event)"
/>