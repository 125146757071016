<div class="form-field field-checkbox">
  <mat-checkbox [color]="(color) ? color:'primary'" [class]="clase" [formControl]="controlCampo" [ngClass]="{'campo-error': controlCampo.invalid && controlCampo.dirty}">
    {{label}}
    <a class="text-underline text-bold lnk-primary" *ngIf="labelLink" [routerLink]="labelLink.rutaInterna" [href]="labelLink.rutaExterna" (click)="clickLabelLink()">{{labelLink.texto}}</a>
    <ng-content></ng-content>
  </mat-checkbox>
</div>

<!--          TODO: Dejar comentado por el momento-->
<!--          <mat-error class="campo-error" *ngIf="formValidators.get(campo.formControlName).invalid && formValidators.get(campo.formControlName).dirty">-->
<!--            <neo-mat-error-form-->
<!--                                [nombreCampo]="campo.label"-->
<!--                                [controlCampo]=formValidators.get(campo.formControlName)>-->
<!--            </neo-mat-error-form>-->
<!--          </mat-error>-->
