import {ExtensionFormInput, FormTagEnum} from '@appNeo/neoShared/helpers/enums/FormTag.enum';
import {TipoDatePickerEnum} from '@appNeo/neoShared/helpers/enums/TipoDatePicker.enum';
import { IValorMultiple } from './IValorMultiple';



// Layout Porcentajes
// Cada campo tiene asociado un porcentaje, y se va adaptando al ancho disponible, que esta establecido a 600px de forma general
export enum ClasesCampoLayoutCamposPorcentajeFormularioEnum {
  campoPorcent1x = 'campo-porcent-1x',
  campoPorcent2x = 'campo-porcent-2x',
  campoPorcent3x = 'campo-porcent-3x',
  campoPorcent4x = 'campo-porcent-4x',
  campoPorcent5x = 'campo-porcent-5x',
  campoPorcent6x = 'campo-porcent-6x',
}

// Layout Pixeles
// Los campos tienen un ancho fijo. Es el padre el que se adapta al ancho disponible y los hijos se recolocan con su ancho fijo.
export enum ClasesCampoLayoutCamposAnchoFijoFormularioEnum {
  campoPorcent100 = 'campo-porcent-100',
  campoPx1x = 'campo-px-1x',
  campoPx2x = 'campo-px-2x',
  campoPx3x = 'campo-px-3x',
}
export enum ClasesFormularioAnchoEnum {
  formularioAnchoCompleto='formulario-ancho-completo',
  formularioAnchoMedio='formulario-ancho-medio',
  formularioAnchoReducido='formulario-ancho-reducido'
}

export enum LayoutFormularioEnum {
  layoutCamposPorcentaje='layoutCamposPorcentaje',
  layoutCamposAnchoFijo='layoutCamposAnchoFijo',
}
export interface ILabelLink {
  texto: string;
  rutaExterna?: string;
  rutaInterna?: string;
}

//TODO: REVISAR NOMENCLATURAS
export interface IFormInput {
  idExterno?: string,
  datosExternos?: any;
  tag: FormTagEnum;
  formControlName: string; //id
  label?: string;
  labelLink?: ILabelLink;
  maxLength?: number;
  minLength?: number;
  max?: number | Date;
  min?: number | Date;
  horaMin?: number;
  minutoMin?: number;
  horaMax?: number;
  minutoMax?: number;
  multiple?: boolean;
  textoAyuda?: string;
  // dos posibles layouts, cada uno tendra sus posible combinacion de clases para cada campo
  clasePersonalizadaLayoutCamposPorcentaje?: ClasesCampoLayoutCamposPorcentajeFormularioEnum[] // las columnas por tag tiene unas aplicadas por defecto, pero podemos sobreescribirlas
  clasePersonalizadaLayoutCamposAnchoFijo?: ClasesCampoLayoutCamposAnchoFijoFormularioEnum[] // las columnas por tag tiene unas aplicadas por defecto, pero podemos sobreescribirlas
  placeholder?: string;
  type: string;
  tipoDatePicker?: TipoDatePickerEnum;
  model?: string;
  icon?: string;
  valor?:any;
  unidades?:string;
  //
  validadores: string[],
  obligatorio?:boolean,
  disabled?:boolean,
  campoDependiente?: string,
  campoExcluyente?: string,
  opcionGeneral?: boolean; // en select si acepta value "" tipo TODOS
  opcionGeneralNombre?: string;
  datos?: IValorMultiple[] | any;
  datosPreestablecidos?: IValorMultiple[] | any;
  anchoCompleto?: boolean;
  color?: string;
  activarExtensiones?: ExtensionFormInput[];
  mostrarMatError?: boolean;
  // ficheroZonaArrastre
  formatosPermitidos?: string[];
  subidaMultiple?: boolean;
  maximoElementosASubir?: number;
  tamanoMaximoTotal?: number;
  tamanoMaximoFichero?: number;
  presubidaFichero?: boolean;
  responsivo?:boolean;
  oculto?:boolean;
}


