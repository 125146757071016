<mat-form-field class="example-full-width campo-cantidad-model" appearance="outline">
  <mat-label *ngIf="label">{{label}}</mat-label>
  <input matInput class="text-right" type="number"
         min="{{ min }}"
         max="{{ max }}"
         [(ngModel)]="cantidad"
         [disabled]="disabled"
         placeholder="{{placeholder}}"
         (change)="nuevaCantidad.emit(cantidad)"
></mat-form-field>
