<mat-label *ngIf="label"> {{label}}</mat-label>
<mat-slide-toggle
    [ngClass]="iconoClase ? iconoClase : 'toggle-on-off toggle-xs'"
    [color]="color"
    [(ngModel)]="valor"
    [disabled]="disabled"
    (ngModelChange)="nuevoValor.emit(valor)">
    <span *ngIf="texto"  data-testid="texto">
        {{texto}}
    </span>
</mat-slide-toggle>
