<circle-progress class="circulo-progreso"
[percent]="progressService.progresoAsObservable | async"
[radius]="15"
[maxPercent]="100"
[outerStrokeWidth]="2"
[outerStrokeColor] = "'#5A85D7'"
[innerStrokeWidth]="5"
[innerStrokeColor] = "'#5A85D7'"
[animation]="true"
[showSubtitle]="false"
[showUnits]="false"
[showTitle]="false"
[startFromZero]="false"
[animationDuration]="300"
[showZeroOuterStroke] = "false"
[showBackground] = "true"
[backgroundStroke] = "'#DDE2E5'"
[backgroundStrokeWidth]="2"
[backgroundPadding] = "-2"
[space] = "9"
></circle-progress>
