<div class="card-totales">
  <h3 class="totales-titulo">Totales</h3>
  <neo-formulario class="mt-20"
    [camposFormulario]="[input]"
    [valoresIniciales]="valoresIniciales"
    [layout]="'layoutCamposAnchoFijo'"
    (changeValueForm)="cambioSelectOpciones($event)">
  </neo-formulario>
  <div class="subtotales">
    <div class="subtotal" *ngFor="let seccion of data">
      <h4 class="subtotal-titulo">{{seccion.titulo}}</h4>
      <p class="subtotal-resultado">
        <ng-container  *ngIf="seccion.resultado; else sinValor">
          {{seccion.resultado}}
        </ng-container>
      </p>
    </div>
  </div>
</div>

<ng-template #sinValor>--</ng-template>
