import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import { NotificacionComponent } from '@appNeo/neoShared/components/notificacion/notificacion.component';

export enum TipoAccionBotonEnum {
  cerrar = 'cerrar',
  accion = 'accion'
}

export interface IConfiguracionBotonera {
  id?: string;
  clase?: string;
}

export interface IBotonAccionesDialog {
  label: string,
  clase?: string,
  claseNeoButton?: string,
  id: string,
  tipoAccion: TipoAccionBotonEnum,
  type: 'submit' | 'button',
  color: string,
  disabled: boolean,
  activo: boolean
  raised?: boolean,
  flat?: boolean,
  basic?: boolean,
  stroked?: boolean,
  fab?: boolean,
  miniFab?: boolean,
  iconoClase?:string,
  matprefix?: boolean,
  matsuffix?: boolean,
}

@Component({
  selector: 'neo-acciones-dialog[dialogTittle]',
  templateUrl: './acciones-dialog.component.html',
  styleUrls: ['./acciones-dialog.component.scss']
})
export class AccionesDialogComponent {

  @Input() identificador;
  @Input() dialogTittle;
  @Input() botonera: IBotonAccionesDialog[] = [];
  @Input() configuracionBotonera: IConfiguracionBotonera = { clase: 'botoneraDerecha' };  
  @Input() botoneraIzquierda: IBotonAccionesDialog[] = [];  
  @Input() configuracionBotoneraIzquierda: IConfiguracionBotonera = { clase: 'botoneraIzquierda' };   
  @Input() botonCerrarDialogoCabecera: boolean = true;
  @Input() notificarCierreDialogo: boolean = false;
  @Input() claseDialogActions: string;


  @Output() cerrarDialogo = new EventEmitter <boolean>();
  @Output() accionBoton = new EventEmitter <IBotonAccionesDialog>();

  @ViewChild('notificacionDialogo') notificacionDialogo: NotificacionComponent;

  constructor() { }

  cerrar(boton?: IBotonAccionesDialog) {
    let botonOrigenBotoneraPie = (boton) ? true : false;
    this.cerrarDialogo.emit(botonOrigenBotoneraPie);
  }

  accion( boton: IBotonAccionesDialog) {
    // console.log(`accion boton ${boton.id}`, boton);
    this.accionBoton.emit(boton);
  }

}
