<ng-container *ngIf="valores?.length && esArray()">
    <ng-container *ngFor="let valor of valores; let i=index">
        <span class="text-nowrap contenedor-perfil-valores-multiples"  [ngStyle]="{'color': valor?.color }">
            <neo-icono *ngIf="valor?.icono || valor?.iconoClase"
              [icono]="valor?.icono"
              [iconoClase]="valor?.iconoClase + ' mr-5 '"
            ></neo-icono>
            <span data-testid="nombre" *ngIf="valor?.nombre">
               {{ valor.nombre }} 
            </span>
        </span>
        <ng-container *ngIf="i<valores.length-1">, </ng-container>
    </ng-container>
</ng-container>

