import {Component, Output, EventEmitter, Input, OnInit, ViewChild, ElementRef} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {environment} from '@environments/environment';
import {FiltrosService} from '@appNeo/neoCore/services/filtros/filtros.service';

@Component({
  selector: 'neo-busqueda',
  templateUrl: './busqueda.component.html',
  styleUrls: ['./busqueda.component.scss'],
  providers: [FiltrosService],
})

export class BusquedaComponent implements OnInit {
  @Input() placeholder: string;
  @Input() botonLimpiarBuscador = true;
  @Input() valorDefault: boolean | string = false;
  @Input() keyFiltrado: string;
  @Output() changeBuscador = new EventEmitter<string>();
  @Output() limpiarBuscador = new EventEmitter<boolean>();
  @Output() pierdeFoco = new EventEmitter<boolean>();
  @ViewChild('inputBusqueda') inputBusqueda: ElementRef;

  form: FormGroup;
  valorAlmacenado: string;
  constructor(
    private formBuilder: FormBuilder,
    public filtrosService: FiltrosService,
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.gestionarValorInicial();
    this.subscripcionCambiosFormulario();
  }

  gestionarValorInicial() {
    if (this.valorDefault) {
      if (typeof this.valorDefault === 'boolean') {
        this.valorAlmacenado = this.filtrosService.getFiltroBusqueda(this.keyFiltrado);
        this.form.reset({textoBusqueda: this.valorAlmacenado});
        this.cambioInputBusqueda();
      }
      if (typeof this.valorDefault === 'string') {
        this.form.reset({textoBusqueda: this.valorDefault});
        this.cambioInputBusqueda();
      }
    }
  }

  subscripcionCambiosFormulario(): void {
    this.form.valueChanges
      .pipe(
        debounceTime(environment.formulario_busqueda_debounce),
        distinctUntilChanged((formEntityOld, formEntityNew) => JSON.stringify(formEntityOld) === JSON.stringify(formEntityNew)),
    )
    .subscribe(x => this.cambioInputBusqueda());
  }

  buildForm(): void {
    this.form = this.formBuilder.group({
      textoBusqueda: new FormControl(),
    });
  }

  cambioInputBusqueda() {
      let valorInput = '';
      if (this.termino) valorInput = this.termino;
      if (this.keyFiltrado) {
        this.filtrosService.setFiltros(this.keyFiltrado, valorInput);
      }
      this.changeBuscador.emit(valorInput);
  }

  clearBuscador(){
    this.form.reset();
  }

  filtroLimpiar() {
    this.clearBuscador();
    this.limpiarBuscador.emit(true);
  }

  get termino(): string {
    return this.form.controls.textoBusqueda.value;
  }

  set termino(termino: string) {
    this.form.controls.textoBusqueda.setValue(termino);
  }

  eventoPierdeFoco(){
    this.pierdeFoco.emit(true);
  }

  establecerFoco() {
    this.inputBusqueda.nativeElement.focus();
  }

  ngOnDestroy(): void {
    if (!this.valorDefault) {
      const filtrosActuales = this.filtrosService.getFiltros(this.keyFiltrado, {});
      if (filtrosActuales['busqueda']) {
        filtrosActuales['busqueda'] = '';
      }
      this.filtrosService.setFiltros(this.keyFiltrado, filtrosActuales);
    }
  }

  onPerderFoco() {
    this.inputBusqueda.nativeElement.blur();
  }
}
